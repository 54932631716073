import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SplitScreen from '../../../components/SplitScreen';
import BillOfQuantitySelector from './BillOfQuantitySelector';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { MissionSelector } from './MissionSelector';
import { ItemType } from '../../../components/BillOfQuantity/BllOfQuantityTable';
import { ProjectMissions_project_missions } from './MissionSelector/types/ProjectMissions';
import useHandleItemPlaningSubmit from '../BillOfQuantitySelector/useHandleItemPlaningSubmit';

export interface IItem {
  acronym: string;
  netPrice: number;
  applyScaleDiscount: boolean;
  catalogMainGroup: string;
  catalogMainGroupDescription: string;
  catalogSection: string;
  catalogSectionDescription: string;
  catalogSubsection: string;
  catalogSubsectionDescription: string;
  category: string;
  color?: string;
  comment: string;
  customerComment: string;
  descriptionOne: string;
  descriptionTwo: string;
  dimensionOne: string;
  dimensionTwo: string;
  freeText: string;
  hasFiles: boolean;
  id: string;
  isInvoiced: boolean;
  netOfferSum?: number;
  openVolume: number;
  itemFromStandardCatalog: boolean;
  markingStyle: string;
  material: string;
  productNumber: string;
  reflexion: string;
  targetConsumptionPerUnit: number;
  timeRequired: number;
  type?: keyof typeof ItemType;
  unit: string;
  volume: number;
  location: {
    id: string;
    billOfQuantity: { id: string };
    parentLocation?: { id: string; parentLocation?: { id: string } };
  };
}

export interface ILocation {
  id: string;
  name: string;
  items: IItem[];
  locations?: ILocation[];
}

export interface IBillOfQuantity {
  id: string;
  name: string;
  createdAt: string;
  colorCodings: string[];
  hasScaleDiscount: boolean;
  taskTypes: string[];
  statuses: any[];
  sum: string;
  defaultLocation: ILocation;
}

export interface IProject {
  projectNumber: string;
  catalog?: null;
}

export interface IMissionItem {
  id: string;
  planVolume: number;
  item: IItem;
}

export type IItemWithAmount = IItem & { amount: number };

export const ADD_ITEMS_MUTATION = gql`
  mutation AddItemsToMissionMutation(
    $id: Int!
    $items: [ItemsToMissionInput!]!
    $locationIdsToAdd: [String!]
  ) {
    addItemsToMission(id: $id, items: $items, locationIdsToAdd: $locationIdsToAdd) {
      id
    }
  }
`;

const TabMissions: React.FC<RouteComponentProps> = ({ match, history }) => {
  const client = useApolloClient();
  const { projectNumber } = match.params as any;
  const [selectedMission, setSelectedMission] = useState<
    ProjectMissions_project_missions | undefined
  >();

  useEffect(() => window.parent.postMessage('momoProjectMissions', '*'), []);

  const handleMissionChange = useCallback(
    (mission: ProjectMissions_project_missions | undefined) => {
      setSelectedMission(mission);
    },
    [setSelectedMission],
  );

  const handleSubmit = useHandleItemPlaningSubmit(selectedMission, projectNumber, client);

  return (
    <SplitScreen
      left={
        <BillOfQuantitySelector
          projectNumber={projectNumber}
          mission={selectedMission}
          onSubmit={handleSubmit}
        />
      }
      right={
        <MissionSelector
          projectNumber={projectNumber}
          onChange={handleMissionChange}
          onClickNew={() => history.push(`/projekte/${projectNumber}/einsaetze/erstellen`)}
        />
      }
    />
  );
};

export default TabMissions;
