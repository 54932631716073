import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import React, { useCallback } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import {
  billFields,
  billItemFields,
} from '../../pages/Projects/TabBills/BillSelector/bill.queries';
import {
  createMapBillItem,
  marshalBillData,
} from '../../pages/Projects/TabBills/BillSelector/utils/pagination.helpers';
import { UPDATE_DATA_TABLE_ROW } from '../../services/graphql-client';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../services/types/UpdateDataTableRow';
import { TableType } from '../../types/graphql';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import DialogCloseable from '../Dialog/DialogCloseable';
import {
  BillItemScaleDiscounts,
  BillItemScaleDiscountsVariables,
} from './types/BillItemScaleDiscounts';
import { ChangeScaleDiscount, ChangeScaleDiscountVariables } from './types/ChangeScaleDiscount';

interface IProps {
  billItemId: string;
  onClose: () => void;
}

const BILL_ITEM_SCALE_DISCOUNTS = gql`
  query BillItemScaleDiscounts($billItemId: ID!) {
    billItem(where: { id: $billItemId }) {
      id
      pricePerUnit
      scaleDiscounts {
        id
        pricePerUnit
        threshhold
      }
    }
  }
`;

const CHANGE_SCALE_DISCOUNT_MUTATION = gql`
  mutation ChangeScaleDiscount($billItemId: ID!, $pricePerUnit: Int!) {
    updateBillItem(where: { id: $billItemId }, data: { pricePerUnit: $pricePerUnit }) {
      ...billItemFields
      bill {
        ...billFields
      }
    }
  }
  ${billItemFields}
  ${billFields}
`;

const useStyles = makeStyles({
  selected: {
    cursor: 'pointer',
  },
});

const currencyFormatter = createSwissCurrencyFormatter();

const ScaleDiscountsTable: React.FC<IProps> = ({ billItemId, onClose }) => {
  const { data, error, loading } = useQuery<
    BillItemScaleDiscounts,
    BillItemScaleDiscountsVariables
  >(BILL_ITEM_SCALE_DISCOUNTS, { variables: { billItemId } });

  const client = useApolloClient();

  // update billItem row in cache with new price-per-unit
  const updatePricePerUnitInCache = useCallback<MutationUpdaterFn<ChangeScaleDiscount>>(
    async (cache, { data }) => {
      if (!data?.updateBillItem) {
        return;
      }

      const { updateBillItem: updatedBillItem } = data;

      const bill = updatedBillItem.bill;

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          where: {
            id: bill.defaultLocation.id,
            tableType: TableType.BILL,
          },
          data: {
            data: marshalBillData(bill),
          },
        },
      });

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          data: {
            data: createMapBillItem(!!updatedBillItem.bill.isFixedBill)(updatedBillItem).data,
          },
          where: {
            id: updatedBillItem.id,
            tableType: TableType.BILL,
          },
        },
      });
    },
    [client],
  );

  const [changeScaleDiscount] = useMutation<ChangeScaleDiscount, ChangeScaleDiscountVariables>(
    CHANGE_SCALE_DISCOUNT_MUTATION,
    {
      update: updatePricePerUnitInCache,
    },
  );

  const classes = useStyles();

  if (error) {
    console.log(error);
    return null;
  }

  if (!data || loading) {
    return null;
  }

  return (
    <DialogCloseable
      open={true}
      title="Mengenrabatt ändern"
      fullWidth
      maxWidth="xs"
      onClose={onClose}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Menge</TableCell>
            <TableCell>Preis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.billItem.scaleDiscounts.map(({ pricePerUnit, threshhold, id }) => {
            const selected = pricePerUnit === data.billItem.pricePerUnit;

            return (
              <TableRow
                key={id}
                hover={!selected}
                className={!selected ? classes.selected : ''}
                selected={selected}
                onClick={async () => {
                  if (pricePerUnit && !selected) {
                    await changeScaleDiscount({ variables: { billItemId, pricePerUnit } });
                    onClose();
                  }
                }}
              >
                <TableCell>{threshhold}</TableCell>
                <TableCell>{pricePerUnit && currencyFormatter(pricePerUnit)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </DialogCloseable>
  );
};

export default ScaleDiscountsTable;
