import { Fab, IconButton, MenuItem, RootRef, Theme, Tooltip } from '@material-ui/core';
import { pink, purple } from '@material-ui/core/colors';
import {
  Add as AddIcon,
  AddCircle as AddCircleIcon,
  AddLocation as AddLocationIcon,
  Attachment,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Filter2 as Filter2Icon,
  FindReplace as FindReplaceIcon,
  PlaylistAdd as PlaylistAddIcon,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Dinero from 'dinero.js';
import { FastField as Field, useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { ItemType } from '.';
import { removeTableTypeFromId } from '../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import { BillOfQuantityEntityType, BOQStatus, PdfPrintType } from '../../../types/graphql';
import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { flattenContainerRows } from '../../../utils/flattenRows/flattenRows';
import { formatDate } from '../../../utils/format/date';
import { formatVolume } from '../../../utils/format/volume';
import { CopyItemAction } from '../../Actions/Copy/Item';
import { DeleteBillOfQuantityAction } from '../../Actions/Delete/BillOfQuantity';
import ColorCoder, { Color } from '../../ColorCoder';
import { DataTableBody, DataTableHotKeysWrapper } from '../../DataTable';
import { IDataTableColumn, IDataTableRow, ILevelOptions, OnLoadMore } from '../../DataTable/types';
import CancelSaveIconButtons from '../../Form/CancelSaveIconButtons';
import FormikSelect from '../../Form/FormikSelect';
import FormikTextField from '../../Form/FormikTextField';
import PdfPrint from '../../PdfPrint';
import { AvailableRapportParameters } from '../../PdfPrint/PdfPrint';
import { Search } from '../../Search';
import { useSearchState } from '../../Search/Search';
import ItemBoilerplateSelect from '../AcronymField';
import {
  BoqTableAction,
  BoqTableActionType,
  IBoqTableReducerState,
} from './billOFQuantityTable.reducer';
import { isCreateRow, isEditRow, NEW_ROW_ID } from './BillOfQuantityTable.utils';
import HotKeysWrapper from './HotKeysWrapper';
import { parseCompositeLocationId } from './utils/compositeLocationId';
import useBlockedOfferClassName from '../useOfferBlockedStyles';
import { lighten } from '@material-ui/core/styles';

interface IProps {
  state: IBoqTableReducerState;
  dispatch: React.Dispatch<BoqTableAction>;
  containerRows: any;
  onSearchSubmit: (searchValues: { terms: string[]; columns: string[] }) => Promise<any>;
  onCreateLocation: (locationId: string) => () => void;
  /**
   * Called when a location is dragged to a new index
   * @param billOfQuantityId the bill of quantities id
   * @param parentLocationId the id of this locations parent (every location has a parent, "defaultLocation" for top level ones)
   * @param locationId the id of the location
   * @param index the new index to move this location to
   */
  onReorderLocation: (args: {
    billOfQuantityId: string;
    parentLocationId: string;
    locationId: string;
    index: number;
  }) => void;
  catalogId?: string;
  projectNumber: string;
  itemActions: {
    onAddItem: (locationId: string) => () => void;
    onCreateRow: (containerRowId: string) => void;
    deleteItem: (itemId: string) => void;
    onCancelEditRow: () => void;
  };
  refs: {
    itemVolumeInputRef: React.MutableRefObject<HTMLBaseElement | null>;
    acronymRef: React.MutableRefObject<HTMLElement | null>;
    tableRef: React.MutableRefObject<HTMLElement | null>;
  };
  L8Button: JSX.Element;
  queryLoading?: boolean;
  isDragAndDropEnabled?: boolean;
  onLoadMore?: OnLoadMore;
  isOnLoadMoreDisabled?: ILevelOptions['isOnLoadMoreDisabled'];
  onToggleFinishedItems?: () => void;
  entityType: BillOfQuantityEntityType;
}

const useStyles = makeStyles((theme: Theme) => ({
  autoCompleteRoot: {
    position: 'relative',
  },
  notStandardCatalog: {
    color: purple['500'],
    fontSize: theme.typography.body2.fontSize,
  },
  noTopMargin: {
    marginTop: '0px!important',
  },
  L8: {
    backgroundColor: lighten(pink[700], 0.7),
  },
}));

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const locationColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Örtlichkeit',
  },
];

const searchColumns = [
  'acronym',
  'productNumber',
  'catalogMainGroup',
  'catalogMainGroupDescription',
  'catalogSection',
  'catalogSectionDescription',
  'catalogSubsection',
  'catalogSubsectionDescription',
  'category',
  'comment',
  'descriptionOne',
  'descriptionTwo',
  'freeText',
  'reflexion',
  'type',
];

const billOfQuantityColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Bezeichnung',
    width: 130,
  },
  {
    id: 'statuses',
    label: 'Status',
    width: 80,
  },
  {
    id: 'taskTypes',
    label: 'Arbeitsgattung',
  },
  {
    id: 'date',
    label: 'Datum',
    width: 90,
    render: (date: string) => (date ? formatDate(date) : '-'),
  },
  {
    id: 'sumNet',
    label: 'Summe netto',
    width: 120,
    render: (sum: any) => sum && formatCurrency(sum),
  },
  {
    id: 'sumGross',
    label: 'Summe brutto',
    width: 120,
    render: (sum: any) => sum && formatCurrency(sum),
  },
  {
    id: 'type',
    label: 'Typ',
    width: 100,
  },
  {
    id: 'hasScaleDiscount',
    label: 'Mengenrabatt',
    width: 100,
  },
  {
    id: 'colorCodings',
    label: '',
    hideOnDefault: true,
    width: 100,
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  },
];

const renderActionCreateLocation = (
  locationId: string,
  onSubmit: (locationId: string) => () => void,
) => (
  <Tooltip title="Örtlichkeit hinzufügen">
    <IconButton onClick={() => onSubmit(locationId)()}>
      <AddLocationIcon />
    </IconButton>
  </Tooltip>
);

const renderActionDeleteLocation = (onDelete: () => void, canBeDeleted?: boolean) => (
  <Tooltip title="Löschen">
    <span>
      <IconButton onClick={onDelete} disabled={!canBeDeleted}>
        <DeleteIcon />
      </IconButton>
    </span>
  </Tooltip>
);

const renderActionsCopyLocation = (onClick: () => void) => (
  <Tooltip title="Örtlichkeit kopieren">
    <IconButton onClick={onClick}>
      <Filter2Icon />
    </IconButton>
  </Tooltip>
);

const isLevelToggleButtonVisible = (row: IDataTableRow) => row.data.hasItemsOrLocations;

const BillOfQuantityDataTable: React.FC<IProps> = ({
  state,
  dispatch,
  containerRows,
  onSearchSubmit,
  onCreateLocation,
  onReorderLocation,
  itemActions,
  refs: { itemVolumeInputRef, acronymRef, tableRef },
  catalogId,
  projectNumber,
  L8Button,
  queryLoading,
  isDragAndDropEnabled,
  onLoadMore,
  isOnLoadMoreDisabled,
  onToggleFinishedItems,
  entityType,
}) => {
  const classes = useStyles();
  const getBlockedOfferClassName = useBlockedOfferClassName();

  const shownColumnIdsLengthRef = useRef(0);

  const searchState = useSearchState();

  const { values, isSubmitting } = useFormikContext<any>();

  const [activeColumns, setActiveColumns] = useState<string[]>(['']);

  useLayoutEffect(() => {
    if (itemVolumeInputRef.current) {
      const input = itemVolumeInputRef.current.querySelector('input')!;

      // don't focus volume input if it's empty because if we insert a new line
      // we still want the first input to be focused
      if (isEmpty(input.value)) {
        return;
      }

      input.focus();
      input.select();
    }
  }, [itemVolumeInputRef]);

  useEffect(() => {
    shownColumnIdsLengthRef.current = activeColumns.length;
  }, [activeColumns]);

  const flattenedContainerRows = useMemo(
    () => flattenContainerRows(containerRows),
    [containerRows],
  );

  const itemBoilerplateSelectOnChange = useCallback(
    (row) => {
      if (row) {
        dispatch({
          type: BoqTableActionType.SET_CURRENT_ITEM_BOILERPLATE_ID,
          payload: { id: row.id },
        });
        dispatch({
          type: BoqTableActionType.SET_NEW_ROW_DATA,
          payload: {
            data: {
              ...values,
              volume: row.predefinedVolume || 0,
              appliedPrice: row.predefinedPricePerUnit || row.basePrice || 0,
            },
          },
        });
      }
    },
    [dispatch, values],
  );

  const itemColumns = useMemo<IDataTableColumn[]>(() => {
    const isNotAcronymSearch = values.acronym === '';
    return [
      {
        id: 'materialColor',
        label: 'Farbcod.',
        width: 85,
        // would produce an invalid nesting p > div
        renderPlain: true,
        render: (data: any) => data && <ColorCoder colorCodings={[data]} />,
      },
      {
        id: 'secretAutocompleteColumn',
        label: '"Zeile hinzufügen" Tabelle Anker',
        width: 0,
        alwaysVisible: true,
        fixedPosition: 1,
        render: (data: any, row: IDataTableRow) =>
          isCreateRow(row) ? (
            <div className={classes.autoCompleteRoot}>
              &nbsp;
              <ItemBoilerplateSelect
                innerRef={tableRef}
                filter={values}
                defaultCatalogId={catalogId}
                activeColumns={activeColumns}
                onChange={itemBoilerplateSelectOnChange}
                onSubmit={(row) => {
                  if (row) {
                    dispatch({
                      type: BoqTableActionType.SET_NEW_ROW_DATA,
                      payload: {
                        data: {
                          ...values,
                          freeText: values.freeText || row.freeText,
                          customerComment: values.customerComment || row.customerComment,
                          volume: row.predefinedVolume || 0,
                          appliedPrice: row.predefinedPricePerUnit || 0,
                        },
                      },
                    });
                  }
                  if (itemVolumeInputRef.current) {
                    itemVolumeInputRef.current.querySelector('input')!.focus();
                  }
                }}
              />
            </div>
          ) : (
            ''
          ),
      },
      {
        id: 'acronym',
        label: 'Kurzbez.',
        width: 80,
        render: (data: any, row: IDataTableRow) =>
          isCreateRow(row) ? (
            <RootRef rootRef={acronymRef}>
              <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
                <Field name="acronym" component={FormikTextField} autoComplete="off" />
              </HotKeysWrapper>
            </RootRef>
          ) : null,
      },
      {
        id: 'productNumber',
        label: 'Produktnummer',
        width: 120,
        render(data: any, row: IDataTableRow) {
          if (isNotAcronymSearch && isCreateRow(row)) {
            return (
              <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
                <Field name="productNumber" component={FormikTextField} autoComplete="off" />
              </HotKeysWrapper>
            );
          }
          if (!row.data.itemFromStandardCatalog) {
            return <span className={classes.notStandardCatalog}>{row.data.productNumber}</span>;
          }
          return null;
        },
      },
      {
        id: 'catalogSection',
        label: 'Hierarchienr 1',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="catalogSection" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'catalogSubsection',
        label: 'Hierarchienr 2',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="catalogSubsection" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'catalogMainGroup',
        label: 'Hierarchienr 3',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="catalogMainGroup" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'catalogSectionDescription',
        label: 'Hierarchie 1',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field
                name="catalogSectionDescription"
                component={FormikTextField}
                autoComplete="off"
              />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'catalogSubsectionDescription',
        label: 'Hierarchie 2',
        width: 120,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field
                name="catalogSubsectionDescription"
                component={FormikTextField}
                autoComplete="off"
              />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'catalogMainGroupDescription',
        label: 'Hierarchie 3',
        width: 120,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field
                name="catalogMainGroupDescription"
                component={FormikTextField}
                autoComplete="off"
              />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'descriptionOne',
        label: 'Beschreibung 1',
        width: 200,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="descriptionOne" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'descriptionTwo',
        label: 'Beschreibung 2',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="descriptionTwo" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'freeText',
        label: 'Freitext',
        width: 110,
        render: (data: any, row: IDataTableRow) =>
          isEditRow(row, state.editRow.rowId) || isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field
                name="freeText"
                component={FormikTextField}
                autoComplete="off"
                inputProps={{ tabIndex: -1 }}
              />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'type',
        label: 'Positionsart',
        width: 130,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="type" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : isEditRow(row, state.editRow.rowId) ? (
            <Field
              component={FormikSelect}
              name="type"
              FormControlProps={{ className: classes.noTopMargin }}
              className={classes.noTopMargin}
            >
              <MenuItem value="" key={2}>
                <em />
              </MenuItem>
              {Object.entries(ItemType).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Field>
          ) : null,
      },
      {
        id: 'volume',
        label: 'Angebots Menge',
        width: 75,
        render: (data: any, row: IDataTableRow) =>
          isEditRow(row, state.editRow.rowId) || isCreateRow(row) ? (
            <RootRef rootRef={itemVolumeInputRef}>
              <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
                <Field name="volume" component={FormikTextField} autoComplete="off" />
              </HotKeysWrapper>
            </RootRef>
          ) : (
            formatVolume(data)
          ),
      },
      {
        id: 'unit',
        label: 'Einheit',
        width: 65,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="unit" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'appliedPrice',
        label: 'Preis',
        width: 105,
        render: (appliedPrice: number, row: IDataTableRow) =>
          isEditRow(row, state.editRow.rowId) || isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="appliedPrice" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : (
            formatCurrency(Dinero({ amount: appliedPrice }))
          ),
      },
      {
        id: 'netPrice',
        label: 'Nettopreis',
        width: 105,
        hideOnDefault: true,
        render: (netPrice: any) => (!isNil(netPrice) ? formatCurrency(netPrice) : ''),
      },
      {
        id: 'netOfferSum',
        label: 'Angebots Betrag Netto',
        width: 120,
        hideOnDefault: true,
        render: (offerSum: any) => (!isNil(offerSum) ? formatCurrency(offerSum) : ''),
      },
      {
        id: 'grossOfferSum',
        label: 'Angebot Betrag Brutto',
        width: 120,
        render: (offerSum: any) => (!isNil(offerSum) ? formatCurrency(offerSum) : ''),
      },
      {
        id: 'applyScaleDiscount',
        label: 'Staffel rabatt',
        width: 60,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="applyScaleDiscount" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'comment',
        label: 'Kommentar Position',
      },
      {
        id: 'customerComment',
        label: 'Bemerkungen Kunde',
        width: 110,
        render: (data: any, row: IDataTableRow) =>
          isEditRow(row, state.editRow.rowId) || isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field
                name="customerComment"
                component={FormikTextField}
                autoComplete="off"
                inputProps={{ tabIndex: -1 }}
              />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'category',
        label: 'Kategorie',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="category" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'doneVolume',
        label: 'Bereits ausge messene Menge',
        width: 80,
        render: (data: any, row: IDataTableRow) => (!isCreateRow(row) ? formatVolume(data) : null),
      },
      {
        id: 'chargedVolume',
        label: 'Bereits verrechnete Menge',
        width: 90,
        render: (data: any, row: IDataTableRow) => (!isCreateRow(row) ? formatVolume(data) : null),
      },
      {
        id: 'markingStyle',
        label: 'Typ',
        width: 75,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="markingStyle" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'color',
        label: 'Farbe',
        width: 80,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="color" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'dimensionOne',
        label: 'Dimension 1',
        width: 120,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="dimensionOne" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'dimensionTwo',
        label: 'Dimension 2',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="dimensionTwo" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'reflexion',
        label: 'Reflektion',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="reflexion" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'material',
        label: 'Verbrauchsmaterial',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="material" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'targetConsumptionPerUnit',
        label: 'Sollverbrauch EinheitMat/Einheitpos',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field
                name="targetConsumptionPerUnit"
                component={FormikTextField}
                autoComplete="off"
              />
            </HotKeysWrapper>
          ) : (
            formatVolume(data)
          ),
      },
      {
        id: 'timeRequired',
        label: 'Zeitbedarf min/einheit',
        hideOnDefault: true,
        render: (data: any, row: IDataTableRow) =>
          isNotAcronymSearch && isCreateRow(row) ? (
            <HotKeysWrapper tableRef={tableRef} itemVolumeInputRef={itemVolumeInputRef}>
              <Field name="timeRequired" component={FormikTextField} autoComplete="off" />
            </HotKeysWrapper>
          ) : null,
      },
      {
        id: 'hasFiles',
        label: 'Daten',
        width: 60,
        render: (hasFiles: boolean) => (hasFiles ? <Attachment color="action" /> : ' '),
      },
      {
        id: 'openVolume',
        label: 'Noch offene Menge',
        width: 65,
        render: (data: number, row: IDataTableRow) =>
          !isCreateRow(row) ? formatVolume(data) : null,
      },
    ];
  }, [
    values,
    classes.autoCompleteRoot,
    classes.notStandardCatalog,
    acronymRef,
    tableRef,
    itemVolumeInputRef,
    catalogId,
    activeColumns,
    dispatch,
    state.editRow.rowId,
    itemBoilerplateSelectOnChange,
    classes.noTopMargin,
  ]);

  const renderActionPositionRow = (locationId: string, containerRowId: string) => {
    return (
      <>
        <Tooltip title="Position hinzufügen">
          <IconButton onClick={itemActions.onAddItem(locationId)}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zeile hinzufügen">
          <IconButton onClick={() => itemActions.onCreateRow(containerRowId)}>
            <PlaylistAddIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      onReorderLocation({
        ...parseCompositeLocationId(result.draggableId),
        index: result.destination.index,
      });
    },
    [onReorderLocation],
  );

  const toggleFinishedItems = useCallback(() => {
    onToggleFinishedItems?.();

    dispatch({ type: BoqTableActionType.TOGGLE_SHOW_FINISHED_ITEMS });
  }, [dispatch, onToggleFinishedItems]);

  const actionButtonProps =
    entityType === BillOfQuantityEntityType.ORDER
      ? { actionText: 'Auftrag', actionSubLink: 'auftraege' }
      : { actionText: 'Angebote', actionSubLink: 'offerten' };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DataTableHotKeysWrapper
        containerRows={containerRows}
        search={
          <Search
            loading={queryLoading}
            columns={searchColumns}
            searchState={searchState}
            onSubmit={onSearchSubmit}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: 'PROJECT_BOQ_ITEMS',
          alwaysOpenRowIds: [NEW_ROW_ID],
          onChangeActiveRow: (activeRowPath) =>
            dispatch({
              type: BoqTableActionType.SET_ACTIVE_ROW_PATH,
              payload: { rowPath: activeRowPath },
            }),
          onChangeHiddenColumns: setActiveColumns,
          fixedWidthColumns: true,
          fixedWidthColumnsHeader: true,
          hideEmptyContainers: !!searchState.searchTerm,
          levels: [
            {
              columns: billOfQuantityColumns,
              isDefaultClosed: true,
              isDragAndDropEnabled,
              isLevelToggleButtonVisible,
              isOnLoadMoreDisabled,
              onLoadMore,
              className: (row) => {
                if (row.data.statuses === BOQStatus.L8) {
                  return classes.L8;
                }
                return getBlockedOfferClassName(row.data.isOfferBlocked);
              },
              setDroppableProps: (row) => {
                return {
                  id: row.id,
                  type: `LOCATION_ONES_${row.id}`,
                };
              },
              rowActions: (data: any) => (
                <>
                  {renderActionCreateLocation(data.row.data.defaultLocation.id, onCreateLocation)}
                  {renderActionPositionRow(data.row.data.defaultLocation.id, data.row.id)}
                  <Tooltip title={`${actionButtonProps.actionText} editieren`}>
                    <Link
                      to={`/projekte/${projectNumber}/${actionButtonProps.actionSubLink}/${data.row.data.id}/details/editieren`}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <DeleteBillOfQuantityAction
                    buttonPrefixText={actionButtonProps.actionText}
                    billOfQuantity={data.row.data}
                    row={data.row}
                  />
                  <Tooltip title={`${actionButtonProps.actionText} duplizieren`}>
                    <IconButton
                      aria-label={`${actionButtonProps.actionText} duplizieren`}
                      onClick={() =>
                        dispatch({
                          type: BoqTableActionType.SET_DUPLICATE_BOQ_ID,
                          payload: { id: data.row.data.id },
                        })
                      }
                    >
                      <Filter2Icon />
                    </IconButton>
                  </Tooltip>
                  <PdfPrint
                    printType={PdfPrintType.BILL_OF_QUANTITY}
                    pdfFilename={(params) => {
                      const isConfirmation = params.includes(
                        AvailableRapportParameters.CONFIRMATION,
                      );
                      const isDeliveryNote = params.includes(
                        AvailableRapportParameters.DELIVERY_NOTE,
                      );
                      const isOrder = data.row.data.entityType === 'ORDER';

                      return `${formatDate().replace(/\//g, '.')}_${
                        isDeliveryNote ? 'LS' : isConfirmation ? 'AB' : isOrder ? 'AU' : 'OF'
                      }_${projectNumber}${
                        data.row.data.project.projectName
                          ? `_${data.row.data.project.projectName}`
                          : ''
                      }_${data.row.data.name}.pdf`.replace(/ /g, '_');
                    }}
                    data={{
                      id: data.row.data.id,
                      locations: true,
                      summary: false,
                      price: true,
                      storeInProject: false,
                      confirmation: false,
                      deliveryNote: false,
                    }}
                    rapportParametersToShow={[
                      AvailableRapportParameters.STORE_IN_PROJECT,
                      AvailableRapportParameters.LOCATIONS,
                      AvailableRapportParameters.PRICE,
                      AvailableRapportParameters.SUMMARY,
                      AvailableRapportParameters.CONFIRMATION,
                      AvailableRapportParameters.DELIVERY_NOTE,
                    ]}
                    disabled={false}
                  />
                </>
              ),
            },
            {
              columns: locationColumns,
              isDefaultClosed: true,
              isDragAndDropEnabled,
              isLevelToggleButtonVisible,
              isOnLoadMoreDisabled,
              onLoadMore,
              setDraggableProps: (row) => {
                const { billOfQuantityId } = parseCompositeLocationId(row.id);
                return {
                  id: row.id,
                  type: `LOCATION_ONES_${billOfQuantityId}`,
                };
              },
              setDroppableProps: (row) => {
                const { billOfQuantityId, locationId } = parseCompositeLocationId(row.id);
                return {
                  id: row.id,
                  type: `LOCATION_TWOS_${billOfQuantityId}_${locationId}`,
                };
              },
              rowActions: (data: any) => (
                <>
                  {renderActionCreateLocation(data.row.data.id, onCreateLocation)}
                  {renderActionPositionRow(data.row.data.id, data.row.id)}
                  {renderActionDeleteLocation(
                    () =>
                      dispatch({
                        type: BoqTableActionType.TOGGLE_DELETE_LOCATION,
                        payload: {
                          deleteData: { openDeleteDialog: true, locationId: data.row.id },
                        },
                      }),
                    data.row.data.canBeDeleted,
                  )}
                  {renderActionsCopyLocation(() =>
                    dispatch({
                      type: BoqTableActionType.SET_COPY_LOCATION_STATE,
                      payload: {
                        data: {
                          billOfQuantityId: data.row.data.billOfQuantity.id,
                          locationCompositeId: data.row.id,
                        },
                      },
                    }),
                  )}
                  <Tooltip title="Örtlichkeit editieren">
                    <IconButton
                      onClick={() =>
                        dispatch({
                          type: BoqTableActionType.SET_EDIT_LOCATION_ID,
                          payload: { id: data.row.id },
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
            {
              columns: locationColumns,
              isDefaultClosed: true,
              isDragAndDropEnabled,
              isLevelToggleButtonVisible,
              isOnLoadMoreDisabled,
              onLoadMore,
              setDraggableProps: (row) => {
                const { billOfQuantityId, parentLocationId } = parseCompositeLocationId(row.id);
                return {
                  id: row.id,
                  type: `LOCATION_TWOS_${billOfQuantityId}_${parentLocationId}`,
                };
              },
              rowActions: (data: any) => (
                <>
                  {renderActionPositionRow(data.row.data.id, data.row.id)}
                  {renderActionDeleteLocation(
                    () =>
                      dispatch({
                        type: BoqTableActionType.TOGGLE_DELETE_LOCATION,
                        payload: {
                          deleteData: { openDeleteDialog: true, locationId: data.row.id },
                        },
                      }),
                    data.row.data.canBeDeleted,
                  )}
                  {renderActionsCopyLocation(() =>
                    dispatch({
                      type: BoqTableActionType.SET_COPY_LOCATION_STATE,
                      payload: {
                        data: {
                          billOfQuantityId: data.row.data.billOfQuantity.id,
                          locationCompositeId: data.row.id,
                        },
                      },
                    }),
                  )}
                  <Tooltip title="Örtlichkeit editieren">
                    <IconButton
                      onClick={() =>
                        dispatch({
                          type: BoqTableActionType.SET_EDIT_LOCATION_ID,
                          payload: { id: data.row.id },
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
            {
              columns: itemColumns,
              onDoubleRowClick: (row) => {
                if (state.editRow.rowId !== row.id) {
                  dispatch({
                    type: BoqTableActionType.SET_EDIT_ROW,
                    payload: { rowId: row.id, newRowContainer: '' },
                  });
                }
              },
              actions: () => (
                // semantically wrong place but for now just going with it!
                <>
                  <Tooltip
                    title={`Abgeschlossene Positionen ${
                      state.showFinishedItems ? 'ausblenden' : 'einblenden'
                    }`}
                  >
                    <IconButton onClick={toggleFinishedItems}>
                      {state.showFinishedItems ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>
                  {L8Button}
                  <Tooltip title="Angebot erstellen">
                    <Fab
                      color="primary"
                      size="small"
                      onClick={() => dispatch({ type: BoqTableActionType.TOGGLE_CREATE_BOQ })}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </>
              ),
              rowActions: (data: any) => {
                const handleEditItem = () =>
                  dispatch({
                    type: BoqTableActionType.SET_EDIT_ITEM_ID,
                    payload: { id: data.row.id },
                  });
                const handleDeleteClick = () => itemActions.deleteItem(data.row.id);

                return data.row.id === state.editRow.rowId ? (
                  <CancelSaveIconButtons
                    isDisabled={isSubmitting}
                    onCancel={itemActions.onCancelEditRow}
                  />
                ) : (
                  <>
                    <Tooltip title="Editieren">
                      <IconButton onClick={handleEditItem} aria-label="Editieren">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen">
                      <span>
                        <IconButton
                          onClick={handleDeleteClick}
                          aria-label="Löschen"
                          disabled={!data.row.data.canBeDeleted}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Positionen ersetzen">
                      <IconButton
                        onClick={() =>
                          dispatch({
                            type: BoqTableActionType.SET_REPLACE_ITEM,
                            payload: {
                              id: data.row.id,
                              productNumber: data.row.data.productNumber,
                            },
                          })
                        }
                        aria-label="Positionen ersetzen"
                      >
                        <FindReplaceIcon />
                      </IconButton>
                    </Tooltip>
                    <CopyItemAction
                      itemId={removeTableTypeFromId(data.row.id)}
                      flattenedContainerRows={flattenedContainerRows}
                      showFinishedItems={state.showFinishedItems}
                    />
                  </>
                );
              },
            },
          ],
        }}
      >
        {(context) => {
          if (context.shownColumnIds.length !== shownColumnIdsLengthRef.current) {
            setActiveColumns(context.shownColumnIds);
          }

          return <DataTableBody context={context} />;
        }}
      </DataTableHotKeysWrapper>
    </DragDropContext>
  );
};

export default BillOfQuantityDataTable;
