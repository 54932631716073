import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { LockOutlined as LockIcon } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { SetAuth, SetAuthVariables } from './types/SetAuth';
import { LoginMutation, LoginMutationVariables } from './types/LoginMutation';
import { IsLoggedIn } from './types/IsLoggedIn';
import { errorPrefixRemover } from '../utils/errorPrefixRemover';
import AppErrorMessage from '../components/Page/AppErrorMessage';
import {
  columnsSettingfragment,
  SET_CURRENT_COLUMNS_SETTING,
} from '../components/ColumnsSettings/clumnsSettings.queries';
import {
  SetCoulmnsSetting,
  SetCoulmnsSettingVariables,
} from '../components/ColumnsSettings/types/SetCoulmnsSetting';

export const LOGIN = gql`
  mutation LoginMutation($name: String!, $password: String!) {
    login(name: $name, password: $password) {
      token
      user {
        id
        name
        role
        columnsSettings {
          ...ColumnsSettingFields
        }
      }
    }
  }
  ${columnsSettingfragment}
`;

export const GET_IS_LOGGED_IN = gql`
  query IsLoggedIn {
    auth @client {
      isLoggedIn
    }
  }
`;

export const GET_LOGGEDIN_USER = gql`
  query GetLoggedInUser {
    auth @client {
      role
      name
    }
  }
`;

export const SET_AUTH = gql`
  mutation SetAuth($auth: ClientAuthInput!, $remember: Boolean!) {
    setAuth(auth: $auth, remember: $remember) @client
  }
`;

const getDefaultColumnsSetting = (userColumnsSettings: any[]) => {
  const defaultColumnSetting = userColumnsSettings[0];

  return {
    __typename: 'CurrentColumnsSetting',
    name: defaultColumnSetting.name,
    id: defaultColumnSetting.id,
    tableSettings: defaultColumnSetting.tableSettings,
  };
};

const LoginWrapper = styled('main')(({ theme }: { theme: Theme }) => ({
  width: 'auto',
  display: 'block', // Fix IE 11 issue.
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  [theme.breakpoints.up(400 + theme.spacing(6))]: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const LoginPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 3, 3),
}));

const LoginAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const LoginForm = styled(Form)(({ theme }: { theme: Theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(3),
}));

interface ILocation {
  from: { pathname: string };
}

const Login: React.FC<RouteComponentProps<any, any, ILocation>> = ({ location }) => {
  const { from } = location.state || { from: { pathname: '/' } };
  const { data } = useQuery<IsLoggedIn>(GET_IS_LOGGED_IN);
  const [setAuth] = useMutation<SetAuth, SetAuthVariables>(SET_AUTH);
  const [setcurrentColumnSetting] = useMutation<SetCoulmnsSetting, SetCoulmnsSettingVariables>(
    SET_CURRENT_COLUMNS_SETTING,
  );

  const [login, { error }] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN, {
    refetchQueries: [{ query: GET_IS_LOGGED_IN }, { query: GET_LOGGEDIN_USER }],
  });

  // useEffect(() => {
  //   const currentUrl = new URL(window.location.href);
  //   const hasManualLoginSearchParam = currentUrl.searchParams.has('manualLogin');
  //   if (!hasManualLoginSearchParam) {
  //     login({
  //       variables: { name: 'Demo', password: 'demouser123' },
  //       update: (cache, { data, errors }) => {
  //         if (errors || !data) {
  //           return;
  //         }
  //         const auth = {
  //           __typename: 'Auth',
  //           token: data.login.token,
  //           role: data.login.user.role,
  //           name: data.login.user.name,
  //         };
  //         setAuth({ variables: { auth, remember: true } });
  //
  //         if (data.login.user.columnsSettings && data.login.user.columnsSettings.length > 0) {
  //           setcurrentColumnSetting({
  //             variables: {
  //               columnsSetting: getDefaultColumnsSetting(data.login.user.columnsSettings),
  //             },
  //           });
  //         }
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (data && data.auth.isLoggedIn) {
    return <Redirect to={from} />;
  }

  return (
    <>
      {error && <AppErrorMessage message={error ? errorPrefixRemover(error.message) : ''} />}
      <Formik
        initialValues={{ name: '', password: '', remember: false }}
        onSubmit={async ({ name, password, remember }, { setSubmitting }) => {
          await login({
            variables: { name, password },
            update: (cache, { data, errors }) => {
              if (errors || !data) {
                return;
              }
              const auth = {
                __typename: 'Auth',
                token: data.login.token,
                role: data.login.user.role,
                name: data.login.user.name,
              };
              setAuth({ variables: { auth, remember } });

              if (data.login.user.columnsSettings && data.login.user.columnsSettings.length > 0) {
                setcurrentColumnSetting({
                  variables: {
                    columnsSetting: getDefaultColumnsSetting(data.login.user.columnsSettings),
                  },
                });
              }
            },
          });
          setSubmitting(false);
        }}
      >
        {({ handleChange }) => (
          <LoginWrapper>
            <LoginPaper>
              <LoginAvatar>
                <LockIcon />
              </LoginAvatar>
              <Typography component="h1" variant="h5">
                Anmelden
              </Typography>
              <LoginForm>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <Input name="name" autoComplete="username" autoFocus onChange={handleChange} />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Passwort</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="remember"
                      onChange={handleChange}
                      value="remember"
                      color="primary"
                    />
                  }
                  label="Angemeldet bleiben"
                />
                <SubmitButton type="submit" fullWidth variant="contained" color="primary">
                  Anmelden
                </SubmitButton>
              </LoginForm>
            </LoginPaper>
          </LoginWrapper>
        )}
      </Formik>
    </>
  );
};

export default Login;
