import { MutationUpdaterFn } from 'apollo-client';
import { Formik } from 'formik';
import { isEmpty, isNil, remove } from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { HotKeys } from 'react-hotkeys';
import * as Yup from 'yup';
import { useL8Filter } from '../../../hooks/BillOfQuantity/useL8Filter';
import { ProjectCatalog_project } from '../../../pages/BillOfQuantity/types/ProjectCatalog';
import { refetchBillBoqDropdown } from '../../../pages/Bills/BillDetails';
import {
  removeTableTypeFromId,
  swapTableType,
} from '../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import {
  ADD_ROW_TO_DATA_TABLE,
  mutateQueryCacheEntry,
  REMOVE_DATA_TABLE_ROW,
} from '../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../services/types/AddRowToDataTable';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../services/types/RemoveDataTableRow';
import {
  BillOfQuantityEntityType,
  CreateItemFromBoilerplateInput,
  RowType,
  TableType,
} from '../../../types/graphql';
import { default as createCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import { flattenContainerRows } from '../../../utils/flattenRows/flattenRows';
import { getChangedFormValues } from '../../../utils/form/getChanged';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import ConfirmationDialog from '../../ConfirmationDialog';
import { ILevelOptions, OnLoadMore } from '../../DataTable/types';
import InlineEditingFormikForm from '../../Form/InlineEditingFormikForm';
import {
  default as ItemDetails,
  isItemComputedChange,
  UPDATE_ITEM_MUTATION,
} from '../../Item/ItemDetails';
import ItemImporter from '../../Item/ItemImporter';
import { useImportCatalog } from '../../Item/ItemImporter/ItemBoilerplateSearch/importCatalog';
import { UpdateItem, UpdateItemVariables } from '../../Item/types/UpdateItem';
import { LocationDetails } from '../../Location/LocationDetails';
import AppErrorMessage from '../../Page/AppErrorMessage';
import AppProgress from '../../Page/AppProgress';
import { CopyLocationDialog } from '../CopyLocationDialog';
import CreateDialog from '../CreateDialog';
import CreateLocationDialog from '../CreateLocationDialog';
import { BillOfQuantityDuplicateForm } from '../DuplicateBillOfQuantityDialog';
import ReplaceItems from '../ReplaceItems';
import BillOfQuantityDataTable from './BillOfQuantityDataTable';
import {
  CREATE_BILL_OF_QUANTITY_MUTATION,
  CREATE_ITEM_MUTATION,
  CREATE_ITEMS_MUTATION,
  CREATE_LOCATION_MUTATION,
  DELETE_ITEM_MUTATION,
  DELETE_LOCATION_MUTATION,
  REORDER_LOCATION_MUTATION,
} from './BillOfQuantityTable.queries';
import {
  BillOfQuantityTableReducer,
  BoqTableAction,
  BoqTableActionType,
  IBoqTableReducerState,
} from './billOFQuantityTable.reducer';
import { addNewRow, findRow, NEW_ROW_ID } from './BillOfQuantityTable.utils';
import { CreateBillOfQuantity, CreateBillOfQuantityVariables } from './types/CreateBillOfQuantity';
import {
  CreateItemFromBoilerplate,
  CreateItemFromBoilerplateVariables,
} from './types/CreateItemFromBoilerplate';
import {
  CreateItemsFromBoilerplates,
  CreateItemsFromBoilerplatesVariables,
} from './types/CreateItemsFromBoilerplates';
import { CreateLocation, CreateLocationVariables } from './types/CreateLocation';
import { DeleteItem, DeleteItemVariables } from './types/DeleteItem';
import { DeleteLocation, DeleteLocationVariables } from './types/DeleteLocation';
import { ReorderLocation, ReorderLocationVariables } from './types/ReorderLocation';
import { isCompositeLocationId, parseCompositeLocationId } from './utils/compositeLocationId';
import { tryParseCompositeLocationId, useBillOfQuantityData } from './utils/paginationHelper';
import {
  executeForPopulatedBoqTableTypes,
  markProjectHasBoqs,
} from './utils/paginationHelper/executeForBoqTypes';
import {
  mapBillOfQuantityToContainerRow,
  mapItemToInnerTableRow,
} from './utils/paginationHelper/mapper';
import { STRUCTURE_QUERY } from './utils/paginationHelper/queries';
import {
  BillOfQuantityStructureQuery,
  BillOfQuantityStructureQueryVariables,
} from './utils/paginationHelper/types/BillOfQuantityStructureQuery';

export enum ItemType {
  A = 'Alternativ',
  PP = 'Per-Position',
  E = 'Eventual',
  N = 'Normal',
}

export const getDbItemTypeFromString = (s: string) => {
  return Object.entries(ItemType).find(([, v]) => v === s)?.[0];
};

interface IBillOfQuantityTableProps {
  project: ProjectCatalog_project;
  entityType: BillOfQuantityEntityType;
}

const formatCurrencyForEdit = createCurrencyFormatter({ format: '0.00' });

const editRowValidationSchema = Yup.object().shape({
  volume: Yup.number()
    .typeError('Menge muss eine Zahl sein')
    .required('Menge muss eingegeben werden'),
  appliedPrice: Yup.number().typeError('Preis muss eine Zahl sein').nullable(),
  freeText: Yup.string().typeError('Freitext muss ein Text sein').nullable(),
  customerComment: Yup.string().typeError('Bemerkungen Kunde muss ein Text sein').nullable(),
});

const keyMap = {
  createBillOfQuantity: 'ctrl+l',
  createOpenCreateLocation: 'ctrl+o',
  createNewPosition: 'ctrl+y',
  addRow: 'ctrl+q',
};

const initialFormValues = {
  acronym: '',
  productNumber: '',
  catalogSection: '',
  catalogSubsection: '',
  catalogMainGroup: '',
  catalogSectionDescription: '',
  catalogSubsectionDescription: '',
  catalogMainGroupDescription: '',
  descriptionOne: '',
  descriptionTwo: '',
  unit: '',
  appliedPrice: '',
  applyScaleDiscount: '',
  comment: '',
  customerComment: '',
  category: '',
  markingStyle: '',
  color: '',
  dimensionOne: '',
  dimensionTwo: '',
  reflexion: '',
  material: '',
  targetConsumptionPerUnit: '',
  timeRequired: '',
  volume: '',
  type: '',
};

const BillOfQuantityTable: React.FunctionComponent<IBillOfQuantityTableProps> = ({
  project: { projectNumber, catalog },
  entityType,
}) => {
  useEffect(
    () =>
      window.parent.postMessage(
        entityType === 'ORDER' ? 'momoProjectOrderTable' : 'momoProjectOfferTable',
        '*',
      ),
    [entityType],
  );

  const itemVolumeInputRef = useRef<HTMLBaseElement | null>(null);
  const acronymRef = useRef<HTMLElement | null>(null);
  const tableRef = useRef<HTMLElement | null>(null);
  const client = useApolloClient();

  const [state, dispatch] = useReducer<React.Reducer<IBoqTableReducerState, BoqTableAction>>(
    BillOfQuantityTableReducer,
    {
      showSelect: false,
      showCreateBoq: false,
      showCreateLocation: false,
      duplicateBillOfQuantityId: '',
      replaceItem: { id: '', productNumber: '' },
      selectedLocationId: '',
      editRow: { rowId: '', newRowContainer: '' },
      newRowData: null,
      currentItemBoilerplateId: null,
      editItemId: '',
      editLocationId: '',
      copyLocationState: null,
      activeRowPath: [],
      deleteLocation: { openDeleteDialog: false, locationId: '' },
      showFinishedItems: true,
      optimisticReorder: undefined,
    },
  );

  const {
    onSearch,
    fetchItemsConnection,
    hasNextPage,
    mappedRows,
    reorderLocation: reorderLocationInCache,
    onToggleL8,
    onToggleShowFinishedItems,
    loading,
    hasL8BillOfQuantities,
  } = useBillOfQuantityData(
    projectNumber,
    entityType === 'ORDER' ? TableType.ORDER : TableType.OFFER,
    entityType,
  );

  const { executeImportCatalog } = useImportCatalog();

  // opens the details dialog when a location is copied
  const onLocationCopied = useCallback(
    (id) => {
      dispatch({
        type: BoqTableActionType.SET_EDIT_LOCATION_ID,
        payload: { id },
      });
    },
    [dispatch],
  );

  const { L8Button } = useL8Filter({
    onToggle: onToggleL8,
    disabled: !hasL8BillOfQuantities,
  });

  const refetchContainerAfterItemsAdd = useCallback(
    async (containerId: string) => {
      return executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        (tableType) => {
          return PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsInContainer(
            [{ containerId: swapTableType(tableType)(containerId) }],
            {
              isFinished: state.showFinishedItems,
            },
          );
        },
        entityType,
      );
    },
    [state.showFinishedItems, client, projectNumber, entityType],
  );

  const [addItem] = useMutation<CreateItemFromBoilerplate, CreateItemFromBoilerplateVariables>(
    CREATE_ITEM_MUTATION,
  );
  const [addItems] = useMutation<CreateItemsFromBoilerplates, CreateItemsFromBoilerplatesVariables>(
    CREATE_ITEMS_MUTATION,
  );

  const [updateItem, { error: updateItemError }] = useMutation<UpdateItem, UpdateItemVariables>(
    UPDATE_ITEM_MUTATION,
  );

  const [deleteItem] = useMutation<DeleteItem, DeleteItemVariables>(DELETE_ITEM_MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        (tableType) => {
          const normalizedId = removeTableTypeFromId(data.deleteItem.id);

          return PAGINATION_HELPERS[projectNumber][tableType]?.removeItemFromCache({
            data: { deleteItem: { __typename: 'Item', id: normalizedId } },
          });
        },
        entityType,
      );
    },
  });

  const deleteItemAction = useCallback(
    async (id: string) => {
      await deleteItem({ variables: { id: removeTableTypeFromId(id) } });
    },
    [deleteItem],
  );

  const removeLocationFromStructureQuery = useCallback(
    (locationId: string) => {
      mutateQueryCacheEntry<BillOfQuantityStructureQuery, BillOfQuantityStructureQueryVariables>(
        client.cache,
        STRUCTURE_QUERY,
        { projectNumber, entityType },
        (cacheEntry) => {
          const defaultLocations = cacheEntry.billOfQuantities.map(
            (billOfQuantity) => billOfQuantity.defaultLocation,
          );

          defaultLocations.forEach((defaultLocation) => {
            remove(defaultLocation.locations!, (location) => location.id === locationId);
            defaultLocation.locations!.forEach((location) => {
              remove(location.locations!, (location) => location.id === locationId);
            });
          });
        },
      );
    },
    [client.cache, projectNumber, entityType],
  );

  const removeLocationFromCache = useCallback(
    async (containerIdToRemove: string) => {
      const { billOfQuantityId, locationId } = parseCompositeLocationId(containerIdToRemove);

      removeLocationFromStructureQuery(locationId);

      await executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        async (tableType) => {
          await PAGINATION_HELPERS[projectNumber][tableType]?.updateBillOfQuantityComputedFields(
            billOfQuantityId,
          );
          await client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
            query: REMOVE_DATA_TABLE_ROW,
            variables: {
              where: { id: swapTableType(tableType)(containerIdToRemove), tableType },
            },
          });
        },
        entityType,
      );
    },
    [client, projectNumber, removeLocationFromStructureQuery, entityType],
  );

  const [deleteLocationMutation] = useMutation<DeleteLocation, DeleteLocationVariables>(
    DELETE_LOCATION_MUTATION,
  );

  const onDeleteLocation = useCallback(async () => {
    await deleteLocationMutation({
      variables: { id: parseCompositeLocationId(state.deleteLocation.locationId).locationId },
    });

    await removeLocationFromCache(state.deleteLocation.locationId);
  }, [removeLocationFromCache, deleteLocationMutation, state.deleteLocation.locationId]);

  const addLocationToBoqCaches = useCallback<MutationUpdaterFn<CreateLocation>>(
    (cache, { data }) => {
      if (!data) {
        return;
      }

      executeForPopulatedBoqTableTypes(
        client,
        projectNumber,
        (tableType) =>
          PAGINATION_HELPERS[projectNumber][tableType]?.addLocationToCache(data.createLocation),
        entityType,
      );
    },
    [client, projectNumber, entityType],
  );

  const [createLocation] = useMutation<CreateLocation, CreateLocationVariables>(
    CREATE_LOCATION_MUTATION,
    {
      update: addLocationToBoqCaches,
    },
  );

  const [reorderLocation, { loading: isReordering, error: reorderingError }] = useMutation<
    ReorderLocation,
    ReorderLocationVariables
  >(REORDER_LOCATION_MUTATION);

  // calls the api for a reorder and already displays the reordered item at the new position until the source of truth is fetched
  const onReorderLocation = useCallback(
    async (variables) => {
      await reorderLocationInCache(variables);
      try {
        await reorderLocation({
          variables,
        });
      } catch (err) {
        // errors handled via `reorderingError` or `error` of the mutation/queries
        console.log(err);
      }
    },
    [reorderLocation, reorderLocationInCache],
  );

  const addBillOfQuantityToCache = useCallback<MutationUpdaterFn<CreateBillOfQuantity>>(
    async (cache, { data }) => {
      if (!data) {
        return;
      }

      const { createBillOfQuantity: createdBillOfQuantity } = data;

      // always add row in TableType.OFFER because it's only allowed on OFFER view even if not populated
      await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: {
          where: { id: TableType[entityType] },
          data: [
            {
              type: RowType.CONTAINER,
              row: mapBillOfQuantityToContainerRow(TableType.OFFER)(createdBillOfQuantity),
            },
          ],
        },
      });
    },
    [client, entityType],
  );

  const [createBillOfQuantity] = useMutation<CreateBillOfQuantity, CreateBillOfQuantityVariables>(
    CREATE_BILL_OF_QUANTITY_MUTATION,
    {
      update: addBillOfQuantityToCache,
    },
  );

  const handleEditRowCancel = () =>
    dispatch({
      type: BoqTableActionType.SET_EDIT_ROW,
      payload: { rowId: '', newRowContainer: '' },
    });

  const catalogId = catalog ? catalog.id : undefined;

  useEffect(() => {
    if (state.editRow && state.editRow.rowId === NEW_ROW_ID && acronymRef.current) {
      acronymRef.current.querySelector('input')!.focus();
    }
  }, [state.editRow]);

  const createOpenSelectItemBoilerplate = (locationId: string) => () => {
    dispatch({ type: BoqTableActionType.SET_SELECTED_LOCATION_ID, payload: { id: locationId } });

    dispatch({ type: BoqTableActionType.TOGGLE_SELECT });
  };

  const createOpenCreateLocation = (locationId: string) => () => {
    dispatch({ type: BoqTableActionType.SET_SELECTED_LOCATION_ID, payload: { id: locationId } });

    dispatch({ type: BoqTableActionType.TOGGLE_CREATE_LOCATION });
  };

  const createNewRow = (newRowContainer: string) => {
    dispatch({
      type: BoqTableActionType.SET_EDIT_ROW,
      payload: { rowId: NEW_ROW_ID, newRowContainer },
    });
  };

  const closeSelect = () => dispatch({ type: BoqTableActionType.TOGGLE_SELECT });

  const mappedRowsWithNewRow = useMemo(() => {
    return state.editRow.newRowContainer.length > 0
      ? addNewRow(mappedRows, state.editRow.newRowContainer, state.newRowData)
      : mappedRows;
  }, [state.editRow, state.newRowData, mappedRows]);

  const editRowData = useMemo(
    () => findRow(state.editRow.rowId, mappedRowsWithNewRow),
    [state.editRow.rowId, mappedRowsWithNewRow],
  );

  const editInitialValues = useMemo(
    () =>
      editRowData
        ? {
            ...initialFormValues,
            ...editRowData.data,
            acronym: editRowData.data.acronym || '',
            volume: editRowData.data.volume || '',
            appliedPrice: formatCurrencyForEdit(editRowData.data.appliedPrice) || '',
            freeText: editRowData.data.freeText || '',
            customerComment: editRowData.data.customerComment || '',
            productNumber: editRowData.data.productNumber || '',
            descriptionOne: editRowData.data.descriptionOne || '',
          }
        : initialFormValues,
    [editRowData],
  );

  const billOfQuantityRows = mappedRows;
  const handleEditRowSubmit = useCallback(
    async (
      addItemProps: typeof addItem,
      updateItemProps: typeof updateItem,
      values: any,
      setSubmitting: any,
    ) => {
      if (!billOfQuantityRows || billOfQuantityRows.length === 0) {
        return;
      }

      if (state.editRow.newRowContainer.length > 0) {
        // If the container is a billOfQuantity we need the defaultLocation
        const billOfQuantity = billOfQuantityRows.find(
          (b: any) => b.id === state.editRow.newRowContainer,
        );
        const containerId = state.editRow.newRowContainer;
        const locationId = billOfQuantity
          ? billOfQuantity.data.defaultLocation.id
          : // api doesn't want the accumulated locationd ID that's separated with colons, just
            // the ID of the location itself
            parseCompositeLocationId(containerId).locationId;

        const { data } = await addItemProps({
          variables: {
            itemBoilerplateId: values.itemBoilerplateId,
            volume: Number.parseFloat(values.volume),
            appliedPrice: Number.parseFloat(values.appliedPrice),
            freeText: values.freeText,
            locationId,
          },
        });

        await executeForPopulatedBoqTableTypes(
          client,
          projectNumber,
          async (tableType) => {
            await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
              query: ADD_ROW_TO_DATA_TABLE,
              variables: {
                where: { id: tableType },
                data: [
                  {
                    type: RowType.INNER,
                    containerId: swapTableType(tableType)(containerId),
                    row: mapItemToInnerTableRow(tableType)(data!.createItemFromBoilerplate),
                  },
                ],
              },
            });
          },
          entityType,
        );

        setSubmitting(false);

        dispatch({ type: BoqTableActionType.SET_NEW_ROW_DATA, payload: { data: null } });

        if (acronymRef.current) {
          acronymRef.current.querySelector('input')!.focus();
        }
      } else {
        // get only changed values to prevent the scale discount price
        // from being observed as a manual price change
        const changedValues = getChangedFormValues({ initialValues: editInitialValues, values });
        const { data } = await updateItemProps({
          variables: {
            id: removeTableTypeFromId(state.editRow.rowId),
            data: {
              ...(!isNil(changedValues.volume) && {
                volume: Number.parseFloat(changedValues.volume),
              }),
              ...(!isNil(values.appliedPrice) && {
                appliedPrice: Number.parseFloat(changedValues.appliedPrice),
              }),
              freeText: changedValues.freeText,
              customerComment: changedValues.customerComment,
              type: changedValues.type,
            },
          },
        });

        executeForPopulatedBoqTableTypes(
          client,
          projectNumber,
          (tableType) => {
            PAGINATION_HELPERS[projectNumber][tableType]?.updateItem(
              data,
              isItemComputedChange(changedValues),
            );
          },
          entityType,
        );

        setSubmitting(false);
        handleEditRowCancel();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      billOfQuantityRows,
      editInitialValues,
      state.editRow.newRowContainer,
      state.editRow.rowId,
      client,
      projectNumber,
    ],
  );

  const hotKeyHandlers = useMemo(
    () => ({
      createBillOfQuantity(e: KeyboardEvent | undefined) {
        if (e) {
          e.preventDefault();
        }
        dispatch({ type: BoqTableActionType.TOGGLE_CREATE_BOQ });
      },
      createOpenCreateLocation(e: KeyboardEvent | undefined) {
        if (e) {
          e.preventDefault();
        }
        const containerRow = state.activeRowPath.find((row) => !row.data.productNumber);
        if (containerRow) {
          createOpenCreateLocation(
            containerRow.data.defaultLocation
              ? containerRow.data.defaultLocation.id
              : containerRow.id,
          )();
        }
      },
      createNewPosition(e: KeyboardEvent | undefined) {
        if (e) {
          e.preventDefault();
        }
        const containerRow = state.activeRowPath.find((row) => !row.data.productNumber);
        if (containerRow) {
          const locationId = containerRow.data.defaultLocation
            ? containerRow.data.defaultLocation.id
            : containerRow.id;
          createOpenSelectItemBoilerplate(locationId)();
        }
      },
      addRow(e: KeyboardEvent | undefined) {
        if (e) {
          e.preventDefault();
        }
        const containerRow = state.activeRowPath.find((row) => !row.data.productNumber);
        if (containerRow) {
          createNewRow(containerRow.id);
        }
      },
    }),
    [state.activeRowPath],
  );

  const onLoadMore = useCallback<OnLoadMore>(
    async (type, containingRowId, after) => {
      const locationId = tryParseCompositeLocationId('locationId')(containingRowId);

      await fetchItemsConnection({
        take: 10,
        after: after ? removeTableTypeFromId(after) : undefined,
        where: { id: locationId },
        itemsWhere: { isFinished: state.showFinishedItems },
      });
    },
    [fetchItemsConnection, state.showFinishedItems],
  );

  const isOnLoadMoreDisabled = useCallback<
    Exclude<ILevelOptions['isOnLoadMoreDisabled'], undefined>
  >(
    (row) => {
      return !hasNextPage(row.id);
    },
    [hasNextPage],
  );

  const showError = reorderingError ?? updateItemError;
  return (
    <>
      {(loading || isReordering) && <AppProgress />}
      {showError && <AppErrorMessage message={errorPrefixRemover(showError.message)} />}
      <HotKeys keyMap={keyMap} handlers={hotKeyHandlers}>
        <Formik
          enableReinitialize
          initialValues={editInitialValues}
          validationSchema={editRowValidationSchema}
          validateOnChange={false}
          onSubmit={(values, meta) => {
            handleEditRowSubmit(
              addItem,
              updateItem,
              {
                ...values,
                itemBoilerplateId: state.currentItemBoilerplateId,
              },
              meta.setSubmitting,
            );
            dispatch({ type: BoqTableActionType.SET_NEW_ROW_DATA, payload: { data: null } });
          }}
        >
          {({ isSubmitting, values, dirty, ...formikProps }) => (
            <InlineEditingFormikForm
              dirty={dirty}
              enterUpSubmitHandler={() => formikProps.submitForm()}
              tabSubmitHandler={(e) => {
                if (e.target.name === 'save-button') {
                  // if not done this way we lose focus on the field that's being tabbed to
                  formikProps.validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      formikProps.submitForm();
                    }
                  });
                }
              }}
              clickAwaySubmitHandler={(e) => {
                // dont submit when user clicks on dropdown item
                if (e.target.nodeName === 'LI') {
                  return;
                }

                formikProps.submitForm();
              }}
            >
              <BillOfQuantityDataTable
                entityType={entityType}
                isDragAndDropEnabled={!isReordering}
                catalogId={catalogId}
                projectNumber={projectNumber}
                state={state}
                dispatch={dispatch}
                containerRows={mappedRowsWithNewRow}
                onSearchSubmit={({ terms, columns }) =>
                  onSearch({ terms, columns }, state.showFinishedItems)
                }
                onCreateLocation={createOpenCreateLocation}
                onReorderLocation={onReorderLocation}
                L8Button={L8Button}
                itemActions={{
                  onAddItem: createOpenSelectItemBoilerplate,
                  onCreateRow: createNewRow,
                  deleteItem: deleteItemAction,
                  onCancelEditRow: handleEditRowCancel,
                }}
                refs={{
                  itemVolumeInputRef,
                  acronymRef,
                  tableRef,
                }}
                queryLoading={loading}
                onLoadMore={onLoadMore}
                isOnLoadMoreDisabled={isOnLoadMoreDisabled}
                onToggleFinishedItems={onToggleShowFinishedItems({
                  isFinished: !state.showFinishedItems,
                })}
              />
            </InlineEditingFormikForm>
          )}
        </Formik>
        <ConfirmationDialog
          message="Bitte beachten Sie, dass alle zugehörigen Postionen und Örtlichkeiten gelöscht werden."
          title="Örtlichkeit löschen!"
          onConfirm={onDeleteLocation}
          open={state.deleteLocation.openDeleteDialog}
          onClose={() =>
            dispatch({
              type: BoqTableActionType.TOGGLE_DELETE_LOCATION,
              payload: {
                deleteData: {
                  openDeleteDialog: false,
                  locationId: '',
                },
              },
            })
          }
        />

        <ItemImporter
          open={state.showSelect}
          project={{ catalogId, projectNumber }}
          locationId={state.selectedLocationId}
          onAddItems={async (items, selectedCatalogId, isImportCatalog) => {
            const normalizedSelectedLocationId = removeTableTypeFromId(state.selectedLocationId);

            const locationRow = flattenContainerRows(mappedRows).find((row) => {
              return (
                tryParseCompositeLocationId('locationId')(removeTableTypeFromId(row.id)) ===
                normalizedSelectedLocationId
              );
            });
            if (!locationRow) {
              return;
            }

            const mutationDataInput = items.map<CreateItemFromBoilerplateInput>((item) => ({
              itemBoilerplateId: item.rowId,
              appliedPrice: item.appliedPrice,
              volume: item.volume,
              locationId: normalizedSelectedLocationId,
            }));

            if (isImportCatalog && selectedCatalogId) {
              await executeImportCatalog({
                variables: {
                  catalog: { id: selectedCatalogId },
                  targetLocation: { id: normalizedSelectedLocationId },
                },
              });
            } else {
              await addItems({ variables: { data: mutationDataInput } });
            }

            const isOnBillOfQuantity = !isCompositeLocationId(locationRow.id);
            const billOfQuantityId = isOnBillOfQuantity
              ? locationRow.data.id
              : parseCompositeLocationId(locationRow.id).billOfQuantityId;

            await refetchContainerAfterItemsAdd(locationRow.id);
            executeForPopulatedBoqTableTypes(
              client,
              projectNumber,
              (tableType) => {
                PAGINATION_HELPERS[projectNumber][tableType]?.updateBillOfQuantityComputedFields(
                  billOfQuantityId,
                );
              },
              entityType,
            );

            closeSelect();
          }}
          onClose={closeSelect}
        />

        <CreateDialog
          open={state.showCreateBoq}
          onCreate={async (data) => {
            await createBillOfQuantity({ variables: { projectNumber, data, entityType } });
            await refetchBillBoqDropdown(client, projectNumber);
            await markProjectHasBoqs(client, projectNumber);
          }}
          entityType={entityType}
          onClose={() => dispatch({ type: BoqTableActionType.TOGGLE_CREATE_BOQ })}
        />

        <CreateLocationDialog
          open={state.showCreateLocation}
          onSubmit={async ({ name }: any) => {
            await createLocation({
              variables: { parentLocationId: state.selectedLocationId, name },
            });
            dispatch({ type: BoqTableActionType.TOGGLE_CREATE_LOCATION });
          }}
          onClose={() => dispatch({ type: BoqTableActionType.TOGGLE_CREATE_LOCATION })}
        />

        <ReplaceItems
          projectNumber={projectNumber}
          itemToReplace={state.replaceItem}
          catalogId={catalogId}
          showFinishedItems={state.showFinishedItems}
          onCloseDialog={() =>
            dispatch({
              type: BoqTableActionType.SET_REPLACE_ITEM,
              payload: {
                id: '',
                productNumber: '',
              },
            })
          }
        />
        {state.editItemId && (
          <ItemDetails
            open={!!state.editItemId}
            itemId={state.editItemId}
            onClose={() =>
              dispatch({ type: BoqTableActionType.SET_EDIT_ITEM_ID, payload: { id: '' } })
            }
          />
        )}
        {state.editLocationId && (
          <LocationDetails
            id={state.editLocationId}
            open={!!state.editLocationId}
            onClose={() =>
              dispatch({ type: BoqTableActionType.SET_EDIT_LOCATION_ID, payload: { id: '' } })
            }
          />
        )}
        {state.duplicateBillOfQuantityId && (
          <BillOfQuantityDuplicateForm
            billOfQuantityId={state.duplicateBillOfQuantityId}
            entityType={entityType}
            onClose={() =>
              dispatch({ type: BoqTableActionType.SET_DUPLICATE_BOQ_ID, payload: { id: '' } })
            }
          />
        )}
        {state.copyLocationState && (
          <CopyLocationDialog
            locationCompositeId={state.copyLocationState.locationCompositeId}
            billOfQuantityId={state.copyLocationState.billOfQuantityId}
            onClose={() =>
              dispatch({
                type: BoqTableActionType.SET_COPY_LOCATION_STATE,
                payload: { data: null },
              })
            }
            onCopied={onLocationCopied}
          />
        )}
      </HotKeys>
    </>
  );
};

export default BillOfQuantityTable;
