import ApolloClient from 'apollo-client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { inRange, isEmpty, omit, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import {
  removeTableTypeFromId,
  swapTableType,
} from '../../../../../pages/Projects/TabMissions/MissionSelector/paginationHelpers/mapper';
import {
  ALL_TABLE_TYPES,
  filterRows,
  GET_DATA_TABLE,
  GET_PAGE_INFOS,
  REMOVE_DATA_TABLE_ROW,
  UPDATE_DATA_TABLE,
  UPDATE_DATA_TABLE_ROW,
  UPDATE_DATA_TABLE_ROWS,
} from '../../../../../services/graphql-client';
import { GetDataTable, GetDataTableVariables } from '../../../../../services/types/GetDataTable';
import { GetPageInfos } from '../../../../../services/types/GetPageInfos';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../../../services/types/RemoveDataTableRow';
import {
  UpdateDataTable,
  UpdateDataTableVariables,
} from '../../../../../services/types/UpdateDataTable';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../../../../services/types/UpdateDataTableRow';
import {
  UpdateDataTableRows,
  UpdateDataTableRowsVariables,
} from '../../../../../services/types/UpdateDataTableRows';
import {
  BillOfQuantityEntityType,
  BOQStatus,
  ItemWhereInput,
  TableType,
  UpdateDataTableRowsInput,
} from '../../../../../types/graphql';
import { unmarshalDataTable } from '../../../../../utils/dataTable/unmarshalDataTable';
import { flattenContainerRows } from '../../../../../utils/flattenRows/flattenRows';
import { IOptimisticReorder } from '../../../../../utils/mapBillOfQuantityToTableData';
import { IPaginationHelperFns, PAGINATION_HELPERS } from '../../../../../utils/paginationHelpers';
import { getAllContainerRows } from '../../../../../utils/paginationHelpers/getAllContainerRows';
import { IDataTableRow } from '../../../../DataTable/types';
import { UpdateItem } from '../../../../Item/types/UpdateItem';
import { ISearchSubmitProps } from '../../../../Search/Search';
import { DeleteItem } from '../../types/DeleteItem';
import {
  CompositeLocationId,
  ICompositeLocationParts,
  isCompositeLocationId,
  parseCompositeLocationId,
} from '../compositeLocationId';
import { findBillOfQuantityRowFromItemRow } from '../findBoqRowFromItem';
import { addLocationToCacheInBillOfQuantity } from './addLocationToCache';
import {
  BOQ_TABLE_TYPES,
  BoqTableType,
  executeForPopulatedBoqTableTypes,
} from './executeForBoqTypes';
import { fetchItemsConnectionBillOfQuantity } from './fetchItemsConnection';
import {
  buildBoqTableRowId,
  mapItemToInnerTableRow,
  mapToDataTable,
  sortContainerRowsByIndex,
} from './mapper';
import { onSearchBillOfQuantity } from './onSearch';
import { onToggleL8 as onToggleL8Helper } from './onToggleL8';
import {
  FULL_BOQ_STRUCTURE_FRAGMENT,
  GET_BILL_OF_QUANTITY_COMPUTED_FIELDS,
  REFETCH_ITEMS_COMPUTED_FIELDS_QUERY,
  STRUCTURE_QUERY,
} from './queries';
import { refetchItemsInContainerBillOfQuantity } from './refetchItemsInContainer';
import {
  BillOfQuantityStructureQuery,
  BillOfQuantityStructureQueryVariables,
} from './types/BillOfQuantityStructureQuery';
import { fullBoqStructureFields } from './types/fullBoqStructureFields';
import {
  GetBillOfQuantityComputedFields,
  GetBillOfQuantityComputedFieldsVariables,
} from './types/GetBillOfQuantityComputedFields';

export type UpdateItemData = Maybe<{
  updateItem: Partial<UpdateItem['updateItem']> & { id: string };
}>;

/**
 * Used to retrieve the last index of the sub-locations-structure of location with id=containerId
 *
 * e.x
 * O1 has 3 Children
 *  - O1.2 // index 0
 *  - O1.1 // index 1
 *  - O1.3 // index 2
 *
 * In this case 2 would be returned
 *
 * @param containerId the container from which to retrieve the sub-locations-structure
 * @returns the last location-index
 */
export const getLastLocationIdxAtLevel =
  (client: ApolloClient<any>, tableType: BoqTableType) => async (containerId: string) => {
    const dataTableRes = await client.query<GetDataTable, GetDataTableVariables>({
      query: GET_DATA_TABLE,
      variables: {
        id: tableType,
      },
    });

    if (!dataTableRes.data.dataTable) {
      return;
    }

    const {
      data: { dataTable },
    } = dataTableRes;

    const containerRows = getAllContainerRows(dataTable);
    const parentContainerRow = containerRows.find(
      (containerRow) => containerRow.id === containerId,
    );

    if (!parentContainerRow) {
      return;
    }

    const idxOfLastRowInParent =
      'containerRows' in parentContainerRow
        ? (parentContainerRow.containerRows?.length ?? 1) - 1
        : 0;

    return idxOfLastRowInParent;
  };

/**
 * Checks if given string is compositeLocationId
 * If true, return the wanted part of the parsedCompositeLocationId
 * If false, return the given string itself
 *
 * @param partToReturn accessor of an parsedCompositeLocationId
 * @returns part of parseCompositeLocationId if string is one or the string itself
 */
export const tryParseCompositeLocationId =
  (partToReturn: keyof ICompositeLocationParts) =>
  (maybeCompositeId: CompositeLocationId | string): string => {
    if (isCompositeLocationId(maybeCompositeId)) {
      return parseCompositeLocationId(maybeCompositeId)[partToReturn];
    }
    const normalId = removeTableTypeFromId(maybeCompositeId);

    return normalId;
  };
export const getTableType = (id: string): Maybe<TableType> => {
  for (const tableType of ALL_TABLE_TYPES) {
    if (id.includes(tableType)) {
      return tableType;
    }
  }

  return undefined;
};
export const isBoqTableType = (tableType: any): tableType is BoqTableType => {
  return BOQ_TABLE_TYPES.includes(tableType);
};

export const useBillOfQuantityData = (
  projectNumber: string,
  tableType: BoqTableType,
  entityType: BillOfQuantityEntityType,
) => {
  const client = useApolloClient();
  const [searchState, setSearchState] = useState<ISearchSubmitProps>({ columns: [], terms: [] });
  const [loading, setLoading] = useState(false);

  const { data: pageInfosData } = useQuery<GetPageInfos>(GET_PAGE_INFOS);
  const { data } = useQuery<GetDataTable, GetDataTableVariables>(GET_DATA_TABLE, {
    variables: { id: tableType },
    fetchPolicy: 'network-only',
  });

  const filteredDataTable = useMemo(() => {
    if (!data?.dataTable) {
      return null;
    }

    return filterRows(data.dataTable);
  }, [data]);

  const hasL8BillOfQuantities = data?.dataTable?.rows.some(
    (row) => JSON.parse(row.data).statuses === BOQStatus.L8,
  );

  const addBillOfQuantitiesToCache = useCallback(
    async (data: BillOfQuantityStructureQuery) => {
      const dataTable = mapToDataTable(data.billOfQuantities, tableType);
      const l8BillOfQuantities = dataTable.rows.filter(
        (row) => JSON.parse(row.data).statuses === BOQStatus.L8,
      );

      l8BillOfQuantities.forEach((billOfQuantity) => {
        billOfQuantity.hidden = true;
      });

      await client.query<UpdateDataTable, UpdateDataTableVariables>({
        query: UPDATE_DATA_TABLE,
        variables: {
          where: {
            id: tableType,
          },
          data: dataTable,
        },
      });
    },
    [client, tableType],
  );

  useQuery<BillOfQuantityStructureQuery, BillOfQuantityStructureQueryVariables>(STRUCTURE_QUERY, {
    skip: !!data?.dataTable && !isEmpty(data.dataTable.rows),
    variables: { projectNumber, entityType },
    fetchPolicy: 'cache-and-network',
    onCompleted: (structureData) => {
      if (!!data?.dataTable && !isEmpty(data.dataTable.rows)) {
        return;
      }
      return addBillOfQuantitiesToCache(structureData);
    },
  });

  const pageInfos = pageInfosData?.pageInfos;

  const fetchItemsConnection = useMemo<IPaginationHelperFns['fetchItemsConnection']>(
    () => fetchItemsConnectionBillOfQuantity(client, tableType)(searchState)(setLoading),
    [client, searchState, tableType],
  );

  const hasNextPage = useCallback<IPaginationHelperFns['hasNextPage']>(
    (containerId) => {
      if (!pageInfos) {
        return true;
      }

      return pageInfos.find((pageInfo) => pageInfo.id === containerId)?.hasNextPage ?? false;
    },
    [pageInfos],
  );

  const onSearch = useMemo<IPaginationHelperFns['onSearch']>(
    () => onSearchBillOfQuantity(client, tableType)(setLoading, setSearchState)(filteredDataTable),
    [client, filteredDataTable, tableType],
  );

  const mappedRows = useMemo((): IDataTableRow[] => {
    if (!filteredDataTable) {
      return [];
    }

    return sortContainerRowsByIndex(unmarshalDataTable(filteredDataTable));
  }, [filteredDataTable]);

  // TODO check
  const refetchItemsInContainer = useMemo<
    IPaginationHelperFns<ItemWhereInput>['refetchItemsInContainer']
  >(
    () =>
      refetchItemsInContainerBillOfQuantity(client, tableType)(searchState)(setLoading)(
        filteredDataTable,
      ),
    [client, searchState, filteredDataTable, tableType],
  );

  const updateBillOfQuantityComputedFields = useCallback(
    async (id: string) => {
      setLoading(true);
      const billOfQuantityRow = mappedRows.find((row) => row.data.id === id)!;

      const billOfQuantityRes = await client.query<
        GetBillOfQuantityComputedFields,
        GetBillOfQuantityComputedFieldsVariables
      >({
        query: GET_BILL_OF_QUANTITY_COMPUTED_FIELDS,
        variables: {
          where: { id: billOfQuantityRow.data.id },
        },
      });
      if (!billOfQuantityRes.data.billOfQuantity) {
        setLoading(false);
        return;
      }

      const {
        data: { billOfQuantity: billOfQuantityUpdateData },
      } = billOfQuantityRes;

      const updateData = pick(
        billOfQuantityUpdateData,
        'sumNet',
        'sumGross',
        'colorCodings',
        'canBeDeleted',
      );
      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          data: {
            data: JSON.stringify(updateData),
            partial: true,
          },
          where: { id: billOfQuantityRow.id, tableType },
        },
      });

      // update the cached billOfQuantity to have updated computed fields
      const boqCacheRes = client.cache.readFragment<fullBoqStructureFields>({
        fragment: FULL_BOQ_STRUCTURE_FRAGMENT,
        id: `BillOfQuantity:${id}`,
        fragmentName: 'fullBoqStructureFields',
      })!;

      Object.assign(boqCacheRes, updateData);
      client.cache.writeFragment<fullBoqStructureFields>({
        fragment: FULL_BOQ_STRUCTURE_FRAGMENT,
        id: `BillOfQuantity:${id}`,
        fragmentName: 'fullBoqStructureFields',
        data: boqCacheRes,
      });

      setLoading(false);
    },
    [client, mappedRows, tableType],
  );

  const updateItem = useCallback(
    async (data: UpdateItemData, isComputedChange: boolean) => {
      if (!data) {
        return;
      }
      setLoading(true);

      const { updateItem: updatedItem } = data;
      const tableRowId = buildBoqTableRowId(tableType)(updatedItem.id);

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          where: { id: tableRowId, tableType },
          data: {
            data: mapItemToInnerTableRow(tableType)(updatedItem).data,
            partial: true,
          },
        },
      });

      if (!isComputedChange) {
        setLoading(false);
        return;
      }

      const billOfQuantityRow = findBillOfQuantityRowFromItemRow(mappedRows)({
        id: tableRowId,
      });
      if (!billOfQuantityRow) {
        return setLoading(false);
      }

      await updateBillOfQuantityComputedFields(billOfQuantityRow.data.id);
      setLoading(false);
    },
    [client, updateBillOfQuantityComputedFields, mappedRows, tableType],
  );

  const reorderLocation = useCallback(
    async (optimisticReorder: Required<IOptimisticReorder>) => {
      const allContainerRows = flattenContainerRows(mappedRows);

      const parentLocation = allContainerRows.find((containerRow) => {
        const normalizedRowId = removeTableTypeFromId(containerRow.id);

        return (
          (isCompositeLocationId(normalizedRowId) &&
            optimisticReorder.parentLocationId === normalizedRowId) ||
          optimisticReorder.parentLocationId === normalizedRowId
        );
      });
      if (!parentLocation) {
        return;
      }

      setLoading(true);

      const containerRows = parentLocation.containerRows ?? [];
      const locationRowToReorder = containerRows.find(
        (row) => row.data.id === optimisticReorder.locationId,
      )!;

      const destinationIndex = optimisticReorder.index;
      const isNewIndexGreaterThanCurrent = destinationIndex > locationRowToReorder.data.index;
      const sourceIndex = locationRowToReorder.data.index;

      /**
       * filter out only the affected rows
       * (all locationRows which fall into range dest-source)
       */
      const locationRowsToMutate = containerRows.filter(
        (containerRow) =>
          containerRow.id !== locationRowToReorder.id &&
          inRange(
            containerRow.data.index,
            Math.min(destinationIndex, sourceIndex),
            Math.max(destinationIndex, sourceIndex) + 1,
          ),
      );

      executeForPopulatedBoqTableTypes(client, projectNumber, async (tableType) => {
        client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
          query: UPDATE_DATA_TABLE_ROWS,
          variables: {
            where: { id: tableType },
            data: [
              ...locationRowsToMutate.map((locationRow) => ({
                where: { tableType, id: swapTableType(tableType)(locationRow.id) },
                data: {
                  data: JSON.stringify({
                    ...locationRow.data,
                    /**
                     * when index greater than current we need to decrement every index till
                     * locationRow at new index
                     */
                    index: isNewIndexGreaterThanCurrent
                      ? locationRow.data.index - 1
                      : locationRow.data.index + 1,
                  }),
                },
              })),
              {
                where: { tableType, id: swapTableType(tableType)(locationRowToReorder.id) },
                data: {
                  data: JSON.stringify({ ...locationRowToReorder.data, index: destinationIndex }),
                },
              },
            ],
          },
        });
      });

      setLoading(false);
    },
    [client, mappedRows, projectNumber],
  );

  const onToggleL8 = useCallback(
    (showStatusL8) =>
      onToggleL8Helper({
        client,
        data,
        setLoading,
        showStatusL8,
        project: { projectNumber },
        entityType,
      }),
    [client, data, projectNumber, entityType],
  );

  const onToggleShowFinishedItems = useCallback(
    (itemsWhere: ItemWhereInput) => async () => {
      setLoading(true);
      const containerIds = mappedRows.flatMap((row) => [
        row.id,
        ...(row.containerRows?.flatMap((containerRow) => [
          containerRow.id,
          ...(containerRow.containerRows?.map((containerRow) => containerRow.id) ?? []),
        ]) ?? []),
      ]);

      await refetchItemsInContainer(
        containerIds.map((containerId) => ({ containerId })),
        itemsWhere,
      );
      setLoading(false);
    },
    [refetchItemsInContainer, mappedRows],
  );

  const addLocationToCache = useMemo(
    () => addLocationToCacheInBillOfQuantity(client, tableType)(data)(setLoading),
    [client, data, tableType],
  );

  const removeItemFromCache = useCallback(
    async ({ data }: { data: DeleteItem }) => {
      if (!data) {
        return;
      }

      setLoading(true);

      const deletedItem = data.deleteItem;
      const tableRowId = buildBoqTableRowId(tableType)(deletedItem.id);
      await client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
        query: REMOVE_DATA_TABLE_ROW,
        variables: {
          where: { id: tableRowId, tableType },
        },
      });

      const billOfQuantityRow = findBillOfQuantityRowFromItemRow(mappedRows)({
        id: tableRowId,
      })!;
      await updateBillOfQuantityComputedFields(billOfQuantityRow.data.id);

      setLoading(false);
    },
    [client, mappedRows, updateBillOfQuantityComputedFields, tableType],
  );

  const refetchItemsComputedFields = useCallback(
    async (idIn: string[], billOfQuantityId: string) => {
      const { data } = await client.query({
        query: REFETCH_ITEMS_COMPUTED_FIELDS_QUERY,
        variables: { where: { id_in: idIn, billOfQuantity: { id: billOfQuantityId } } },
      });

      if (!data) {
        return;
      }

      const updateInputs: UpdateDataTableRowsInput[] = data.items.map((item: any) => ({
        where: { id: item.id, tableType },
        data: { partial: true, data: JSON.stringify(omit(item, '__typename', 'id')) },
      }));

      client.query<UpdateDataTableRows, UpdateDataTableRowsVariables>({
        query: UPDATE_DATA_TABLE_ROWS,
        variables: {
          where: { id: tableType },
          data: updateInputs,
        },
      });
    },
    [client, tableType],
  );

  useEffect(() => {
    PAGINATION_HELPERS[projectNumber] = {
      ...PAGINATION_HELPERS[projectNumber],
      [tableType]: {
        onSearch,
        hasNextPage,
        fetchItemsConnection,
        refetchItemsInContainer,
        updateItem,
        removeItemFromCache,
        addLocationToCache,
        refetchItemsComputedFields,
        updateBillOfQuantityComputedFields,
      },
    };
  }, [
    projectNumber,
    tableType,
    updateItem,
    onSearch,
    hasNextPage,
    fetchItemsConnection,
    refetchItemsInContainer,
    removeItemFromCache,
    addLocationToCache,
    refetchItemsComputedFields,
    updateBillOfQuantityComputedFields,
  ]);

  return {
    fetchItemsConnection,
    hasNextPage,
    onSearch,
    mappedRows,
    refetchItemsInContainer,
    reorderLocation,
    updateBillOfQuantityComputedFields,
    onToggleL8,
    updateItem,
    onToggleShowFinishedItems,
    addLocationToCache,
    removeItemFromCache,
    loading,
    setLoading,
    hasL8BillOfQuantities,
  };
};
