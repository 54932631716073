import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { IDataTableColumn, IDataTableRow } from '../../DataTable/types';
import { formatDate } from '../../../utils/format/date';
import { translateBool } from '../../../utils/bool.translate.util';
import { DataTableBody, DataTableHotKeysWrapper } from '../../DataTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Search, useSearchState } from '../../Search/Search';
import { useQuery } from 'react-apollo';
import { MACHINE_COMPUTED_FIELDS, MACHINE_TABLE_QUERY } from './MachineTable.queries';
import { Machines, Machines_machines, MachinesVariables } from './types/Machines';
import { IconButton, Tooltip } from '@material-ui/core';
import { stopEventPropagation } from '../../../utils/stopEventPropagation';
import CreateIcon from '@material-ui/icons/Create';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AddButton from '../../DataTable/DataTableToolbar/AddButton';
import { keyBy, omit } from 'lodash';
import AppProgress from '../../Page/AppProgress';
import { machineCategoryMap, machineSkillMap } from '../MachineForm/MachineForm';
import { MachineCategory, MachineSkill, Role } from '../../../types/graphql';
import { formatVolume } from '../../../utils/format/volume';
import { IPaginationState, usePaginationState } from '../../Pagination/Pagination.utils';
import { Pagination } from '../../Pagination';
import { useCount } from '../../../hooks/useCount';
import { CountEntity } from '../../../hooks/useCount/useCount.queries';
import { DownloadCsvAction } from '../../Actions/Download/Csv';
import { createOrderByFieldName } from '../../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../../utils/order/isComputedField';
import { Subsidiary } from '../../../pages/Missions/types/Subsidiary';
import { SELECTED_SUBSIDIARY_QUERY } from '../../../pages/Missions/queries';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const TABLE_NAME = 'ALL_MACHINES';

const columns: IDataTableColumn[] = [
  {
    id: 'subsidiary.name',
    label: 'Filiale',
    width: 140,
  },
  {
    id: 'inventoryNumber',
    label: 'Inventarnummer',
    width: 130,
  },
  // {
  //   id: 'projectNumber',
  //   label: 'Projektnummer (Abacus)',
  //   width: 130,
  // },
  {
    id: 'registrationNumber',
    label: 'Kennzeichen',
    width: 130,
  },
  {
    id: 'rootNumber',
    label: 'Stammnummer',
    width: 130,
  },
  {
    id: 'chassisNumber',
    label: 'Fahrgestellnummer',
    width: 130,
  },
  {
    id: 'internalHourlyWage',
    label: 'Stundensatz intern',
    width: 100,
  },
  {
    id: 'nameOne',
    label: 'Bezeichnung 1',
  },
  {
    id: 'nameTwo',
    label: 'Bezeichnung 2',
  },
  {
    id: 'driver',
    label: 'Fahrer',
    width: 100,
  },
  {
    id: 'releaseDate',
    label: 'Jahrgang',
    width: 100,
  },
  {
    id: 'weight',
    label: 'Gewicht (kg)',
    width: 100,
  },
  {
    id: 'hoursOfOperation',
    label: 'Betriebsstunden',
    width: 110,
  },
  {
    id: 'hoursOfOperationUser',
    label: 'Betriebsstunden-Benutzer',
    hideOnDefault: true,
  },
  {
    id: 'hoursOfOperationDate',
    label: 'Betriebsstunden-Datum',
    hideOnDefault: true,
  },
  {
    id: 'emissionsTestDate',
    label: 'Abgas-\nuntersuchung',
    width: 100,
  },
  {
    id: 'trafficOfficeTestDate',
    label: 'STVA Termin',
    width: 100,
  },
  {
    id: 'lastInternalTestDate',
    label: 'Letzte Prüfung intern',
    width: 100,
  },
  {
    id: 'note',
    label: 'Bemerkung',
  },
  {
    id: 'category',
    label: 'Kategorie',
    width: 175,
  },
  {
    id: 'skills',
    label: 'Fähigkeiten',
  },
  {
    id: 'isDeactivated',
    label: 'Inaktiv',
    hideOnDefault: true,
  },
];

const columnIds = columns.map(({ id }) => id);

const machineMapper = ({
  isDeactivated,
  skills,
  releaseDate,
  lastInternalTestDate,
  trafficOfficeTestDate,
  emissionsTestDate,
  internalHourlyWage,
  subsidiary,
  weight,
  category,
  hoursOfOperation,
  hoursOfOperationHistory,
  ...machine
}: any) => ({
  id: machine.id,
  data: {
    ...machine,
    'subsidiary.name': subsidiary?.name,
    isDeactivated: translateBool(isDeactivated),
    skills:
      skills && skills.map((skill: MachineSkill) => machineSkillMap[skill] ?? skill).join(', '),
    category: category && machineCategoryMap[category as MachineCategory],
    releaseDate: releaseDate && formatDate(releaseDate),
    lastInternalTestDate: lastInternalTestDate && formatDate(lastInternalTestDate),
    trafficOfficeTestDate: trafficOfficeTestDate && formatDate(trafficOfficeTestDate),
    emissionsTestDate: emissionsTestDate && formatDate(emissionsTestDate),
    internalHourlyWage: formatCurrency(internalHourlyWage),
    weight: weight && formatVolume(weight),
    hoursOfOperation: hoursOfOperation && formatVolume(hoursOfOperation),
    hoursOfOperationUser: hoursOfOperationHistory?.[0]?.createdBy.name ?? '',
    hoursOfOperationDate: hoursOfOperationHistory?.[0]
      ? formatDate(hoursOfOperationHistory[0].createdAt)
      : '',
  },
  innerTableRows: [],
});

const mapMachines = (machines: Machines_machines[]) => {
  const machinesMap = keyBy<typeof machines[0]>(machines, 'id');
  return Object.values(machinesMap).map(machineMapper);
};

const MachineTable: React.FC = () => {
  useEffect(() => window.parent.postMessage('momoMachines', '*'), []);

  const [showDeactivatedMachines, setShowDeactivatedMachines] = useState(false);

  const user = useLoggedInUser();

  const history = useHistory();

  const searchState = useSearchState();

  const { data: subsidiaryData } = useQuery<Subsidiary>(SELECTED_SUBSIDIARY_QUERY);

  const subsidiary =
    subsidiaryData?.selectedSubsidiary === 'all'
      ? undefined
      : { id: subsidiaryData?.selectedSubsidiary };

  const { data, loading, error, refetch, variables } = useQuery<Machines, MachinesVariables>(
    MACHINE_TABLE_QUERY,
    {
      variables: {
        showDeactivated: showDeactivatedMachines,
        ...(subsidiary && { subsidiary: { id: subsidiary.id } }),
        page: {
          amountPerPage: 25,
          pageNumber: 0,
        },
      },
    },
  );

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch],
  );

  const count = useCount({
    entity: CountEntity.MACHINE,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const innerTableRows: IDataTableRow[] = mapMachines(data?.machines ?? []);

  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        droppableId="machines"
        innerTableRows={innerTableRows}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            searchState={searchState}
            columns={columnIds}
            onSubmit={(search) =>
              refetch({
                search,
              })
            }
            loading={loading}
          />
        }
        options={{
          activeRowId: '',
          filterText: searchState.searchTerm,
          tableName: TABLE_NAME,
          fixedWidthColumns: true,
          actionColumnWidth: 80,
          levels: [
            {
              columns,
              isDragAndDropEnabled: false,
              onRowClick: (row: any) =>
                history.push({
                  pathname: `/maschinen/${row.data.inventoryNumber}/editieren`,
                  state: { variables },
                }),
              rowActions: ({ row }) => (
                <>
                  <Tooltip title="Bearbeiten (Enter)">
                    <IconButton
                      aria-label="Bearbeiten"
                      onClick={(e) => {
                        stopEventPropagation(e);
                        history.push({
                          pathname: `/maschinen/${row.data.inventoryNumber}/editieren`,
                          state: { variables },
                        });
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
          ],
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, MACHINE_COMPUTED_FIELDS),
              },
            });
          },
        }}
      >
        {(context) => {
          return (
            <DataTableBody
              context={context}
              toolbarProps={{
                actions: (
                  <>
                    <Tooltip
                      title={`Inaktive Maschinen ${
                        showDeactivatedMachines ? 'ausblenden' : 'anzeigen'
                      }`}
                    >
                      <IconButton
                        onClick={() => {
                          setShowDeactivatedMachines(!showDeactivatedMachines);
                          refetch();
                        }}
                      >
                        {showDeactivatedMachines ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                    <DownloadCsvAction
                      entityType="MACHINE"
                      csvName={`Machinen_${formatDate()}`}
                      variables={{
                        where: { showDeactivated: variables.showDeactivated },
                        search: variables.search,
                      }}
                    />
                    <AddButton
                      disabled={user?.role !== Role.SUPER_ADMIN}
                      tooltip="Neu"
                      href="/maschinen/erstellen"
                      state={{ variables }}
                    />
                  </>
                ),
              }}
            />
          );
        }}
      </DataTableHotKeysWrapper>
    </>
  );
};

export default MachineTable;
