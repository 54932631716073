import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SplitScreen from '../../../components/SplitScreen';
import { BillOfQuantitySelector } from './BillOfQuantitySelector';
import { useApolloClient, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import MissionSelector from './MissionSelector';
import {
  updateMissionItem as updateMissionItemType,
  updateMissionItemVariables,
} from './types/updateMissionItem';
import { removeTableTypeFromId } from '../TabMissions/MissionSelector/paginationHelpers/mapper';
import useHandleItemPlaningSubmit from '../BillOfQuantitySelector/useHandleItemPlaningSubmit';

const UPDATE_MISSION_ITEM_MUTATION = gql`
  mutation updateMissionItem($id: ID!, $data: MissionItemUpdateInput!) {
    updateMissionItem(where: { id: $id }, data: $data) {
      id
    }
  }
`;

const TabMissionMeasure: React.FC<RouteComponentProps> = ({ match }) => {
  const client = useApolloClient();
  const { projectNumber } = match.params as any;
  const [selectedMission, setSelectedMission] = useState<any | undefined>();

  useEffect(() => window.parent.postMessage('momoProjectAusmass', '*'), []);

  const [updateMissionItem] = useMutation<updateMissionItemType, updateMissionItemVariables>(
    UPDATE_MISSION_ITEM_MUTATION,
  );

  const handleMissionChange = useCallback(
    (mission: any | undefined) => {
      setSelectedMission(mission);
    },
    [setSelectedMission],
  );

  const handleSubmit = useHandleItemPlaningSubmit(selectedMission, projectNumber, client);

  return (
    <SplitScreen
      left={
        <BillOfQuantitySelector
          projectNumber={projectNumber}
          mission={selectedMission}
          onSubmit={handleSubmit}
        />
      }
      right={
        <MissionSelector
          projectNumber={projectNumber}
          onChange={handleMissionChange}
          onInputChange={(data, id) =>
            updateMissionItem({
              variables: { id: removeTableTypeFromId(id), data },
            })
          }
        />
      }
    />
  );
};

export default TabMissionMeasure;
