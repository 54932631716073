import { Fab, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { isNil, last } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { CopyMissionAction } from '../../../../components/Actions/Copy/Mission';
import { DeleteMissionAction } from '../../../../components/Actions/Delete/Mission';
import { DeleteMissionItemAction } from '../../../../components/Actions/Delete/MissionItem';
import ColorCoder from '../../../../components/ColorCoder';
import { DataTableBody, DataTableHotKeysWrapper } from '../../../../components/DataTable';
import { IDataTableColumn, IDataTableRow } from '../../../../components/DataTable/types';
import useBlockedMissionClassName from '../../../../components/Mission/useMissionBlockedStyles';
import AppProgress from '../../../../components/Page/AppProgress';
import PdfPrint from '../../../../components/PdfPrint';
import { Search } from '../../../../components/Search';
import { useSearchState } from '../../../../components/Search/Search';
import { Color, PdfPrintType, TableType } from '../../../../types/graphql';
import { createSwissCurrencyFormatter } from '../../../../utils/createCurrencyFormatter';
import { formatDate } from '../../../../utils/format/date';
import { formatVolume } from '../../../../utils/format/volume';
import { getAllInnerTableRows } from '../../../../utils/paginationHelpers/getAllInnerTableRows';
import { setAllRowsVisible } from '../../../../utils/paginationHelpers/setAllRowsVisible';
import { useMissionItemEdit } from './missionItemEdit';
import { UPDATE_LOCATIONS_INDEX } from './MissionSelector.queries';
import { buildMissionLocationId, useMissionData } from './paginationHelpers';
import { buildTableId, removeTableTypeFromId } from './paginationHelpers/mapper';
import { missionStructureFields } from './paginationHelpers/types/missionStructureFields';
import { ProjectMissions_project_missions } from './types/ProjectMissions';
import { UpdateLocationsIndex, UpdateLocationsIndexVariables } from './types/UpdateLocationsIndex';

interface IProps {
  projectNumber: string;
  onChange: (mission: ProjectMissions_project_missions | undefined) => void;
  onClickNew: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const missionColumns: IDataTableColumn[] = [
  {
    id: 'id',
    label: 'Laufnummer',
    width: 100,
  },
  {
    id: 'name',
    label: 'Einsatzname',
    width: 120,
  },
  {
    id: 'status',
    label: 'Status',
    width: 120,
  },
  {
    id: 'netPlanSum',
    label: 'Plan Summe Netto',
    width: 110,
    render: formatCurrency,
  },
  {
    id: 'grossPlanSum',
    label: 'Plan Summe Brutto',
    render: formatCurrency,
    width: 100,
  },
  {
    id: 'netFinishedSum',
    label: 'Ausmaß Summe Netto',
    width: 140,
    render: formatCurrency,
  },
  {
    id: 'grossFinishedSum',
    label: 'Ausmaß Summe Brutto',
    render: formatCurrency,
    width: 140,
  },
  {
    id: 'colorCodings',
    label: '',
    width: 80,
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  },
];

const locationColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Örtlichkeit',
  },
];

export const MissionSelector: React.FC<IProps> = ({ projectNumber, onChange, onClickNew }) => {
  const [showFinishedMissionItems, setShowFinishedMissionItems] = useState<boolean>(true);

  const searchState = useSearchState();
  const client = useApolloClient();

  const getBlockedMissionClassName = useBlockedMissionClassName();

  const isFinished = showFinishedMissionItems ? undefined : false;

  const {
    mappedRows,
    isLevelToggleButtonVisible,
    flattenedContainerRows,
    onSearchItems,
    onToggleItems: onToggleShowFinishedItems,
    reorderLocation,
    onLoadMore,
    isOnLoadMoreDisabled,
    loading,
  } = useMissionData(projectNumber, TableType.MISSION, showFinishedMissionItems, false);

  const flatItems = useMemo(() => {
    const flatItems = mappedRows.flatMap<any & { id: string }>((mappedRow) =>
      getAllInnerTableRows(mappedRow).map((innerRow) => ({ ...innerRow.data, id: innerRow.id })),
    );

    return flatItems;
  }, [mappedRows]);

  const { renderVolumeCell, onVolumeDoubleClick } = useMissionItemEdit(flatItems);

  const [updateLocationIndex] = useMutation<UpdateLocationsIndex, UpdateLocationsIndexVariables>(
    UPDATE_LOCATIONS_INDEX,
  );

  const itemColumns = useMemo<IDataTableColumn[]>(() => {
    return [
      // add space in label to trigger word wrap
      {
        id: 'productNumber',
        label: 'Produktnummer',
        width: 130,
      },
      {
        id: 'descriptionOne',
        label: 'Beschreibung 1',
        width: 200,
      },
      {
        id: 'category',
        label: 'Kategorie',
        width: 100,
      },
      {
        id: 'markingStyle',
        label: 'Markierungsart',
        width: 110,
      },
      {
        id: 'freeText',
        label: 'Freitext',
        width: 100,
      },
      {
        id: 'unit',
        label: 'Einheit',
        width: 60,
      },
      {
        id: 'netPrice',
        label: 'Nettopreis',
        render: formatCurrency,
        width: 80,
      },
      {
        id: 'volume',
        label: 'Angebots menge',
        render: formatVolume,
        width: 75,
      },
      {
        id: 'planVolume',
        label: 'Plan menge',
        render: renderVolumeCell,
        onDoubleClick: onVolumeDoubleClick,
        width: 70,
      },
      {
        id: 'billOfQuantity',
        label: 'LV',
        hideOnDefault: true,
        width: 70,
      },
    ];
  }, [onVolumeDoubleClick, renderVolumeCell]);

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || !result.source) {
        return;
      }

      const compositeId = removeTableTypeFromId(result.draggableId);
      const [missionId, locationOneId, locationTwoId] = compositeId.split('-');
      const parsedMissionId = parseInt(missionId, 10);

      const parentRowId = buildTableId(TableType.MISSION)(
        !locationTwoId
          ? `${missionId}`
          : buildMissionLocationId(parsedMissionId, { id: locationOneId }),
      );

      await reorderLocation(result.source.index, result.destination.index, parentRowId);

      try {
        await updateLocationIndex({
          variables: {
            missionId: parsedMissionId,
            sourceIndex: result.source.index,
            destinationIndex: result.destination.index,
            ...(!isNil(locationTwoId) && {
              locationOneId,
            }),
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [updateLocationIndex, reorderLocation],
  );

  const activeRowId = mappedRows.length === 1 ? mappedRows[0].id.toString() : '';

  const fetchMoreItems = useMemo(() => onLoadMore({ isFinished }), [onLoadMore, isFinished]);
  const toggleShowFinishedItems = useCallback(() => {
    setShowFinishedMissionItems((v) => !v);
    onToggleShowFinishedItems(!showFinishedMissionItems);
  }, [onToggleShowFinishedItems, showFinishedMissionItems]);

  useMount(() => {
    try {
      setAllRowsVisible(client.cache, TableType.MISSION);
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <>
      {loading && <AppProgress />}
      <DragDropContext onDragEnd={onDragEnd}>
        <DataTableHotKeysWrapper
          containerRows={mappedRows}
          search={
            <Search
              columns={['item.productNumber', 'item.descriptionOne', 'item.unit', 'comment']}
              columnsToHighlight={['productNumber', 'descriptionOne', 'unit', 'comment']}
              searchState={searchState}
              onSubmit={(searchInput) =>
                onSearchItems(
                  searchInput,
                  { isFinished: showFinishedMissionItems ? undefined : false },
                  projectNumber,
                )
              }
              loading={loading}
            />
          }
          options={{
            tableName: 'MISSION_SELECTOR',
            fixedWidthColumns: true,
            activeRowId,
            filterText: searchState.searchTerm,
            levels: [
              {
                columns: missionColumns,
                isDefaultClosed: true,
                rowActions: ({ row }) => {
                  const missionId = removeTableTypeFromId(row.id);

                  return (
                    <>
                      <PdfPrint
                        pdfFilename={`${formatDate().replace(
                          /\//g,
                          '.',
                        )}_${missionId}_${projectNumber}.pdf`}
                        printType={PdfPrintType.MISSION}
                        data={{ id: row.data.id }}
                      />
                      <Tooltip title="Bearbeiten">
                        <Link to={`/projekte/${projectNumber}/einsaetze/${missionId}/details`}>
                          <IconButton aria-label="Bearbeiten">
                            <CreateIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <DeleteMissionAction
                        missionId={parseInt(missionId, 10)}
                        canBeDeleted={row.data.canBeDeleted}
                      />
                      <CopyMissionAction missionRowId={row.id} />
                    </>
                  );
                },
                isOnLoadMoreDisabled,
                onLoadMore: fetchMoreItems,
                isLevelToggleButtonVisible,
                isDragAndDropEnabled: !loading,
                setDroppableProps: (row) => {
                  // const compositeLocationId = removeTableTypeFromId(row.id);

                  return {
                    id: row.id,
                    type: `LOCATION_ONES_${row.id}`,
                  };
                },
                className: (row: IDataTableRow<missionStructureFields>) => {
                  return getBlockedMissionClassName(
                    row.data.isMissionBlocked,
                    row.data.manualLockingStatus,
                  );
                },
              },
              {
                columns: locationColumns,
                isDragAndDropEnabled: !loading,
                isDefaultClosed: true,
                isLevelToggleButtonVisible,
                isOnLoadMoreDisabled,
                onLoadMore: fetchMoreItems,
                setDraggableProps: (row) => {
                  // const compositeLocationId = removeTableTypeFromId(row.id);

                  const missionId = row.id.split('-')[0];
                  return {
                    id: row.id,
                    type: `LOCATION_ONES_${missionId}`,
                  };
                },
                setDroppableProps: (row) => {
                  const compositeRowId = removeTableTypeFromId(row.id);

                  const missionId = compositeRowId.split('-')[0];
                  const locationId = last(compositeRowId.split('-'));

                  return {
                    id: row.id,
                    type: `LOCATION_TWOS_${missionId}_${locationId}`,
                  };
                },
              },
              {
                columns: locationColumns,
                isDragAndDropEnabled: !loading,
                isDefaultClosed: true,
                isLevelToggleButtonVisible,
                onLoadMore: fetchMoreItems,
                isOnLoadMoreDisabled,
                setDraggableProps: (row) => {
                  const compositeRowId = removeTableTypeFromId(row.id);

                  const [missionId, locationOneId] = compositeRowId.split('-');
                  return {
                    id: row.id,
                    type: `LOCATION_TWOS_${missionId}_${locationOneId}`,
                  };
                },
              },
              {
                columns: itemColumns,
                rowActions: ({ row }) => (
                  <>
                    <DeleteMissionItemAction
                      disabled={!row.data.canBeDeleted}
                      missionItemRowId={row.id}
                      flattenedContainerRows={flattenedContainerRows}
                    />
                  </>
                ),
                actions: () => (
                  // semantically wrong place but for now just going with it!
                  <>
                    <Tooltip
                      title={`Abgeschlossene Positionen ${
                        showFinishedMissionItems ? 'ausblenden' : 'einblenden'
                      }`}
                    >
                      <IconButton onClick={toggleShowFinishedItems}>
                        {showFinishedMissionItems ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Einsatz erstellen">
                      <Fab color="primary" size="small" onClick={onClickNew}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </>
                ),
              },
            ],
            onChangeActiveRow: (rows) => {
              const activeRow = last(rows);
              onChange(activeRow && activeRow.data);
            },
          }}
        >
          {(context) => <DataTableBody context={context} />}
        </DataTableHotKeysWrapper>
      </DragDropContext>
    </>
  );
};
