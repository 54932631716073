import { Button, IconButton, Theme, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import Filter2Icon from '@material-ui/icons/Filter2';
import { makeStyles } from '@material-ui/styles';
import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { isNil, last, startCase, toLower } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { DeleteBillAction } from '../../../../components/Actions/Delete/Bill';
import { DeleteBillItemAction } from '../../../../components/Actions/Delete/BillItem';
import ScaleDiscountsTable from '../../../../components/Bill/ScaleDiscountsTable';
import { DataTableBody, DataTableHotKeysWrapper } from '../../../../components/DataTable';
import AddButton from '../../../../components/DataTable/DataTableToolbar/AddButton';
import {
  IDataTableColumn,
  IDataTableOptions,
  IDataTableRow,
  ILevelOptions,
} from '../../../../components/DataTable/types';
import AppErrorMessage from '../../../../components/Page/AppErrorMessage';
import AppProgress from '../../../../components/Page/AppProgress';
import PdfPrint from '../../../../components/PdfPrint';
import { AvailableRapportParameters } from '../../../../components/PdfPrint/PdfPrint';
import { Search } from '../../../../components/Search';
import { useSearchState } from '../../../../components/Search/Search';
import { ADD_ROW_TO_DATA_TABLE } from '../../../../services/graphql-client';
import {
  AddRowToDataTable,
  AddRowToDataTableVariables,
} from '../../../../services/types/AddRowToDataTable';
import { BillStatus, BillTitle, PdfPrintType, RowType, TableType } from '../../../../types/graphql';
import { translateBool } from '../../../../utils/bool.translate.util';
import { createSwissCurrencyFormatter } from '../../../../utils/createCurrencyFormatter';
import { formatDate } from '../../../../utils/format/date';
import { formatVolume } from '../../../../utils/format/volume';
import { setAllRowsVisible } from '../../../../utils/paginationHelpers/setAllRowsVisible';
import { billFields, billFieldsForBillView } from './bill.queries';
import { CopyBill, CopyBillVariables } from './types/CopyBill';
import { mapToContainerRow, useBillData } from './utils/pagination.helpers';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { lighten } from '@material-ui/core/styles';
import { green, pink, purple } from '@material-ui/core/colors';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { fetchMAD } from '../../../../utils/fetchMAD';
import Dinero from 'dinero.js';

const useStyles = makeStyles((theme: Theme) => ({
  customPrice: {
    color: theme.palette.primary.main,
  },
  measurementBill: {
    backgroundColor: lighten(purple[700], 0.8),
  },
  measurementBillFixed: {
    backgroundColor: lighten(purple[700], 0.6),
  },
  billFixed: {
    backgroundColor: lighten(green[700], 0.7),
  },
  billCancelled: {
    backgroundColor: lighten(pink[700], 0.7),
  },
}));

interface IBillSelectorParams {
  projectNumber: string;
  handleBillChange: (data: any) => void;
}

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const CONFIRMATION_DIALOG_TITLE = 'Rechnung kopieren';
const CONFIRMATION_DIALOG_MESSAGE = 'Bist du dir sicher, dass du die Rechnung kopieren willst?';

const COPY_BILL_MUTATION = gql`
  mutation CopyBill($billId: ID!, $projectNumber: String!) {
    copyBill(where: { id: $billId }, project: { projectNumber: $projectNumber }) {
      ...billFields
      ...billFieldsForBillView
    }
  }
  ${billFields}
  ${billFieldsForBillView}
`;

export const billColumns: IDataTableColumn[] = [
  { id: 'titleDropdown', label: 'Titel', width: 100, render: (val) => startCase(toLower(val)) },
  { id: 'number', label: 'Rechnungsnummer', width: 130 },
  { id: 'billDate', label: ' Rechnungsdatum', width: 100, render: (val) => formatDate(val) },
  { id: 'status', label: 'Status', width: 70 },
  { id: 'netBillSum', label: 'Rechnungssumme - netto', width: 160, render: formatCurrency },
  { id: 'grossBillSum', label: 'Rechnungssumme - brutto', width: 160, render: formatCurrency },
];

const locationColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Örtlichkeit',
  },
];

const BillSelector: React.FC<IBillSelectorParams> = ({ projectNumber, handleBillChange }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const searchState = useSearchState();

  const [billItemId, setBillItemId] = useState<string>('');
  const [confirmationDialogRowId, setConfirmationDialogRowId] = useState<string | undefined>(
    undefined,
  );

  const [hideCancelledBills, setHideCancelledBills] = useState<boolean>(true);

  const closeConfirmationDialog = useCallback(() => {
    setConfirmationDialogRowId(undefined);
  }, []);

  const { data, error, loading, fetchItemsConnection, hasNextPage, onSearch } =
    useBillData(projectNumber);

  const addCopiedBillToCache = useCallback<MutationUpdaterFn<CopyBill>>(
    async (cache, { data }) => {
      if (!data) {
        return;
      }

      const { copyBill: copiedBill } = data;

      const row = mapToContainerRow(copiedBill);

      await client.query<AddRowToDataTable, AddRowToDataTableVariables>({
        query: ADD_ROW_TO_DATA_TABLE,
        variables: { data: [{ row, type: RowType.CONTAINER }], where: { id: TableType.BILL } },
      });
    },
    [client],
  );

  const [copyBill] = useMutation<CopyBill, CopyBillVariables>(COPY_BILL_MUTATION, {
    update: addCopiedBillToCache,
  });

  useMount(() => {
    setAllRowsVisible(client.cache, TableType.BILL);
  });

  const billItemColumns: IDataTableColumn[] = [
    { id: 'missionItem.item.productNumber', label: 'Art.Nr.', width: 120 },
    { id: 'missionItem.mission.id', label: 'Einsatznummer', width: 100 },
    { id: 'missionItem.mission.name', label: 'Einsatzname', width: 100, hideOnDefault: true },
    {
      id: 'missionItem.mission.executionDate',
      label: 'Ausführungsdatum',
      width: 100,
      hideOnDefault: true,
      render: (v) => formatDate(v),
    },
    { id: 'missionItem.item.descriptionOne', label: 'Beschreibung 1' },
    { id: 'missionItem.item.descriptionTwo', label: 'Beschreibung 2', hideOnDefault: true },
    { id: 'missionItem.item.freeText', label: 'Freitext', width: 80 },
    { id: 'missionItem.item.unit', label: 'Einheit', width: 60 },
    {
      id: 'pricePerUnit',
      label: 'Nettopreis',
      render: (price: any, row: any) => {
        const className = row.data.isScaleDiscountOverridden ? classes.customPrice : '';

        return row.data.isPriceChangeable ? (
          <Tooltip title="Mengenrabatt ändern">
            <Button onClick={() => setBillItemId(row.id)} className={className}>
              {formatCurrency(price)}
            </Button>
          </Tooltip>
        ) : (
          formatCurrency(price)
        );
      },
      width: 100,
    },
    { id: 'missionItem.item.markingStyle', label: 'Positionsart', hideOnDefault: true },
    { id: 'amountVolume', label: 'Verrechnete Menge', render: formatVolume, width: 80 },
    { id: 'invoicedVolumeSum', label: 'Verrechnete Summe', render: formatCurrency, width: 100 },
    {
      id: 'missionItem.item.applyScaleDiscount',
      label: 'Mengenrabatt',
      hideOnDefault: true,
      render: translateBool,
    },
    { id: 'missionItem.comment', label: 'Zusatztext', hideOnDefault: true },
    { id: 'missionItem.item.comment', label: 'Positions Kommentar', hideOnDefault: true },
    { id: 'missionItem.customerComment', label: 'Bemerkungen Kunde', hideOnDefault: true },
  ];

  const filteredDataTableRows = useMemo(() => {
    return (
      data?.dataTable?.rows.filter(
        (row) => !hideCancelledBills || row.data.status !== BillStatus.STORNIERT,
      ) ?? []
    );
  }, [data?.dataTable?.rows, hideCancelledBills]);

  const [totalBillData, setTotalBillData] = useState<{
    totalBilledNetSum: number | null;
    totalBilledGrossSum: number | null;
  }>({
    totalBilledNetSum: null,
    totalBilledGrossSum: null,
  });

  useEffect(() => {
    const loadTotalBillData = async () => {
      const { totalBilledNetSum, totalBilledGrossSum } = await fetchMAD(
        `/api/projectData?projectNumber=${projectNumber}`,
      );
      setTotalBillData({
        totalBilledNetSum: totalBilledNetSum ?? null,
        totalBilledGrossSum: totalBilledGrossSum ?? null,
      });
    };
    loadTotalBillData();
  }, [data?.dataTable?.rows, projectNumber]);

  if (error) {
    console.log(error);
    return <AppErrorMessage message={error.message} />;
  }

  if (!data || !data.dataTable) {
    return null;
  }

  const activeRowId = data.dataTable.rows.length === 1 ? data.dataTable.rows[0].id : '';

  const levels: ILevelOptions[] = [
    {
      columns: billColumns,
      isDefaultClosed: true,
      isLevelToggleButtonVisible: (row) => row.data.hasDescendants,
      isOnLoadMoreDisabled: (row) => {
        return !hasNextPage(row.id);
      },
      onLoadMore: (type, containingRowId, after) => {
        fetchItemsConnection({ where: { id: containingRowId }, after });
      },
      className: (row) => {
        switch (row.data.status) {
          case BillStatus.FIXIERT:
            if (row.data.titleDropdown === BillTitle.AUSMASS) {
              return classes.measurementBillFixed;
            }
            return classes.billFixed;
          case BillStatus.STORNIERT:
            return classes.billCancelled;
          default:
            if (row.data.titleDropdown === BillTitle.AUSMASS) {
              return classes.measurementBill;
            }
            return '';
        }
      },
      rowActions: ({ row }) => (
        <>
          <Tooltip title="Bearbeiten">
            <Link to={`/projekte/${projectNumber}/rechnungen/${row.data.id}/details`}>
              <IconButton aria-label="Bearbeiten">
                <CreateIcon fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
          <PdfPrint
            printType={PdfPrintType.RAPPORT}
            data={{
              id: row.data.id,
              missions: row.data.missions?.map((mission: any) => mission.id),
              daySum: false,
              locations: true,
              summary: false,
              price: true,
              measurementAccepted: false,
              storeInProject: row.data.isFixedBill,
              sortByLocations: false,
            }}
            rapportParametersToShow={[
              AvailableRapportParameters.STORE_IN_PROJECT,
              AvailableRapportParameters.DAY_SUM,
              AvailableRapportParameters.LOCATIONS,
              AvailableRapportParameters.MEASUREMENT_ACCEPTED,
              AvailableRapportParameters.PRICE,
              AvailableRapportParameters.SUMMARY,
              AvailableRapportParameters.SORT_BY_LOCATIONS,
            ]}
            pdfFilename={(rapportParametersToShow) =>
              rapportParametersToShow.includes(AvailableRapportParameters.MEASUREMENT_ACCEPTED)
                ? `Ausmass_${projectNumber}_${row.data.number}.pdf`
                : `Leistungsrapport_${projectNumber}_${row.data.number}.pdf`
            }
            disabled={false}
          />
          <Tooltip title="Rechnung kopieren">
            <IconButton
              aria-label="Rechnung kopieren"
              onClick={() => setConfirmationDialogRowId(row.data.id)}
            >
              <Filter2Icon fontSize="small" />
            </IconButton>
          </Tooltip>
          <DeleteBillAction bill={row.data} row={row} />
        </>
      ),
    },
    {
      columns: locationColumns,
      isDragAndDropEnabled: false,
      isDefaultClosed: true,
      isLevelToggleButtonVisible: (row) => row.data.hasDescendants,
      onLoadMore: (type, containingRowId, after) => {
        fetchItemsConnection({ where: { id: containingRowId }, after });
      },
      isOnLoadMoreDisabled: (row) => {
        return !hasNextPage(row.id);
      },
    },
    {
      columns: locationColumns,
      isDragAndDropEnabled: false,
      isDefaultClosed: true,
      isLevelToggleButtonVisible: (row) => row.data.hasDescendants,
      onLoadMore: (type, containingRowId, after) => {
        fetchItemsConnection({ where: { id: containingRowId }, after });
      },
      isOnLoadMoreDisabled: (row) => {
        return !hasNextPage(row.id);
      },
    },
    {
      columns: billItemColumns,
      rowActions: (data: any) => <DeleteBillItemAction row={data.row} />,
      actions: () => (
        <>
          <Tooltip
            title={`Stornierte Rechnungen ${hideCancelledBills ? 'einblenden' : 'ausblenden'}`}
          >
            <IconButton
              onClick={() => {
                setHideCancelledBills(!hideCancelledBills);
              }}
            >
              {hideCancelledBills ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Tooltip>
          <AddButton
            tooltip="Rechnung erstellen"
            href={`/projekte/${projectNumber}/rechnungen/erstellen`}
          />
        </>
      ),
    },
  ];

  const dataTableOptions: IDataTableOptions = {
    levels,
    activeRowId,
    filterText: searchState.searchTerm,
    onChangeActiveRow: (rows: IDataTableRow[]) => {
      const activeRow = last(rows);
      handleBillChange(activeRow && activeRow.data);
    },
    fixedWidthColumns: true,
    noMinWidth: true,
    actionColumnWidth: 100,
    tableName: 'BILL_SELECTOR',
  };

  return (
    <>
      {loading && <AppProgress />}
      {billItemId && (
        <ScaleDiscountsTable billItemId={billItemId} onClose={() => setBillItemId('')} />
      )}
      <ConfirmationDialog
        open={!isNil(confirmationDialogRowId)}
        title={CONFIRMATION_DIALOG_TITLE}
        message={CONFIRMATION_DIALOG_MESSAGE}
        onClose={closeConfirmationDialog}
        onConfirm={() =>
          copyBill({ variables: { billId: confirmationDialogRowId!, projectNumber } })
        }
      />
      <DataTableHotKeysWrapper
        containerRows={filteredDataTableRows}
        options={dataTableOptions}
        search={
          <Search
            columns={['title', 'number', 'date', 'status']}
            searchState={searchState}
            onSubmit={async ({ columns, terms }) => {
              return onSearch({ columns, terms });
            }}
            loading={loading}
          />
        }
      >
        {(context) => {
          return (
            <DataTableBody context={context}>
              {!isNil(totalBillData.totalBilledGrossSum) &&
                !isNil(totalBillData.totalBilledNetSum) && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItems: 'center',
                      gap: '16px',
                      paddingRight: '4px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '0.8rem',
                        }}
                      >
                        brutto
                      </div>
                      <div>
                        {formatCurrency(Dinero({ amount: totalBillData.totalBilledGrossSum }))}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '0.8rem',
                        }}
                      >
                        netto
                      </div>
                      <div>
                        {formatCurrency(Dinero({ amount: totalBillData.totalBilledNetSum }))}
                      </div>
                    </div>
                    <div>Rechnungssumme Total:</div>
                  </div>
                )}
            </DataTableBody>
          );
        }}
      </DataTableHotKeysWrapper>
    </>
  );
};

export default BillSelector;
