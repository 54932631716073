import { IconButton, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import { isNil, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Query, useApolloClient, useMutation } from 'react-apollo';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IDataTableOptions, IDataTableRow } from '../../../components/DataTable/types';
import ItemDetails from '../../../components/Item/ItemDetails';
import MissionSelector from '../../../components/Mission/MissionSelector';
import useBlockedMissionClassName from '../../../components/Mission/useMissionBlockedStyles';
import AppErrorMessage from '../../../components/Page/AppErrorMessage';
import AppProgress from '../../../components/Page/AppProgress';
import SplitScreen from '../../../components/SplitScreen';
import {
  GET_SIDEBAR_IS_OPEN,
  REMOVE_DATA_TABLE_ROW,
  UPDATE_DATA_TABLE_ROW,
} from '../../../services/graphql-client';
import {
  RemoveDataTableRow,
  RemoveDataTableRowVariables,
} from '../../../services/types/RemoveDataTableRow';
import {
  UpdateDataTableRow,
  UpdateDataTableRowVariables,
} from '../../../services/types/UpdateDataTableRow';
import { TableType } from '../../../types/graphql';
import { deepCopy } from '../../../utils/deepCopy';
import { errorPrefixRemover } from '../../../utils/errorPrefixRemover';
import {
  IRefetchItemsInContainerInput,
  PAGINATION_HELPERS,
} from '../../../utils/paginationHelpers';
import { getAllInnerTableRows } from '../../../utils/paginationHelpers/getAllInnerTableRows';
import { useMissionData } from '../TabMissions/MissionSelector/paginationHelpers';
import { executeForMissionTableTypes } from '../TabMissions/MissionSelector/paginationHelpers/executeForMissionTypes';
import { removeTableTypeFromId } from '../TabMissions/MissionSelector/paginationHelpers/mapper';
import { missionStructureFields } from '../TabMissions/MissionSelector/paginationHelpers/types/missionStructureFields';
import BillSelector from './BillSelector';
import { billItemFields, GET_BILL } from './BillSelector/bill.queries';
import { billItemFields_missionItem_mission } from './BillSelector/types/billItemFields';
import { GetBill, GetBillVariables } from './BillSelector/types/GetBill';
import { locationColumns, missionColumns, missionItemColumns } from './row-definition';
import SelectedItemsInfo from './SelectedItemsInfo';
import {
  AddMissionItemsToBillMutation,
  AddMissionItemsToBillMutation_addMissionItemsToBill_missionItem,
  AddMissionItemsToBillMutationVariables,
} from './types/AddMissionItemsToBillMutation';
import useLocalStorage from 'use-local-storage';
import { getToken } from '../../../utils/getToken';
import { useAusmassDigitalLink } from '../../../utils/useAusmassDigitalLink';

const getInitialValues = (items: any[]): { [x: string]: boolean | number } =>
  items.reduce(
    (init, item) => ({
      ...init,
      [item.id]: {
        amountVolume: item.openInvoicedVolume,
      },
    }),
    {},
  );

export const ADD_MISSIONITEMS_TO_BILL_MUTATION = gql`
  mutation AddMissionItemsToBillMutation(
    $id: ID!
    $items: [MissionItemsToBillInput!]!
    $locationIdsToAdd: [String!]
  ) {
    addMissionItemsToBill(id: $id, items: $items, locationIdsToAdd: $locationIdsToAdd) {
      ...billItemFields
    }
  }
  ${billItemFields}
`;

interface IMatchParams {
  projectNumber: string;
}

const BillsTab: React.FC<RouteComponentProps<IMatchParams>> = ({ match }) => {
  const { projectNumber } = match.params;
  const [hideInvoicedMissionItems, setHideInvoicedMissionItems] = useState<boolean | undefined>(
    true,
  );
  const [selectedBill, setSelectedBill] = useState<any | undefined>();
  const [editMissionItemRow, setEditMissionItemRow] = useState<IDataTableRow | null>(null);

  useEffect(() => window.parent.postMessage('momoProjectBills', '*'), []);

  const getBlockedMissionClassName = useBlockedMissionClassName();

  const ausmassDigitalLink = useAusmassDigitalLink();

  const client = useApolloClient();
  const {
    mappedRows,
    loading,
    onSearchItems,
    isLevelToggleButtonVisible,
    onLoadMore,
    isOnLoadMoreDisabled,
    onToggleItems: onToggleShowFinishedItems,
  } = useMissionData(projectNumber, TableType.BILL_MISSION, true, hideInvoicedMissionItems);

  const addMissionItemsToBillUpdate = useCallback<MutationUpdaterFn<AddMissionItemsToBillMutation>>(
    async (cache, { data }) => {
      if (!data?.addMissionItemsToBill) {
        return;
      }

      const { addMissionItemsToBill: billItems } = data;

      // TODO refactor
      const uniqContainers = billItems.reduce<{
        billContainers: Map<string, IRefetchItemsInContainerInput>;
        missionContainers: Map<
          number,
          {
            mission: Pick<
              billItemFields_missionItem_mission,
              'isMissionBlocked' | 'manualLockingStatus'
            >;
            missionItems: Map<
              string,
              AddMissionItemsToBillMutation_addMissionItemsToBill_missionItem
            >;
          }
        >;
      }>(
        (acc, curr) => {
          // handle bill containers
          const location = curr.missionItem.item.location;

          if (isNil(location)) {
            return acc;
          }

          const billContainerId = `${selectedBill.id}-${location.id}`;

          if (!acc.billContainers.has(billContainerId)) {
            acc.billContainers.set(billContainerId, {
              containerId: billContainerId,
              parent: location.parentLocation
                ? {
                  id: `${selectedBill.id}-${location.parentLocation.id}`,
                  parentContainerId: location.parentLocation.parentLocation
                    ? `${selectedBill.id}-${location.parentLocation.parentLocation.id}`
                    : undefined,
                }
                : undefined,
            });
          }

          // handle capturing the updated mission containers isBlocked state
          const missionItem = curr.missionItem;
          const mission = missionItem.mission;

          if (mission && !acc.missionContainers.has(mission.id)) {
            acc.missionContainers.set(mission.id, {
              mission: {
                isMissionBlocked: mission?.isMissionBlocked ?? false,
                manualLockingStatus: mission?.manualLockingStatus ?? null,
              },
              missionItems: new Map([[missionItem.id, missionItem]]),
            });

            return acc;
          }

          // assert because mission can't be null at this point
          const missionInMap = acc.missionContainers.get(mission!.id);

          if (missionInMap && !missionInMap.missionItems.has(missionItem.id)) {
            missionInMap.missionItems.set(missionItem.id, missionItem);
          }

          return acc;
        },
        {
          billContainers: new Map(),
          missionContainers: new Map(),
        },
      );

      const {
        data: { bill },
      } = deepCopy(
        await client.query<GetBill, GetBillVariables>({
          query: GET_BILL,
          variables: {
            where: { id: selectedBill.id },
          },
        }),
      );

      // update missions in case the blocking state changes
      for (const [missionId, mission] of uniqContainers.missionContainers) {
        await executeForMissionTableTypes(async (tableType) => {
          await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
            query: UPDATE_DATA_TABLE_ROW,
            variables: {
              where: { id: `${missionId}-${tableType}`, tableType },
              data: { data: JSON.stringify(mission.mission), partial: true },
            },
          });

          // update mission items
          for (const [missionItemId] of mission.missionItems) {
            await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
              query: UPDATE_DATA_TABLE_ROW,
              variables: {
                where: { id: `${missionItemId}-${tableType}`, tableType },
                data: { data: JSON.stringify(mission), partial: true },
              },
            });
          }
        });
      }

      if (!bill) {
        return;
      }

      await client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
        query: UPDATE_DATA_TABLE_ROW,
        variables: {
          data: {
            data: JSON.stringify(bill),
          },
          where: {
            id: bill.defaultLocation.id,
            tableType: TableType.BILL,
          },
        },
      });

      await PAGINATION_HELPERS[projectNumber].BILL.refetchItemsInContainer([
        ...uniqContainers.billContainers.values(),
      ]);
    },
    [client, selectedBill, projectNumber],
  );

  const [addMissionItemsToBill, { error: mutationError }] = useMutation<
    AddMissionItemsToBillMutation,
    AddMissionItemsToBillMutationVariables
  >(ADD_MISSIONITEMS_TO_BILL_MUTATION, {
    update: addMissionItemsToBillUpdate,
  });

  const { flatItems, tableData, initialValues } = useMemo(() => {
    const flatItems = mappedRows
      .flatMap(getAllInnerTableRows)
      .map((innerRow) => ({ ...innerRow.data, id: innerRow.id }));

    return {
      flatItems,
      tableData: mappedRows,
      initialValues: getInitialValues(flatItems),
    };
  }, [mappedRows]);

  const handleBillChange = useCallback(
    (bill: any | undefined) => {
      setSelectedBill(bill);
    },
    [setSelectedBill],
  );

  const [useNewTables] = useLocalStorage<boolean>('useNewTables', false);

  const handleMissionItemToBillSubmit = useCallback(
    async (missionItems: any[], checkedRowIds: any[], locationIdsToAdd: string[]) => {
      const formValues = missionItems.reduce((acc, missionItem) => {
        acc[missionItem.id] = pick(missionItem, 'amountVolume');

        return acc;
      }, {});

      const missionItemsToSubmit = checkedRowIds.reduce((acc, id) => {
        // take items with amount > 0
        if (formValues[id].amountVolume > 0) {
          acc.push({ id: removeTableTypeFromId(id), ...formValues[id] });
        }

        return acc;
      }, []);

      if (locationIdsToAdd.length > 0) {
        const unselectedItems = flatItems.filter((item: any) => {
          return !checkedRowIds.includes(item.id);
        });

        missionItemsToSubmit.push(
          ...unselectedItems.map(({ id }: any) => {
            return {
              id: removeTableTypeFromId(id),
              amountVolume: 0,
            };
          }),
        );
      }

      if ((missionItemsToSubmit.length < 1 && locationIdsToAdd.length < 1) || !selectedBill) {
        return;
      }

      const result = await addMissionItemsToBill({
        variables: {
          id: selectedBill.id,
          items: missionItemsToSubmit,
          locationIdsToAdd,
        },
      });

      const { fullyInvoiced, partiallyInvoiced } = checkedRowIds.reduce<
        Record<'fullyInvoiced' | 'partiallyInvoiced', string[]>
      >(
        (acc, rowId) => {
          const missionItem = missionItems.find((missionItem) => missionItem.id === rowId);

          const isFullyInvoiced = missionItem.amountVolume >= missionItem.openInvoicedVolume;

          if (isFullyInvoiced) {
            acc.fullyInvoiced.push(rowId);
          } else {
            acc.partiallyInvoiced.push(rowId);
          }

          return acc;
        },
        { fullyInvoiced: [], partiallyInvoiced: [] },
      );

      // only delete item if user fully invoices it
      fullyInvoiced.forEach((rowId) => {
        client.query<RemoveDataTableRow, RemoveDataTableRowVariables>({
          query: REMOVE_DATA_TABLE_ROW,
          variables: {
            where: { id: rowId, tableType: TableType.BILL_MISSION },
          },
        });
      });

      partiallyInvoiced.forEach((rowId) => {
        const missionItem = missionItems.find((missionItem) => missionItem.id === rowId);
        const amountVolume = formValues[rowId].amountVolume;

        const rowUpdateData = {
          openInvoicedVolume: missionItem.openInvoicedVolume - amountVolume,
          invoicedVolume: missionItem.invoicedVolume + amountVolume,
          invoicedSum: missionItem.invoicedSum + amountVolume * missionItem.netPrice,
          openInvoicedSum: (missionItem.openInvoicedVolume - amountVolume) * missionItem.netPrice,
        };

        client.query<UpdateDataTableRow, UpdateDataTableRowVariables>({
          query: UPDATE_DATA_TABLE_ROW,
          variables: {
            where: { id: rowId, tableType: TableType.BILL_MISSION },
            data: {
              partial: true,
              data: JSON.stringify(rowUpdateData),
            },
          },
        });
      });

      // Refresh finished items, Otherwise mission items added to an AUSMANSS would dissapear until the next page reload.
      onToggleShowFinishedItems(null, hideInvoicedMissionItems);

      return result;
    },
    [
      selectedBill,
      addMissionItemsToBill,
      onToggleShowFinishedItems,
      hideInvoicedMissionItems,
      flatItems,
      client,
    ],
  );
  const fetchMoreItems = useMemo(
    () =>
      onLoadMore({
        hideFullyInvoiced: hideInvoicedMissionItems,
        isFinished: true,
        isMeasured: true,
      }),
    [onLoadMore, hideInvoicedMissionItems],
  );

  const dataTableOptions: IDataTableOptions = {
    tableName: 'BILLS_MISSION_SELECTOR',
    levels: [
      {
        columns: missionColumns,
        isDefaultClosed: true,
        onLoadMore: fetchMoreItems,
        isLevelToggleButtonVisible,
        isOnLoadMoreDisabled,
        className: (row: IDataTableRow<missionStructureFields>) =>
          getBlockedMissionClassName(row.data.isMissionBlocked, row.data.manualLockingStatus),
        rowActions: ({ row }: any) => (
          <>
            <Tooltip title="Bearbeiten">
              <Link to={`/projekte/${projectNumber}/einsaetze/${row.data.id}/details`}>
                <IconButton aria-label="Bearbeiten">
                  <CreateIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>
          </>
        ),
      },
      {
        columns: locationColumns,
        isDefaultClosed: true,
        onLoadMore: fetchMoreItems,
        isLevelToggleButtonVisible,
        isOnLoadMoreDisabled,
      },
      {
        columns: locationColumns,
        isDefaultClosed: true,
        onLoadMore: fetchMoreItems,
        isLevelToggleButtonVisible,
        isOnLoadMoreDisabled,
      },
      {
        columns: missionItemColumns,
        rowActions: ({ row }: any) => (
          <Tooltip title="Editieren">
            <IconButton onClick={() => setEditMissionItemRow(row)} aria-label="Editieren">
              <CreateIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
        actions: () => (
          // semantically wrong place but for now just going with it!
          <>
            <Tooltip
              title={`Fakturierete Positionen ${hideInvoicedMissionItems ? 'einblenden' : 'ausblenden'
                }`}
            >
              <IconButton
                onClick={() => {
                  setHideInvoicedMissionItems(!hideInvoicedMissionItems);
                  onToggleShowFinishedItems(null, !hideInvoicedMissionItems);
                }}
              >
                {hideInvoicedMissionItems ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          </>
        ),
        hasCheckboxes: true,
      },
    ],
    actionColumnWidth: 100,
  };

  return (
    <>
      {loading && <AppProgress />}
      {mutationError && (
        <AppErrorMessage
          message={errorPrefixRemover(mutationError.message)}
          noAutoHide
          noHideOnClickAway
        />
      )}
      {useNewTables && ausmassDigitalLink && (
        <Query<any, any> query={GET_SIDEBAR_IS_OPEN}>
          {({
            data: {
              navigation: { sidebarIsOpen: isSidebarOpen },
            },
          }) => (
            <iframe
              title="Neue Rechnungstabellen"
              src={`${ausmassDigitalLink}/projekte/${projectNumber}/rechnungen?layout=no-layout&momoAuth=${getToken()}`}
              style={{
                width: isSidebarOpen ? 'calc(100vw - 270px)' : 'calc(100vw - 56px)',
                height: 'calc(100vh - 70px)',
                border: 'none',
                background: '#fafafa',
                overflowX: 'visible',
                overflowY: 'visible',
                margin: '-16px',
              }}
            />
          )}
        </Query>
      )}
      {!useNewTables && (
        <SplitScreen
          left={
            <MissionSelector
              data={{ flatItems, tableData, initialValues }}
              loading={loading}
              tableOptions={dataTableOptions}
              onSubmit={handleMissionItemToBillSubmit}
              onSearchSubmit={(search) =>
                onSearchItems(
                  search,
                  {
                    hideFullyInvoiced: hideInvoicedMissionItems,
                    isFinished: true,
                    isMeasured: true,
                  },
                  projectNumber,
                )
              }
            >
              {({ context, values }) => {
                const selectedItems =
                  flatItems && context
                    ? flatItems
                      .filter((item) => context.checkedRowIds.includes(item.id))
                      .map((item) => ({
                        ...item,
                        amountVolume: values[item.id].amountVolume,
                      }))
                    : [];
                return (
                  <>
                    <SelectedItemsInfo
                      selectedContainers={context.state.selectedContainerIds}
                      bill={selectedBill}
                      missionItems={selectedItems}
                    />

                    {editMissionItemRow && (
                      <>
                        <ItemDetails
                          open={!!editMissionItemRow}
                          itemId={editMissionItemRow.data.item.id}
                          onClose={() => setEditMissionItemRow(null)}
                        />
                      </>
                    )}
                  </>
                );
              }}
            </MissionSelector>
          }
          right={<BillSelector projectNumber={projectNumber} handleBillChange={handleBillChange} />}
        />
      )}
    </>
  );
};

export default BillsTab;
