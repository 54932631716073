import ApolloClient from 'apollo-client';
import { isEmpty, pick } from 'lodash';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { IMissionLocationIdInput } from '../TabMissions/MissionSelector/paginationHelpers';
import { ADD_ITEMS_MUTATION, IItem, IItemWithAmount } from '../TabMissions/TabMissions';
import {
  AddItemsToMissionMutation,
  AddItemsToMissionMutationVariables,
} from '../TabMissions/types/AddItemsToMissionMutation';
import { ProjectMissions_project_missions } from '../TabMissions/types/ProjectMissions';
import { handleMissionLikeTabSubmitInCache } from '../TabMissions/utils';
import { IItemsToMissionInput } from './types';

const mapToMissionInput = (item: IItemWithAmount): IItemsToMissionInput =>
  pick(item, ['id', 'amount']);

export function useHandleItemPlaningSubmit(
  selectedMission: ProjectMissions_project_missions | undefined,
  projectNumber: string,
  client: ApolloClient<any>,
) {
  const [addItemsToMission] = useMutation<
    AddItemsToMissionMutation,
    AddItemsToMissionMutationVariables
  >(ADD_ITEMS_MUTATION);

  const handleSubmit = useCallback(
    async (
      selectedItems: IItemWithAmount[],
      allItems: IItem[],
      locationIdsToAdd?: string[],
      containersToRefetch?: IMissionLocationIdInput[],
    ) => {
      if ((isEmpty(selectedItems) && isEmpty(locationIdsToAdd)) || !selectedMission) {
        return;
      }

      if (containersToRefetch && containersToRefetch.length > 0) {
        const unselectedItems = allItems.filter(
          (item) => !selectedItems.some((selectedItem) => item.id === selectedItem.id),
        );

        selectedItems.push(
          ...unselectedItems.map((item) => {
            return {
              ...item,
              // send undefined to mark the item as checked out
              // items with amount will also be added!!
              amount: undefined as any,
            };
          }),
        );
      }

      await addItemsToMission({
        variables: {
          id: selectedMission.id,
          items: selectedItems.map(mapToMissionInput),
          locationIdsToAdd,
        },
      });

      handleMissionLikeTabSubmitInCache(
        selectedItems,
        selectedMission.id,
        projectNumber,
        client,
        containersToRefetch,
      );
    },

    [selectedMission, addItemsToMission, projectNumber, client],
  );
  return handleSubmit;
}

export default useHandleItemPlaningSubmit;
