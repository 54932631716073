import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { CreateUserDialog } from './CreateUserDialog';
import DataTable from '../../components/DataTable';
import { IDataTableColumn } from '../../components/DataTable/types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import UserDetails from './UserDetails';
import Edit from '@material-ui/icons/Edit';
import { getUsers, getUsersVariables } from './types/getUsers';
import AppProgress from '../../components/Page/AppProgress';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { translateBool } from '../../utils/bool.translate.util';
import { GET_SUBSIDIARIES, mapIdsToSubsidiaryNames } from '../../components/User/UserForm';
import { getSubsidiaries } from '../Subsidiaries/types/getSubsidiaries';
import { Search, useSearchState } from '../../components/Search/Search';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { omit } from 'lodash';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';

const columns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'role',
    label: 'Rechte',
  },
  {
    id: 'subsidiaries',
    label: 'Filialen',
    render: (subsidiaries: string[]) => subsidiaries.join(', '),
  },
  {
    id: 'archived',
    label: 'Archiviert',
    render: translateBool,
  },
];

export const GET_USERS = gql`
  query getUsers(
    $archived: Boolean
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    users(where: { archived: $archived }, search: $search, page: $page, orderBy: $orderBy) {
      id
      name
      email
      role
      archived
      subsidiaries
    }
  }
`;

const Users = () => {
  useEffect(() => window.parent.postMessage('momoUsers', '*'), []);

  const [showArchivedUsers, setShowArchivedUsers] = useState<boolean>(false);

  const searchState = useSearchState();

  const { data, error, loading, refetch, variables } = useQuery<getUsers, getUsersVariables>(
    GET_USERS,
    {
      variables: {
        ...(!showArchivedUsers && { archived: false }),
        page: {
          pageNumber: 0,
          amountPerPage: 25,
        },
      },
    },
  );

  const { data: subsidiariesData } = useQuery<getSubsidiaries>(GET_SUBSIDIARIES);

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          pageNumber: state.page,
          amountPerPage: state.rowsPerPage,
        },
      });
    },
    [refetch],
  );

  const count = useCount({ entity: CountEntity.USER, variables: omit(variables, 'page') });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  if (!subsidiariesData) {
    return null;
  }

  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  const toTableElement = (user: any) => ({
    id: user.id,
    data: {
      ...user,
      ...(user.subsidiaries &&
        user.subsidiaries.length > 0 && {
          subsidiaries: mapIdsToSubsidiaryNames(user.subsidiaries, subsidiariesData.subsidiaries),
        }),
    },
  });

  return (
    <>
      {loading && <AppProgress />}
      <DataTable
        innerTableRows={data.users?.map(toTableElement) ?? []}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            columns={['name', 'email', 'role']}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: 'USERS',
          levels: [
            {
              columns,
              actions: () => (
                <>
                  <Tooltip
                    title={`Archivierte Users ${showArchivedUsers ? 'ausblenden' : 'einblenden'}`}
                  >
                    <IconButton
                      onClick={() => {
                        setShowArchivedUsers(!showArchivedUsers);
                      }}
                    >
                      {showArchivedUsers ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>
                  <AddButton tooltip="Neu" href="/benutzer/erstellen" state={{ variables }} />
                </>
              ),
              rowActions: ({ row }) => (
                <Tooltip title="Editieren">
                  <Link to={`/benutzer/${row.id}/editieren`}>
                    <IconButton aria-label="Editieren">
                      <Edit fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              ),
            },
          ],
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, ['subsidiaries']),
              },
            });
          },
        }}
      />

      <Route path="/benutzer/erstellen">
        <CreateUserDialog />
      </Route>
      <Route path="/benutzer/:id/editieren">
        <UserDetails />
      </Route>
    </>
  );
};

export default Users;
