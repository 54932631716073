import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import FormikTextField, { IFormikTextFieldProps } from '../Form/FormikTextField';
import {
  Button,
  ClickAwayListener,
  List,
  ListItem,
  Paper,
  RootRef,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type IProps = IFormikTextFieldProps & {
  suggestions: readonly string[];
  maxSuggestions?: number;
  minLengthToTrigger: number;
  matcher?: (value: string, suggestion: string) => boolean;
  onSetValue?: (value: string) => void;
  selectOnFocus?: boolean;
  fillOnEnter?: boolean;
  fillOnBlur?: boolean;
  next?: HTMLElement | string | ((value: string) => string);
  useNext?: 'focus' | 'click';
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: theme.spacing(0),
  },
  buttonLabel: {
    justifyContent: 'start',
    textTransform: 'initial',
    textAlign: 'left',
  },
}));

const defaultMatcher = (value: string, suggestion: string): boolean => suggestion.startsWith(value);

export const Autocomplete: React.FC<IProps> = ({
  fillOnEnter,
  fillOnBlur,
  next,
  useNext,
  matcher = defaultMatcher,
  suggestions,
  maxSuggestions = -1,
  minLengthToTrigger,
  children,
  selectOnFocus,
  onSetValue,
  ...props
}) => {
  const paperRef = useRef<HTMLElement>();

  // no object identity on setValue of useField!
  const { setFieldValue, setFieldTouched, validateField, values } = useFormikContext();

  // reducer is needed for fields with names like 'projectResponsible.name'
  const value = props.field.name
    .split(/[.[\]]/)
    .reduce((acc: any, cur) => (isEmpty(cur) ? acc : acc[cur]), values);

  const setValue = useCallback(
    (value) => {
      setFieldTouched(props.field.name, true);
      setFieldValue(props.field.name, value, true);
      onSetValue?.(value);
    },
    [props.field.name, setFieldValue, onSetValue, setFieldTouched],
  );

  const classes = useStyles();

  const inputRef = useRef<HTMLElement>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const clearAnchor = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const [suggestionIdx, setSuggestionIdx] = useState<number>(0);
  const clearSuggestionIdx = useCallback(() => setSuggestionIdx(0), [setSuggestionIdx]);

  const clear = useCallback(() => {
    // prevent closing popup when user selects text in textbox and let's go of mouse key outside of textbox
    if (window.getSelection()?.anchorNode?.contains(inputRef?.current ?? null)) {
      return;
    }
    clearSuggestionIdx();
    clearAnchor();
  }, [clearSuggestionIdx, clearAnchor]);

  const [width, setWidth] = useState<number | null>(null);

  const isTriggered = value.length >= minLengthToTrigger;

  const matchingSuggestions = useMemo(() => {
    const matches = suggestions.filter((suggestion: string) =>
      matcher(value.toLowerCase(), suggestion.toLowerCase()),
    );
    if (maxSuggestions >= 0) {
      return matches.slice(0, maxSuggestions);
    }
    return matches;
  }, [suggestions, maxSuggestions, value, matcher]);

  const propsOnFocus = props.onFocus;
  const propsOnClick = props.onClick;
  const setVariablesForPopover = useCallback(
    (event: any) => {
      if (!anchorEl) {
        setAnchorEl(event.currentTarget);
        setWidth(event.currentTarget.clientWidth);
      }

      if (selectOnFocus) {
        event.currentTarget.querySelector('input')?.select();
      }

      switch (event.type) {
        case 'focus':
          propsOnFocus?.(event);
          break;
        case 'click':
          propsOnClick?.(event);
          break;
      }
    },
    [anchorEl, selectOnFocus, propsOnFocus, propsOnClick],
  );

  const onNext = useCallback(
    (value: string) => {
      if (!next) {
        return;
      }

      // yield to allow validation to kick in
      window.requestAnimationFrame(() => {
        const node =
          typeof next === 'object'
            ? next
            : document.querySelector<HTMLElement>(typeof next === 'function' ? next(value) : next);
        node?.[useNext ?? 'focus']();
      });
    },
    [next, useNext],
  );

  const handleListItemClick = useCallback(
    (suggestion: string) => () => {
      setValue(suggestion);
      clear();
      onNext(suggestion);
    },
    [clear, onNext, setValue],
  );

  const isOpen = useMemo(
    () =>
      (minLengthToTrigger === 0 || !!value) &&
      !props.disabled &&
      !!anchorEl &&
      isTriggered &&
      !isEmpty(matchingSuggestions),
    [value, anchorEl, isTriggered, minLengthToTrigger, matchingSuggestions, props.disabled],
  );

  const buttonClasses = useMemo(() => ({ label: classes.buttonLabel }), [classes.buttonLabel]);
  const listItemClasses = useMemo(() => ({ root: classes.listItem }), [classes.listItem]);
  const listStyle = useMemo<CSSProperties>(
    () => ({
      minWidth: `${width}px`,
      maxWidth: `${(width ?? 1) * 2}px`,
      maxHeight: `450px`,
      overflowY: 'auto',
    }),
    [width],
  );

  const onArrows = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (!(isOpen && fillOnEnter)) {
        return;
      }

      let propagate = true;
      switch (evt.key) {
        case 'ArrowUp':
          setSuggestionIdx(Math.max(0, suggestionIdx - 1));
          propagate = false;
          break;
        case 'ArrowDown':
          setSuggestionIdx(Math.min(matchingSuggestions.length, suggestionIdx + 1));
          propagate = false;
          break;
      }
      if (!propagate) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    },
    [fillOnEnter, isOpen, matchingSuggestions.length, suggestionIdx],
  );

  const propsOnKeyDown = props.onKeyDown;
  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      onArrows(evt);
      propsOnKeyDown?.(evt);

      if (evt.key === 'Tab') {
        clear();

        return;
      }

      const executeSetValue = fillOnEnter && isOpen && evt.key === 'Enter';
      if (!executeSetValue) {
        return;
      }

      const value = matchingSuggestions[suggestionIdx] ?? '';

      setValue(value);
      clear();
      onNext(value);

      evt.stopPropagation();
      evt.preventDefault();
    },
    [
      fillOnEnter,
      isOpen,
      onArrows,
      propsOnKeyDown,
      setValue,
      matchingSuggestions,
      clear,
      onNext,
      suggestionIdx,
    ],
  );

  const atLeastOneCharacter = value.length > 0;

  const propsOnBlur = props.onBlur;
  const propsFieldName = props.field.name;
  const onBlur = useCallback(
    (evt: any) => {
      // if an item in the dropdown is clicked, onBlur runs before the value is actually set.
      // We don't want to validate the old value (or set a value that will just be potentially overwritten
      // once the clickHandler runs so exit early if the click that triggered onBlur was inside the dropdown
      if (paperRef.current && paperRef.current.contains(evt.relatedTarget)) {
        return;
      }

      const executeSetValue = atLeastOneCharacter && isOpen && fillOnBlur;
      if (executeSetValue) {
        const value = matchingSuggestions[suggestionIdx] ?? '';
        setValue(value);
      } else {
        // if the user doesn't actively select from the drop down we need to trigger validation since he might have
        // entered an incorrect value
        setFieldTouched(propsFieldName, true);
        validateField(propsFieldName);
      }

      propsOnBlur?.(evt);
    },
    [
      fillOnBlur,
      propsOnBlur,
      propsFieldName,
      isOpen,
      atLeastOneCharacter,
      matchingSuggestions,
      suggestionIdx,
      setValue,
      setFieldTouched,
      validateField,
    ],
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    paperRef?.current?.scrollIntoView(false);
  }, [isOpen, matchingSuggestions]);

  useEffect(() => {
    clearSuggestionIdx();
  }, [clearSuggestionIdx, matchingSuggestions]);

  // tabIndex={-1} to remove elements from tab order
  return (
    <>
      <ClickAwayListener onClickAway={clear} mouseEvent="onMouseUp">
        <span>
          <FormikTextField
            {...props}
            onBlur={onBlur}
            onClick={setVariablesForPopover}
            onFocus={setVariablesForPopover}
            autoComplete="off"
            onKeyDown={onKeyDown}
            tabIndex={-1}
            inputRef={inputRef}
          />
          {isOpen && (
            <Paper tabIndex={-1} style={{ position: 'absolute', zIndex: 10_000 }}>
              <RootRef rootRef={paperRef}>
                <List tabIndex={-1} style={listStyle} onMouseLeave={clearSuggestionIdx}>
                  {matchingSuggestions.map((suggestion: string, idx: number) => (
                    <ListItem
                      key={suggestion}
                      classes={listItemClasses}
                      selected={
                        fillOnEnter || (fillOnBlur && atLeastOneCharacter)
                          ? idx === suggestionIdx
                          : undefined
                      }
                      tabIndex={-1}
                      onClick={handleListItemClick(suggestion)}
                      onMouseEnter={() => setSuggestionIdx(idx)}
                    >
                      <Button variant="text" fullWidth classes={buttonClasses} tabIndex={-1}>
                        {suggestion}
                      </Button>
                    </ListItem>
                  ))}
                  {maxSuggestions >= 0 &&
                    suggestions.length > maxSuggestions &&
                    matchingSuggestions.length >= maxSuggestions && (
                      <ListItem key="end" classes={listItemClasses} tabIndex={-1}>
                        <Button variant="text" fullWidth classes={buttonClasses} tabIndex={-1}>
                          Bitte Auswahl einschränken!
                        </Button>
                      </ListItem>
                    )}
                </List>
              </RootRef>
            </Paper>
          )}
        </span>
      </ClickAwayListener>
    </>
  );
};
