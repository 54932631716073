import Dinero from 'dinero.js';
import { defaults } from 'lodash';

interface IFormatOptions {
  currency?: string;
  format?: string;
}

const createCurrencyFormatter = (options?: IFormatOptions) => (amount: number) => {
  options = defaults(options || {}, {
    currency: 'EUR',
  });

  return Dinero({
    currency: options.currency,
    amount,
  }).toFormat(options.format);
};
export default createCurrencyFormatter;

interface ISwissFormatOptions {
  withCurrency: boolean;
}

export const createSwissCurrencyFormatter = (opts?: ISwissFormatOptions) => {
  const { withCurrency } = opts || { withCurrency: false };

  const format = withCurrency ? '$0,0.00' : '0,0.00';
  const formatter = createCurrencyFormatter({ format });

  return (amount: number | Dinero.Dinero) => {
    if (typeof amount !== 'number') {
      amount = amount.getAmount();
    }
    const formatted = formatter(amount)
      .replace(/\./g, 'comma')
      .replace(/,/g, '.')
      .replace(/comma/g, ',');

    if (!withCurrency && amount >= 0) {
      return formatted;
    }

    return formatted.replace('-EUR ', 'EUR -');
  };
};

export const roundToFiveRappen = (price: Dinero.Dinero): Dinero.Dinero => {
  const roundedAmount = Math.round(price.toUnit() * 20) / 20;

  return Dinero({ amount: centConverter(roundedAmount) });
};

export const centConverter = (moneyAmount: number) => {
  if (!Number.isInteger(moneyAmount) && moneyAmount.toString().replace(/.*\./, '').length > 2) {
    throw new Error(`${moneyAmount} ist kein richtiger Geldbetrag`);
  }

  return Math.round(100 * moneyAmount);
};
