import Attachment from '@material-ui/icons/Attachment';
import gql from 'graphql-tag';
import { isEmpty, omit } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import ColorCoder, { Color } from '../../components/ColorCoder';
import { DataTableBody, DataTableHotKeysWrapper } from '../../components/DataTable';
import { IDataTableColumn } from '../../components/DataTable/types';
import AppProgress from '../../components/Page/AppProgress';
import { Pagination } from '../../components/Pagination';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Search } from '../../components/Search';
import { useSearchState } from '../../components/Search/Search';
import { useL8Filter } from '../../hooks/BillOfQuantity/useL8Filter';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { BillOfQuantityEntityType, BillOfQuantityWhereInput, BOQStatus } from '../../types/graphql';
import { translateBool } from '../../utils/bool.translate.util';
import { capitalize } from '../../utils/capitalize';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import { formatDate } from '../../utils/format/date';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { SELECTED_SUBSIDIARY_QUERY } from '../Missions/queries';
import { BOQ_COMPUTED_FIELDS } from './boq.constants';
import {
  AllBillOfQuantities,
  AllBillOfQuantities_billOfQuantities,
  AllBillOfQuantitiesVariables,
} from './types/AllBillOfQuantities';

interface IProps {
  entityType: BillOfQuantityEntityType;
}

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

export const BILL_OF_QUANTITIES_QUERY = gql`
  query AllBillOfQuantities(
    $boqWhere: BillOfQuantityWhereInput
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
  ) {
    billOfQuantities(where: $boqWhere, search: $search, page: $page, orderBy: $orderBy) {
      name
      id
      skontoDeadline
      paymentDeadline
      status {
        id
        status
        createdAt
      }
      taskTypes
      type
      createdAt
      hasScaleDiscount
      hasScaleDiscountAccumulate
      colorCodings
      sumNet
      sumGross
      project {
        id
        hasFiles
        status
        subsidiary {
          id
          name
        }
        projectNumber
        projectName
        createdAt
        personResponsible {
          id
          name
        }
      }
    }
  }
`;

const columns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Bezeichnung-LV',
  },
  {
    id: 'status.status',
    label: 'Status-LV',
  },
  {
    id: 'type',
    label: 'Typ-LV',
  },
  {
    id: 'taskTypes',
    label: 'Arbeitsgattung-LV',
    render: (data) => data && data.map(capitalize).join(','),
  },
  {
    id: 'createdAt',
    label: 'Erstellungsdatum-LV',
    render: (data) => formatDate(data),
  },
  {
    id: 'sumNet',
    label: 'Summe-LV',
    render: formatCurrency,
  },
  {
    id: 'skontoDeadline',
    label: 'Skontofrist',
    hideOnDefault: true,
  },
  {
    id: 'paymentDeadline',
    label: 'Nettofrist',
    hideOnDefault: true,
  },
  {
    id: 'hasScaleDiscount',
    label: 'Mengenrabatt ja/nein LV',
    render: translateBool,
  },
  {
    id: 'project.subsidiary.name',
    label: 'Filiale',
  },
  {
    id: 'project.projectNumber',
    label: 'Projektnummer',
  },
  {
    id: 'project.projectName',
    label: 'Projektbezeichnung',
  },
  {
    id: 'project.status',
    label: 'Status',
  },
  {
    id: 'colorCodings',
    label: 'Farbcodierung',
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
    hideOnDefault: true,
  },
  {
    id: 'project.personResponsible.name',
    label: 'Verantwortlicher',
  },
  {
    id: 'project.createdAt',
    label: 'Eröffnet Datum',
    hideOnDefault: true,
    render: (val) => formatDate(val),
  },
  {
    id: 'hasFiles',
    label: 'Daten',
    render: (hasFiles: boolean) =>
      hasFiles ? <Attachment color="action" /> : translateBool(hasFiles),
  },
];

const mapTableData = (billOfQuantities: AllBillOfQuantities_billOfQuantities[]) => {
  return billOfQuantities.map((billOfQuantity) => ({
    id: billOfQuantity.id,
    data: {
      ...billOfQuantity.project,
      ...billOfQuantity,
      'status.status': (billOfQuantity as any).status.status,
      sumNet: billOfQuantity.sumNet,
      sumGross: billOfQuantity.sumGross,
      colorCodings: billOfQuantity.colorCodings,
      taskTypes: billOfQuantity.taskTypes,
      createdAt: billOfQuantity.createdAt,
      scaleDiscount: billOfQuantity.hasScaleDiscount,
      'project.subsidiary.name': billOfQuantity.project.subsidiary.name,
      'project.projectAddress.city': '',
      'project.projectNumber': billOfQuantity.project.projectNumber,
      'project.projectName': billOfQuantity.project.projectName,
      'project.status': billOfQuantity.project.status,
      'project.personResponsible.name': billOfQuantity.project.personResponsible?.name,
      hasFiles: billOfQuantity.project.hasFiles,
      'project.createdAt': billOfQuantity.project.createdAt,
    },
  }));
};

const BillOfQuantities: React.FunctionComponent<IProps> = ({ entityType }) => {
  useEffect(
    () => window.parent.postMessage(entityType === 'ORDER' ? 'momoOrders' : 'momoOffers', '*'),
    [entityType],
  );

  const { showStatusL8, L8Button } = useL8Filter();

  const { data: subsidiaryData } = useQuery(SELECTED_SUBSIDIARY_QUERY);

  const boqWhere = useMemo<BillOfQuantityWhereInput>(
    () => ({
      entityType,
      ...(!showStatusL8 && {
        status: { status_not_in: [BOQStatus.L8] },
        ...(subsidiaryData?.selectedSubsidiary !== 'all' && {
          project: { subsidiary: { id: subsidiaryData?.selectedSubsidiary } },
        }),
      }),
    }),
    [showStatusL8, subsidiaryData, entityType],
  );

  const queryVariables = useMemo(
    (): AllBillOfQuantitiesVariables => ({
      ...(!isEmpty(boqWhere) && { boqWhere }),
    }),
    [boqWhere],
  );

  const { data, error, refetch, loading, variables } = useQuery<
    AllBillOfQuantities,
    AllBillOfQuantitiesVariables
  >(BILL_OF_QUANTITIES_QUERY, {
    variables: {
      ...queryVariables,
      page: {
        pageNumber: 0,
        amountPerPage: 25,
      },
    },
  });

  const history = useHistory();

  const searchState = useSearchState();

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        ...queryVariables,
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch, queryVariables],
  );

  const count = useCount({
    entity: CountEntity.BILL_OF_QUANTITY,
    variables: { ...omit(variables, 'page', 'boqWhere'), where: variables.boqWhere },
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const csvName = `${
    entityType === BillOfQuantityEntityType.OFFER ? 'Angebote' : 'Aufträge'
  }_${formatDate()}`;

  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }
  return (
    <>
      {loading && <AppProgress />}
      <DataTableHotKeysWrapper
        innerTableRows={mapTableData(data?.billOfQuantities ?? [])}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            searchState={searchState}
            columns={[
              'name',
              'type',
              'status',
              'taskTypes',
              'createdAt',
              'subsidiary.name',
              'project.projectNumber',
              'project.projectName',
              'project.status',
              'project.personResponsible.name',
            ]}
            onSubmit={({ columns, terms }) =>
              refetch({
                ...queryVariables,
                search: { columns, terms },
              })
            }
            loading={loading}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, BOQ_COMPUTED_FIELDS),
              },
            });
          },
          tableName: 'ALL_BOQS',
          levels: [
            {
              columns,
              onRowClick: (row) => history.push(`/projekte/${row.data.projectNumber}/auftraege`),
            },
          ],
        }}
      >
        {(context) => (
          <DataTableBody
            context={context}
            toolbarProps={{
              actions: (
                <>
                  <DownloadCsvAction
                    csvName={csvName}
                    entityType={entityType}
                    variables={{
                      where: !isEmpty(variables.boqWhere) ? variables.boqWhere : undefined,
                    }}
                  />
                  {L8Button}
                </>
              ),
            }}
          />
        )}
      </DataTableHotKeysWrapper>
    </>
  );
};

export default BillOfQuantities;
