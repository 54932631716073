import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { IDataTableColumn } from '../../components/DataTable/types';
import ColorCoder, { Color } from '../../components/ColorCoder';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { omit } from 'lodash';
import { Grid, IconButton, InputLabel, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import gql from 'graphql-tag';
import { itemBoilerlateFields } from './ItemBoilerplate';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';
import { translateBool } from '../../utils/bool.translate.util';
import { formatVolume } from '../../utils/format/volume';
import { Search, useSearchState } from '../../components/Search/Search';
import { useQuery } from 'react-apollo';
import { getItemBoilerplates, getItemBoilerplatesVariables } from './types/getItemBoilerplates';
import AppProgress from '../../components/Page/AppProgress';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { ITEM_BOILERPLATE_COMPUTED_FIELDS } from '../../components/Item/ItemImporter/ItemBoilerplateSearch/CatalogMaterialQuery';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { GetLoggedInUser } from '../types/GetLoggedInUser';
import { GET_LOGGEDIN_USER } from '../Login';
import { hasAccessRights } from '../../utils';
import { Role } from '../../enums';
import CatalogSelect from '../../components/Item/ItemImporter/ItemBoilerplateSearch/CatalogSelect';
import MaterialSelect from '../../components/Item/ItemImporter/ItemBoilerplateSearch/MaterialSelect';
import { useMount } from 'react-use';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const columns: IDataTableColumn[] = [
  {
    id: 'catalog.useName',
    label: 'Katalogname',
  },
  {
    id: 'productNumber',
    label: 'Produktnummer',
  },
  {
    id: 'catalogSection',
    label: 'Hierarchienr 1',
  },
  {
    id: 'catalogSubsection',
    label: 'Hierarchienr 2',
  },
  {
    id: 'catalogMainGroup',
    label: 'Hierarchienr 3',
  },
  {
    id: 'catalogSectionDescription',
    label: 'Hierarchie 1',
  },
  {
    id: 'catalogSubsectionDescription',
    label: 'Hierarchie 2',
  },
  {
    id: 'catalogMainGroupDescription',
    label: 'Hierarchie 3',
  },
  {
    id: 'descriptionOne',
    label: 'Beschreibung 1',
  },
  {
    id: 'descriptionTwo',
    label: 'Beschreibung 2',
  },
  {
    id: 'freeText',
    label: 'Freitext',
    hideOnDefault: true,
  },
  {
    id: 'unit',
    label: 'Einheit',
  },
  {
    id: 'basePrice',
    label: 'Preis',
    render: (cell, row) => formatCurrency(cell || row.data.predefinedPricePerUnit || 0),
  },
  {
    id: 'acronym',
    label: 'Kurzbezeichnung',
  },
  {
    id: 'category',
    label: 'Kategorie',
  },
  {
    id: 'markingStyle',
    label: 'Typ',
  },
  {
    id: 'color',
    label: 'Farbe',
  },
  {
    id: 'dimensionOne',
    label: 'Dimension 1',
  },
  {
    id: 'dimensionTwo',
    label: 'Dimension 2',
  },
  {
    id: 'reflexion',
    label: 'Reflektion',
  },
  {
    id: 'applyScaleDiscount',
    label: 'Mengenrabatt',
    render: translateBool,
    preventSorting: true,
  },
  {
    id: 'material',
    label: 'Verbrauchsmaterial',
    hideOnDefault: true,
  },
  {
    id: 'targetConsumptionPerUnit',
    label: 'Sollverbrauch EinheitMat/Einheitpos',
    hideOnDefault: true,
    render: formatVolume,
  },
  {
    id: 'timeRequired',
    label: 'Zeitbedarf min/einheit',
    hideOnDefault: true,
  },
  {
    id: 'materialColor',
    label: 'Materialcode',
    render: (colorCode: Color) => colorCode && <ColorCoder colorCodings={[colorCode]} />,
  },
];

const columnIds = columns.map(({ id }) => id);

export const GET_ITEM_BOILERPLATES = gql`
  query getItemBoilerplates(
    $search: SearchInput
    $page: PageInput
    $orderBy: OrderByInput
    $where: ItemBoilerplateWhereInput
  ) {
    itemBoilerplates(search: $search, page: $page, orderBy: $orderBy, where: $where) {
      ...itemBoilerlateFields
    }
  }
  ${itemBoilerlateFields}
`;

const tableDataMapper = (itemBoilerplates: any[]) =>
  itemBoilerplates.map((itemBoilerplate: any) => ({
    id: itemBoilerplate.id,
    data: {
      ...itemBoilerplate,
      'catalog.useName': itemBoilerplate.catalog.useName,
      applyScaleDiscount: itemBoilerplate.scaleDiscounts.length > 1,
    },
  }));

const ItemBoilerplates: React.FunctionComponent = () => {
  useEffect(() => window.parent.postMessage('momoItemBoilerplates', '*'), []);

  const [boilerplateId, setBoilerplateId] = useState('');
  const location = useLocation();
  const [selectedCatalogId, setSelectedCatalogId] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const searchState = useSearchState();
  const userRole = useQuery<GetLoggedInUser>(GET_LOGGEDIN_USER).data!.auth.role!;

  const { data, error, loading, refetch, variables } = useQuery<
    getItemBoilerplates,
    getItemBoilerplatesVariables
  >(GET_ITEM_BOILERPLATES, {
    variables: {
      page: {
        pageNumber: 0,
        amountPerPage: 25,
      },
    },
  });

  const count = useCount({
    entity: CountEntity.ITEMBOILERPLATE,
    variables: omit(variables, 'page'),
  });

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch],
  );
  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const onChangeSelect = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const catalogId = event.target.value as string;

      setSelectedCatalogId(catalogId);
      refetch({ where: { catalog: catalogId ? { id: catalogId } : undefined } });
    },
    [refetch],
  );
  const onChangeMaterial = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const material = event.target.value as string;

      setSelectedMaterial(material);
      refetch({
        where: { ...variables.where, material: material !== 'all' ? material : undefined },
      });
    },
    [refetch, variables],
  );

  useMount(() => {
    // trigger onChangeSelect if catalogId got passed via location state
    if (location.state?.catalogId) {
      onChangeSelect({ target: { value: location.state.catalogId } } as ChangeEvent<{
        value: unknown;
      }>);
    }
  });

  if (boilerplateId) {
    return <Redirect to={`/einzel-positions-vorlagen/${boilerplateId}/editieren`} />;
  }

  if (error) {
    console.log(error);
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <Grid container spacing={2}>
        <Grid item>
          <InputLabel>Katalog</InputLabel>
          <CatalogSelect onChange={onChangeSelect} value={selectedCatalogId} />
        </Grid>
        <Grid item>
          {selectedCatalogId && <InputLabel>Material</InputLabel>}
          <MaterialSelect
            catalogId={selectedCatalogId}
            value={selectedMaterial}
            onChange={onChangeMaterial}
          />
        </Grid>
      </Grid>
      <DataTable
        innerTableRows={tableDataMapper(data?.itemBoilerplates ?? [])}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            searchState={searchState}
            columns={columnIds}
            loading={loading}
            onSubmit={(search) => {
              search.columns[search.columns.indexOf('catalog')] = 'catalog.useName';
              return refetch({ search });
            }}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: 'ITEM_BOILER_PLATE',
          levels: [
            {
              columns,
              ...(hasAccessRights(userRole, Role.ADMIN)
                ? {
                    onRowClick: (row) => setBoilerplateId(row.id),
                    rowActions: ({ row }) => (
                      <Tooltip title="Bearbeiten (Enter)">
                        <Link to={`/einzel-positions-vorlagen/${row.id}/editieren`}>
                          <IconButton aria-label="Bearbeiten">
                            <CreateIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    ),
                  }
                : {}),
            },
          ],
          onChangeSort: async (fieldName, order) => {
            await refetch({
              orderBy: {
                fieldName: createOrderByFieldName(fieldName, order),
                isComputed: isComputedField(fieldName, ITEM_BOILERPLATE_COMPUTED_FIELDS),
              },
            });
          },
        }}
      />
    </>
  );
};

export default ItemBoilerplates;
