import React, { useCallback, useMemo } from 'react';
import { Mutation, useApolloClient, useQuery } from 'react-apollo';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  AddLocation as AddLocationIcon,
  Edit as EditIcon,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { pick } from 'lodash';
import { ActionType, DataTableBody, DataTableHotKeysWrapper } from '../../../components/DataTable';
import CreateLocationDialog from '../../../components/BillOfQuantity/CreateLocationDialog';
import ItemImporter from '../../../components/Item/ItemImporter';
import { IItem, IItemWithAmount } from '../TabMissions/TabMissions';
import SelectedItemsInfo from '../TabMissions/SelectedItemsInfo';
import { Filter, IFilterConfig, useFilterReducer } from '../../../components/Filter';
import {
  billOfQuantityColumns,
  itemColumns,
  locationColumns,
} from '../TabMissions/BillOfQuantitySelector/row-definitions';
import ItemDetails from '../../../components/Item/ItemDetails';
import { getFlatItems } from '../TabMissions/BillOfQuantitySelector/BillOfQuantitySelector';
import { useL8Filter } from '../../../hooks/BillOfQuantity/useL8Filter';
import { Search, useSearchState } from '../../../components/Search/Search';
import {
  CREATE_ITEM_MUTATION,
  CREATE_LOCATION_MUTATION,
} from '../../../components/BillOfQuantity/BllOfQuantityTable/BillOfQuantityTable.queries';
import AppProgress from '../../../components/Page/AppProgress';
import { GET_PROJECT } from '../TabDetails';
import { GetProject, GetProjectVariables } from '../TabDetails/types/GetProject';
import {
  tryParseCompositeLocationId,
  useBillOfQuantityData,
} from '../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper';
import { IDataTableRow, ILevelOptions, OnLoadMore } from '../../../components/DataTable/types';
import {
  isCompositeLocationId,
  parseCompositeLocationId,
} from '../../../components/BillOfQuantity/BllOfQuantityTable/utils/compositeLocationId';
import { flattenContainerRows } from '../../../utils/flattenRows/flattenRows';
import {
  CreateLocation,
  CreateLocationVariables,
} from '../../../components/BillOfQuantity/BllOfQuantityTable/types/CreateLocation';
import { BillOfQuantityEntityType, TableType } from '../../../types/graphql';
import {
  removeTableTypeFromId,
  swapTableType,
} from '../TabMissions/MissionSelector/paginationHelpers/mapper';
import { executeForPopulatedBoqTableTypes } from '../../../components/BillOfQuantity/BllOfQuantityTable/utils/paginationHelper/executeForBoqTypes';
import { PAGINATION_HELPERS } from '../../../utils/paginationHelpers';
import { MutationUpdaterFn } from 'apollo-client';
import { ProjectMissions_project_missions } from '../TabMissions/MissionSelector/types/ProjectMissions';
import getSelectedContainersToRefetch from '../BillOfQuantitySelector/getSelectedContainersToRefetch';
import { IMissionLocationIdInput } from '../TabMissions/MissionSelector/paginationHelpers';
import { useImportCatalog } from '../../../components/Item/ItemImporter/ItemBoilerplateSearch/importCatalog';

const getInitialValues = (items: IItem[]): { [x: string]: number } =>
  items.reduce(
    (init, item) => ({
      ...init,
      [`volume-${item.id}`]: item.openVolume,
    }),
    {},
  );

interface IProps {
  projectNumber: string;
  mission?: ProjectMissions_project_missions;
  onSubmit: (
    items: IItemWithAmount[],
    allItems: IItem[],
    containerIdsToAdd: string[],
    containersToRefetch: IMissionLocationIdInput[],
  ) => Promise<any> | undefined;
}

const filterConfigs: { [key: string]: IFilterConfig } = {
  acronym: { label: 'Kurzbezeichnung', type: 'string' },
  volume: { label: 'Angebot Menge', type: 'number' },
  basePrice: { label: 'Einheitspreis', type: 'number' },
  catalogMainGroup: { label: 'Hierarchienr 3', type: 'string' },
  catalogMainGroupDescription: { label: 'Hierarchie 3', type: 'string' },
  catalogSection: { label: 'Hierarchienr 1', type: 'string' },
  catalogSectionDescription: { label: 'Hierarchie 1', type: 'string' },
  catalogSubsection: { label: 'Hierarchienr 2', type: 'string' },
  catalogSubsectionDescription: { label: 'Hierarchie 2', type: 'string' },
  category: { label: 'Kategorie', type: 'string' },
  color: { label: 'Farbe', type: 'string' },
  comment: { label: 'Kommentar Position', type: 'string' },
  descriptionOne: { label: 'Beschreibung 1', type: 'string' },
  descriptionTwo: { label: 'Beschreibung 2', type: 'string' },
  dimensionOne: { label: 'Dimension 1', type: 'string' },
  dimensionTwo: { label: 'Dimension 2', type: 'string' },
  freeText: { label: 'Freitext', type: 'string' },
  markingStyle: { label: 'Typ', type: 'string' },
  material: { label: 'Verbrauchsmaterial', type: 'string' },
  productNumber: { label: 'Produktnummer', type: 'string' },
  reflexion: { label: 'Reflektion', type: 'string' },
  targetConsumptionPerUnit: { label: 'Sollverbrauch EinheitMat/Einheitpos', type: 'number' },
  timeRequired: { label: 'Zeitbedarf min/einheit', type: 'number' },
  type: { label: 'Positionsart', type: 'string' },
  unit: { label: 'Einheit', type: 'string' },
};

const isLevelToggleButtonVisible = (row: IDataTableRow) => row.data.hasItemsOrLocations;

const BillOfQuantitySelector: React.FC<IProps> = ({ projectNumber, mission, onSubmit }) => {
  const [filterState, dispatchFilter] = useFilterReducer();
  const [showSelect, setShowSelect] = React.useState(false);
  const [showCreateLocation, setShowCreateLocation] = React.useState(false);
  const [selectedLocationId, setSelectedLocationId] = React.useState('');
  const [editItemId, setEditItemId] = React.useState('');
  const [showFinishedItems, setShowFinishedItems] = React.useState<boolean>(false);
  const {
    mappedRows,
    onToggleL8,
    onToggleShowFinishedItems,
    onSearch,
    hasNextPage,
    fetchItemsConnection,
    loading,
  } = useBillOfQuantityData(
    projectNumber,
    TableType.ORDER_MEASUREMENT,
    BillOfQuantityEntityType.ORDER,
  );
  const { L8Button } = useL8Filter({ onToggle: onToggleL8 });
  const client = useApolloClient();
  const { executeImportCatalog } = useImportCatalog();

  const searchState = useSearchState();

  const itemsWhere = useMemo(
    () =>
      Object.entries(filterState.addedFilters).reduce(
        (itemsWhere, [key, filter]) => ({
          ...itemsWhere,
          ...(filter.type === 'number'
            ? filter.value !== '' && { [key]: Number(filter.value) }
            : { [key]: filter.value }),
        }),
        {},
      ),
    [filterState.addedFilters],
  );

  const { data: projectData } = useQuery<GetProject, GetProjectVariables>(GET_PROJECT, {
    variables: {
      projectNumber,
    },
  });
  const { flatItems, tableData, initialValues } = useMemo(() => {
    const flatItems = getFlatItems(mappedRows);

    return {
      flatItems,
      tableData: mappedRows,
      initialValues: getInitialValues(flatItems),
    };
  }, [mappedRows]);

  const handleSubmit = useCallback(
    async (
      values: { [x: string]: number },
      checkedRowIds: string[],
      selectedContainerIds: string[],
    ) => {
      const submittableItems = (flatItems || [])
        .filter((item) => checkedRowIds.includes(item.id) && values[`volume-${item.id}`] > 0)
        .map((item) => ({
          ...item,
          amount: values[`volume-${item.id}`],
        }));

      await onSubmit(
        submittableItems,
        flatItems,
        selectedContainerIds,
        getSelectedContainersToRefetch(selectedContainerIds, tableData),
      );
    },
    [flatItems, onSubmit, tableData],
  );

  const isOnLoadMoreDisabled = useCallback(
    (row: IDataTableRow) => {
      return !hasNextPage(row.id);
    },
    [hasNextPage],
  );

  const onLoadMore = useCallback<OnLoadMore>(
    (type, containingRowId, after) => {
      const locationId = tryParseCompositeLocationId('locationId')(
        removeTableTypeFromId(containingRowId),
      );

      return fetchItemsConnection({
        take: 10,
        where: { id: locationId },
        after,
        itemsWhere: { isFinished: showFinishedItems, ...itemsWhere },
      });
    },
    [fetchItemsConnection, itemsWhere, showFinishedItems],
  );

  const addLocationToCaches = useCallback<MutationUpdaterFn<CreateLocation>>(
    (_, { data }) => {
      if (!data || !projectNumber) {
        return;
      }

      executeForPopulatedBoqTableTypes(client, projectNumber, (tableType) => {
        PAGINATION_HELPERS[projectNumber][tableType]?.addLocationToCache(data.createLocation);
      });
    },
    [client, projectNumber],
  );

  if (!flatItems || !tableData || !initialValues) {
    return null;
  }

  const createOpenSelectItemBoilerplate = (locationId: string) => () => {
    setSelectedLocationId(locationId);
    setShowSelect(true);
  };

  const createOpenCreateLocation = (locationId: string) => () => {
    setSelectedLocationId(locationId);
    setShowCreateLocation(true);
  };

  const levels: ILevelOptions[] = [
    {
      columns: billOfQuantityColumns,
      isDefaultClosed: true,
      isLevelToggleButtonVisible,
      isOnLoadMoreDisabled,
      onLoadMore,
      rowActions: ({ row }: any) => (
        <>
          <Tooltip title="Position hinzufügen">
            <IconButton onClick={createOpenSelectItemBoilerplate(row.data.defaultLocation.id)}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Örtlichkeit hinzufügen">
            <IconButton onClick={createOpenCreateLocation(row.data.defaultLocation.id)}>
              <AddLocationIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      columns: locationColumns,
      isDefaultClosed: true,
      isLevelToggleButtonVisible,
      isOnLoadMoreDisabled,
      onLoadMore,
      rowActions: ({ row }: any) => (
        <>
          <Tooltip title="Position hinzufügen">
            <IconButton onClick={createOpenSelectItemBoilerplate(row.data.id)}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Örtlichkeit hinzufügen">
            <IconButton onClick={createOpenCreateLocation(row.data.id)}>
              <AddLocationIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      columns: locationColumns,
      isDefaultClosed: true,
      isLevelToggleButtonVisible,
      isOnLoadMoreDisabled,
      onLoadMore,
      rowActions: ({ row }: any) => (
        <>
          <Tooltip title="Position hinzufügen">
            <IconButton onClick={createOpenSelectItemBoilerplate(row.data.id)}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      columns: itemColumns(true),
      hasCheckboxes: true,
      rowActions: ({ row }: any) => (
        <>
          <Tooltip title="Editieren">
            <IconButton onClick={() => setEditItemId(row.id)} aria-label="Editieren">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      actions: () => (
        <>
          <Tooltip
            title={`Abgeschlossene Positionen ${showFinishedItems ? 'ausblenden' : 'einblenden'}`}
          >
            <IconButton
              onClick={() => {
                setShowFinishedItems(!showFinishedItems);
                onToggleShowFinishedItems({ ...itemsWhere, isFinished: !showFinishedItems })();
              }}
            >
              {showFinishedItems ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Tooltip>
          {L8Button}
        </>
      ),
    },
  ];

  return (
    <>
      <DataTableHotKeysWrapper
        containerRows={tableData}
        search={
          <Search
            columns={['productNumber', 'acronym', 'descriptionOne', 'category', 'unit']}
            searchState={searchState}
            loading={loading}
            onSubmit={(searchInput) => onSearch(searchInput, showFinishedItems)}
          />
        }
        options={{
          levels,
          fixedWidthColumns: true,
          filterText: searchState.searchTerm,
          isSelectableContainers: true,
          tableName: 'MEASUREMENTS_BOQ_SELECTOR',
        }}
      >
        {(context) => {
          const activeColumnIds = context.tableData.innerTableOptions.columns.reduce<string[]>(
            (activeColumnIds, c) => [...activeColumnIds, c.id],
            [],
          );
          const filters = pick(filterConfigs, activeColumnIds);
          const normalizedCheckedRowIds = context.checkedRowIds.map(removeTableTypeFromId);

          return (
            <>
              {loading && <AppProgress />}
              <Filter
                filters={filters}
                addedFilters={filterState.addedFilters}
                selectedFilter={filterState.selectedFilter}
                dispatch={dispatchFilter}
              />
              <Formik<{ [x: string]: number }>
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  await handleSubmit(
                    values,
                    normalizedCheckedRowIds,
                    context.state.selectedContainerIds,
                  );
                  setSubmitting(false);
                  resetForm();
                  context.dispatch({ type: ActionType.RESET_CHECKBOXES });
                }}
                enableReinitialize
              >
                {({ values }) => {
                  const selectedItems = flatItems
                    .filter((item) => normalizedCheckedRowIds.includes(item.id))
                    .map((item) => ({
                      ...item,
                      amount: values[`volume-${item.id}`],
                    }));

                  return (
                    <Form>
                      <DataTableBody context={context} />
                      <SelectedItemsInfo
                        selectedContainerIds={context.state.selectedContainerIds}
                        items={selectedItems}
                        mission={mission}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </DataTableHotKeysWrapper>
      <Mutation<CreateLocation, CreateLocationVariables>
        mutation={CREATE_LOCATION_MUTATION}
        update={addLocationToCaches}
      >
        {(createLocation) => (
          <CreateLocationDialog
            open={showCreateLocation}
            onSubmit={async ({ name }: any) => {
              await createLocation({
                variables: { parentLocationId: selectedLocationId, name },
              });
              setShowCreateLocation(false);
            }}
            onClose={() => setShowCreateLocation(false)}
          />
        )}
      </Mutation>
      {editItemId && (
        <ItemDetails open={!!editItemId} itemId={editItemId} onClose={() => setEditItemId('')} />
      )}
      <Mutation<any, any> mutation={CREATE_ITEM_MUTATION}>
        {(addItem, { loading }) => (
          <>
            {loading && <AppProgress />}
            <ItemImporter
              open={showSelect}
              project={
                projectData?.project && {
                  catalogId: projectData.project.catalog?.id,
                  projectNumber,
                }
              }
              locationId={selectedLocationId}
              onAddItems={async (items, selectedCatalogId, isImportCatalog) => {
                const normalizedSelectedLocationId = removeTableTypeFromId(selectedLocationId);

                const locationRow = flattenContainerRows(mappedRows).find((row) => {
                  return (
                    removeTableTypeFromId(tryParseCompositeLocationId('locationId')(row.id)) ===
                    normalizedSelectedLocationId
                  );
                });

                if (!locationRow) {
                  return;
                }

                if (isImportCatalog && selectedCatalogId) {
                  await executeImportCatalog({
                    variables: {
                      catalog: { id: selectedCatalogId },
                      targetLocation: { id: normalizedSelectedLocationId },
                    },
                  });
                } else {
                  await Promise.all(
                    items.map((item) =>
                      addItem({
                        variables: {
                          itemBoilerplateId: item.rowId,
                          appliedPrice: item.appliedPrice,
                          volume: item.volume,
                          locationId: normalizedSelectedLocationId,
                        },
                      }),
                    ),
                  );
                }

                const isOnBillOfQuantity = !isCompositeLocationId(locationRow.id);
                const billOfQuantityId = isOnBillOfQuantity
                  ? locationRow.data.id
                  : parseCompositeLocationId(locationRow.id).billOfQuantityId;

                executeForPopulatedBoqTableTypes(client, projectNumber, async (tableType) => {
                  const normalizedContainerRowId = swapTableType(tableType)(locationRow.id);

                  await PAGINATION_HELPERS[projectNumber][tableType]?.refetchItemsInContainer([
                    { containerId: normalizedContainerRowId },
                  ]);

                  PAGINATION_HELPERS[projectNumber][tableType]?.updateBillOfQuantityComputedFields(
                    billOfQuantityId,
                  );
                });

                setShowSelect(false);
              }}
              onClose={() => setShowSelect(false)}
            />
          </>
        )}
      </Mutation>
    </>
  );
};

export { BillOfQuantitySelector };
