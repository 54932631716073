import React from 'react';
import { MenuItem } from '@material-ui/core';
import FormikSelect from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';
import { Field } from 'formik';

enum WeatherType {
  SONNIG = 'Sonnig',
  BEDECKT = 'Bedeckt',
  WECHSELHAFT = 'Wechselhaft',
  REGEN = 'Regen',
  SCHNEE = 'Schnee',
  INNENBEREICH = 'Innenbereich',
}

enum SurfaceCondition {
  TROCKEN = 'Trocken',
  NASS = 'Nass',
  FEUCHT = 'Feucht',
  KALT = 'Kalt',
  HEISS = 'Heiss',
  SCHMUTZIG = 'Schmutzig',
}

interface IProps {
  disabled?: boolean;
}

const MeasurementFields: React.FC<IProps> = ({ disabled }) => (
  <>
    <Field component={FormikSelect} label="Ausmaß Wetter" name="weatherType" disabled={disabled}>
      {Object.entries(WeatherType).map(([key, value]) => (
        <MenuItem value={key} key={key}>
          {value}
        </MenuItem>
      ))}
    </Field>
    <Field
      component={FormikTextField}
      name="temperature"
      label="Ausmaß Temperatur"
      type="number"
      disabled={disabled}
    />
    <Field
      component={FormikSelect}
      label="Ausmaß Untergrundverhältnisse"
      name="surfaceConditions"
      disabled={disabled}
      multiple
    >
      {Object.entries(SurfaceCondition).map(([key, value]) => (
        <MenuItem value={key} key={key}>
          {value}
        </MenuItem>
      ))}
    </Field>
  </>
);

export default MeasurementFields;
