import React from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { History } from '@material-ui/icons';
import { FieldProps } from 'formik';
import RejectedStatusDialog from './RejectedStatusDialog';
import BillOfQuantityHistory from './BillOfQuantityHistory';
import { BillOfQuantityEntityType } from '../../../types/graphql';

export const STATUS_REJECTED = 'L8';

interface IBoqStatusProps extends FieldProps {
  history: [];
  isCreate?: boolean;
  entityType: BillOfQuantityEntityType;
  disabled: boolean;
}

export enum BOQStatus {
  L1 = 'L1 - Angebotsstellung' as any,
  L2 = 'L2 - Angeboten' as any,
  L3 = 'L3 - Beauftragt' as any,
  L4 = 'L4 - In Ausführung' as any,
  L5 = 'L5 - Ausgeführt in Abrechnung' as any,
  L6 = 'L6 - Fakturiert' as any,
  L7 = 'L7 - Abgeschlossen' as any,
  L8 = 'L8 - Abgelehnt' as any,
}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const StatusMap = {
  [BillOfQuantityEntityType.OFFER]: [BOQStatus.L1, BOQStatus.L2, BOQStatus.L3, BOQStatus.L8],
  [BillOfQuantityEntityType.ORDER]: [BOQStatus.L4, BOQStatus.L6, BOQStatus.L7, BOQStatus.L8],
};

const BillOfQuantityStatusField: React.FunctionComponent<IBoqStatusProps> = ({
  field: { value, name },
  disabled,
  form: { setFieldValue },
  history,
  entityType,
  isCreate,
}) => {
  const [openRejectedStatus, setOpenRejectedStatus] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [status, setStatus] = React.useState(value && value.status);
  const classes = useStyles();

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
    setStatus(value);
    value === STATUS_REJECTED
      ? setOpenRejectedStatus(true)
      : setFieldValue(name, { status: value });
  };

  return (
    <>
      <FormControl className={classes.select}>
        <InputLabel htmlFor={name}>Status</InputLabel>
        <Select
          disabled={disabled}
          onChange={handleChange}
          inputProps={{
            name,
          }}
          value={status}
          endAdornment={
            history && (
              <InputAdornment position="start">
                <Tooltip title="Historie">
                  <IconButton
                    onClick={() => {
                      setOpenHistory(true);
                    }}
                  >
                    <History fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }
        >
          {StatusMap[entityType].map((value) => (
            <MenuItem value={BOQStatus[value]} key={BOQStatus[value]}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <RejectedStatusDialog
        open={openRejectedStatus}
        onClose={() => setOpenRejectedStatus(!openRejectedStatus)}
        onSave={(values) => {
          setFieldValue(name, {
            status,
            ...values,
          });
        }}
      />
      {!isCreate && (
        <BillOfQuantityHistory
          history={history}
          open={openHistory}
          onClose={() => setOpenHistory(!openHistory)}
        />
      )}
    </>
  );
};

export default BillOfQuantityStatusField;
