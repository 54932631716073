import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { isEmpty, omit } from 'lodash';
import { Form, Formik } from 'formik';
import { missionColumns } from './mission.columns';
import { DataTableBody, DataTableHotKeysWrapper } from '../../components/DataTable';
import { MISSION_QUERY, SELECTED_SUBSIDIARY_QUERY } from './queries';
import { mapToMissionTableData } from './mission.mapper';
import { Filter, useFilterReducer } from '../../components/Filter';
import { mapMissionWhereClause, missionFilterConfigs } from './filter.config';
import { RouteComponentProps } from 'react-router-dom';
import { GetMissions, GetMissionsVariables } from './types/GetMissions';
import { formatDate } from '../../utils/format/date';
import { Search, useSearchState } from '../../components/Search/Search';
import AppProgress from '../../components/Page/AppProgress';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Pagination } from '../../components/Pagination';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { MISSION_COMPUTED_FIELDS } from './mission.consts';

const Missions: React.FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => window.parent.postMessage('momoMissions', '*'), []);

  const [filterState, dispatchFilter] = useFilterReducer();

  const searchState = useSearchState();

  const { error: subsidiaryError, data: subsidiaryData } = useQuery(SELECTED_SUBSIDIARY_QUERY);

  const queryWhereVariables = useMemo(
    () => ({
      ...mapMissionWhereClause(filterState.addedFilters),
      ...(subsidiaryData.selectedSubsidiary !== 'all' && {
        project: {
          subsidiary: { id: subsidiaryData.selectedSubsidiary },
        },
      }),
    }),
    [filterState.addedFilters, subsidiaryData],
  );

  const { loading, error, data, refetch, variables } = useQuery<GetMissions, GetMissionsVariables>(
    MISSION_QUERY,
    {
      variables: {
        where: queryWhereVariables,
        page: {
          amountPerPage: 25,
          pageNumber: 0,
        },
      },
    },
  );

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        where: queryWhereVariables,
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch, queryWhereVariables],
  );

  const count = useCount({
    entity: CountEntity.MISSION,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  if (subsidiaryError || error) {
    console.log(error);
    return null;
  }

  if (!subsidiaryData || !data) {
    return null;
  }

  const flattenedItems = mapToMissionTableData(data);

  return (
    <DataTableHotKeysWrapper
      pagination={<Pagination paginationState={paginationState} />}
      search={
        <Search
          columns={[
            'id',
            'project.projectNumber',
            'project.projectName',
            'materialColor',
            'name',
            'status.missionStatus',
            'externalServices',
            'surfaceType',
            'projectAddress.postCode',
            'projectAddress.city',
          ]}
          searchState={searchState}
          onSubmit={(search) => refetch({ search })}
          loading={loading}
        />
      }
      options={{
        filterText: searchState.searchTerm,
        tableName: 'ALL_MISSIONS',
        onChangeSort: (fieldName, order) =>
          refetch({
            orderBy: {
              fieldName: createOrderByFieldName(fieldName, order),
              isComputed: isComputedField(fieldName, MISSION_COMPUTED_FIELDS),
            },
          }),
        levels: [
          {
            columns: missionColumns,
            onRowClick: (row) =>
              history.push(`/projekte/${row.data['project.projectNumber']}/einsaetze`),
          },
        ],
      }}
      innerTableRows={flattenedItems}
    >
      {(context) => {
        return (
          <>
            {loading && <AppProgress />}
            <Filter
              filters={missionFilterConfigs}
              selectedFilter={filterState.selectedFilter}
              addedFilters={filterState.addedFilters}
              dispatch={dispatchFilter}
            />
            <Formik
              initialValues={{}}
              onSubmit={() => {
                return;
              }}
            >
              {() => {
                return (
                  <Form>
                    <DataTableBody
                      context={context}
                      toolbarProps={{
                        actions: (
                          <>
                            <DownloadCsvAction
                              entityType="MISSION"
                              variables={{
                                where: !isEmpty(variables.where) ? variables.where : undefined,
                                search: variables.search,
                              }}
                              csvName={`Einsätze_${formatDate()}`}
                            />
                          </>
                        ),
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        );
      }}
    </DataTableHotKeysWrapper>
  );
};

export default Missions;
