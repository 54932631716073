import { reverseString } from '../../reverseString';
import { roundFloat } from '../../roundFloat';
/**
 * Formats a volume to the format X'XXX'...'XXX{.YY}
 * @param {number} volume
 * @returns {string} if volume is int: X'XXX'...'XXX; if float: X'XXX...'XXX.YY
 */

export const formatVolume = (volume: number) => {
  const volumeStr = roundFloat(volume);

  const [intPart, decPart] = volumeStr.split('.');

  // no formatting needed
  if (intPart.length <= 3) {
    return volumeStr;
  }

  // reversing because chunking doesnt work from the end of string
  // Example:
  // '1000' -> chunking -> ['100', '0']
  const reversedVolumeStr = reverseString(intPart);

  // split the string in 3 char chunks
  const parts = reversedVolumeStr.match(/.{1,3}/g) as RegExpMatchArray; // type assertion because it is logically impossible to get a null here

  return reverseString(parts.join('.')) + (decPart ? `,${decPart}` : '');
};
