import React, { useCallback, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { Box, Button } from '@material-ui/core';
import { IDataTableRow } from '../../../DataTable/types';
import { DataTableBody, DataTableHotKeysWrapper } from '../../../DataTable';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikTextField from '../../../Form/FormikTextField';
import { IAddItem } from '../index';
import CatalogMaterialQuery, { ITEM_BOILERPLATE_COMPUTED_FIELDS } from './CatalogMaterialQuery';
import { formatVolume } from '../../../../utils/format/volume';
import createCurrencyFormatter from '../../../../utils/createCurrencyFormatter';
import { Search, useSearchState } from '../../../Search/Search';
import { Pagination } from '../../../Pagination';
import AppErrorMessage from '../../../Page/AppErrorMessage';
import createPreventAll from '../../../../utils/createPreventEventDefault';
import { createOrderByFieldName } from '../../../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../../../utils/order/isComputedField';
import { useImportCatalog } from './importCatalog';

const formatCurrencyForEdit = createCurrencyFormatter({ format: '0.00' });

interface IItem {
  appliedPrice: string;
  volume: string;
}

const rowIdToVolumeName = (rowId: string): string => `volume-${rowId}`;
const rowIdToAppliedPriceName = (rowId: string): string => `appliedPrice-${rowId}`;

const mapTableData = (data: any): IDataTableRow[] =>
  data?.itemBoilerplates?.map((i: any) => ({
    id: i.id,
    data: {
      ...i,
      'catalog.useName': i.catalog.useName,
    },
  })) ?? [];

const ItemBoilerplateSearchVolumeEdit: React.FunctionComponent<{
  onSubmit: (items: IAddItem[], selectedCatalogId: string, isImportCatalog: boolean) => void;
  initCatalogId?: string;
}> = ({ onSubmit, initCatalogId }) => {
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: IItem }>({});
  const [isVolumeError, setIsVolumeError] = useState(false);
  const { ImportCatalogJsx, isImportCatalogSelected } = useImportCatalog();

  const searchState = useSearchState();

  const itemBoilerplateSearchVolumeEditColumns = useMemo(() => {
    return [
      {
        id: 'productNumber',
        label: 'Produktnummer',
      },
      {
        id: 'descriptionOne',
        label: 'Beschreibung 1',
      },
      {
        id: 'descriptionTwo',
        label: 'Beschreibung 2',
        hideOnDefault: true,
      },
      {
        id: 'unit',
        label: 'Einheit',
      },
      {
        id: 'formVolume',
        label: 'Angebot Menge',
        render: (cell: any, row: IDataTableRow) => (
          <Field
            name={rowIdToVolumeName(row.id)}
            label="Menge"
            shrink
            component={FormikTextField}
            type="number"
            onBlur={(e: any) => {
              if (row.id in selectedItems) {
                if (isNil(e.target.value)) {
                  setIsVolumeError(true);

                  return;
                }

                setSelectedItems(({ ...selectedItems }) => {
                  selectedItems[row.id].volume = e.target.value;

                  return selectedItems;
                });
              }
            }}
          />
        ),
      },
      {
        id: 'formAppliedPrice',
        label: 'Preis',
        render: (cell: any, row: IDataTableRow) => (
          <Field
            name={rowIdToAppliedPriceName(row.id)}
            label="Preis"
            component={FormikTextField}
            shrink
            type="number"
            onBlur={(e: any) => {
              if (row.id in selectedItems) {
                setSelectedItems(({ ...selectedItems }) => {
                  selectedItems[row.id].appliedPrice = e.target.value;
                  return selectedItems;
                });
              }
            }}
          />
        ),
      },
      {
        id: 'catalog.useName',
        label: 'Katalogname',
      },
      {
        id: 'markingStyle',
        label: 'Typ',
      },
      {
        id: 'category',
        label: 'Kategorie',
        hideOnDefault: true,
      },
      {
        id: 'color',
        label: 'Farbe',
      },
      {
        id: 'dimensionOne',
        label: 'Dimension 1',
      },
      {
        id: 'dimensionTwo',
        label: 'Dimension 2',
        hideOnDefault: true,
      },
      {
        id: 'reflexion',
        label: 'Reflektion',
        hideOnDefault: true,
      },
      {
        id: 'catalogSection',
        label: 'Hierarchienr 1',
        hideOnDefault: true,
      },
      {
        id: 'catalogSubsection',
        label: 'Hierarchienr 2',
        hideOnDefault: true,
      },
      {
        id: 'catalogMainGroup',
        label: 'Hierarchienr 3',
        hideOnDefault: true,
      },
      {
        id: 'catalogSectionDescription',
        label: 'Hierarchie 1',
        hideOnDefault: true,
      },
      {
        id: 'catalogSubsectionDescription',
        label: 'Hierarchie 2',
      },
      {
        id: 'catalogMainGroupDescription',
        label: 'Hierarchie 3',
        hideOnDefault: true,
      },
      {
        id: 'freeText',
        label: 'Freitext',
      },
      {
        id: 'acronym',
        label: 'Kurzbezeichnung',
        hideOnDefault: true,
      },
      {
        id: 'type',
        label: 'Positionsart',
      },
      {
        id: 'material',
        label: 'Verbrauchsmaterial',
        hideOnDefault: true,
      },
      {
        id: 'targetConsumptionPerUnit',
        label: 'Sollverbrauch EinheitMat/Einheitpos',
        hideOnDefault: true,
        render: formatVolume,
      },
      {
        id: 'timeRequired',
        label: 'Zeitbedarf min/einheit',
        hideOnDefault: true,
      },
    ];
  }, [selectedItems]);

  const handleSubmit = useCallback(
    (selectedCatalogId: string) => {
      const items: IAddItem[] = Object.entries(selectedItems).map(([rowId, value]) => {
        return {
          rowId,
          volume: Number.parseFloat(value.volume),
          appliedPrice: Number.parseFloat(value.appliedPrice),
        };
      });
      onSubmit(items, selectedCatalogId, isImportCatalogSelected);
    },
    [selectedItems, onSubmit, isImportCatalogSelected],
  );

  const handleCheckbox = useCallback(
    (rowId: string, checked: boolean, item: { volume: string; appliedPrice: string }) => {
      if (!checked) {
        setSelectedItems(({ ...selectedItems }) => {
          delete selectedItems[rowId];
          return selectedItems;
        });

        return;
      }
      setSelectedItems(({ ...selectedItems }) => {
        selectedItems[rowId] = {
          ...item,
        };
        return selectedItems;
      });
    },
    [],
  );

  return (
    <>
      {isVolumeError && (
        <AppErrorMessage
          isOpen={isVolumeError}
          message="Menge ist ein Pflichtfeld!"
          onClose={() => setIsVolumeError(false)}
          noHideOnClickAway
          noAutoHide
        />
      )}
      <CatalogMaterialQuery
        initiallyOpen={!initCatalogId}
        hasMaterialSelector
        initCatalogId={initCatalogId}
        catalogSelectorLabel="Katalog"
      >
        {({ data, refetch, loading, paginationState, selectedCatalogId }) => {
          const innerTableRows = mapTableData(data);
          const initialValues = innerTableRows.reduce((acc, row) => {
            const defaultAppliedPrice =
              // case 'predefinedPricePerUnit' if the item boilerplate is from a project specific import
              (row.data.predefinedPricePerUnit &&
                formatCurrencyForEdit(row.data.predefinedPricePerUnit)) ||
              // case 'basePrice' if the item boilerplate is from a common import
              (row.data.basePrice && formatCurrencyForEdit(row.data.basePrice)) ||
              '';

            const defaultVolume = row.data.predefinedVolume ? String(row.data.predefinedVolume) : 1;

            return {
              [rowIdToVolumeName(row.id)]:
                row.id in selectedItems ? selectedItems[row.id].volume : defaultVolume,

              [rowIdToAppliedPriceName(row.id)]:
                row.id in selectedItems ? selectedItems[row.id].appliedPrice : defaultAppliedPrice,
              ...acc,
            };
          }, {});

          const validationSchema = Yup.object().shape(
            innerTableRows.reduce(
              (acc, row) => ({
                [rowIdToVolumeName(row.id)]: Yup.number()
                  .moreThan(0, 'Menge muss grösser als null sein!')
                  .required('Menge ist ein Pflichtfeld!'),
                [rowIdToAppliedPriceName(row.id)]: Yup.number(),
                [rowIdToVolumeName(row.id)]: Yup.number().required('Menge ist ein Pflichtfeld!'),
                ...acc,
              }),
              {},
            ),
          );

          return (
            <>
              <Formik<{ [key: string]: string }>
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={() => handleSubmit(selectedCatalogId)}
              >
                {({ submitForm, isSubmitting, values, setSubmitting }) => {
                  // disable form if user checked `ImportCatalog` checkbox
                  if (!isSubmitting && isImportCatalogSelected) {
                    setSubmitting(true);
                    // activate form if user unchecked `ImportCatalog` checkbox
                  } else if (isSubmitting && !isImportCatalogSelected) {
                    setSubmitting(false);
                  }

                  return (
                    <DataTableHotKeysWrapper
                      innerTableRows={innerTableRows}
                      pagination={<Pagination paginationState={paginationState} />}
                      search={
                        <Search
                          searchState={searchState}
                          columns={['productNumber', 'material', 'descriptionOne', 'unit']}
                          onSubmit={(search) => refetch({ search })}
                          loading={loading}
                          disabled={isImportCatalogSelected}
                          autoFocus
                        />
                      }
                      options={{
                        tableName: 'ITEM_BOILERPLATE_SEARCH_VOLUME_EDIT',
                        filterText: searchState.searchTerm,
                        levels: [
                          {
                            onSelectAllCheckboxChange: (checkedIds, checked) => {
                              checkedIds.forEach((id) =>
                                handleCheckbox(id, checked, {
                                  volume: values[rowIdToVolumeName(id)],
                                  appliedPrice: values[rowIdToAppliedPriceName(id)],
                                }),
                              );
                            },
                            onCheckboxChange: (row, checked) => {
                              handleCheckbox(row.id, checked, {
                                volume: values[rowIdToVolumeName(row.id)],
                                appliedPrice: values[rowIdToAppliedPriceName(row.id)],
                              });
                            },
                            columns: itemBoilerplateSearchVolumeEditColumns,
                            hasCheckboxes: true,
                          },
                        ],
                        additionalHotKeys: {
                          keyMap: { ENTER: 'enter' },
                          hotkeyHandlers: {
                            ENTER: createPreventAll(() => {
                              (document.activeElement as HTMLFormElement | null)?.blur?.();
                              // noinspection JSIgnoredPromiseFromCall
                              submitForm();
                            }),
                          },
                        },
                        onChangeSort: async (fieldName, order) => {
                          await refetch({
                            orderBy: {
                              fieldName: createOrderByFieldName(fieldName, order),
                              isComputed: isComputedField(
                                fieldName,
                                ITEM_BOILERPLATE_COMPUTED_FIELDS,
                              ),
                            },
                          });
                        },
                      }}
                    >
                      {(context) => {
                        const { checkedRowIds } = context;
                        const disabled = isImportCatalogSelected
                          ? false
                          : checkedRowIds.length <= 0 || isSubmitting;
                        return (
                          <Form>
                            <DataTableBody
                              context={context}
                              toolbarProps={{
                                actions: (
                                  <Box display="inline-block">
                                    {ImportCatalogJsx}
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      disabled={disabled}
                                    >
                                      Positionen hinzufügen
                                    </Button>
                                  </Box>
                                ),
                              }}
                            />
                          </Form>
                        );
                      }}
                    </DataTableHotKeysWrapper>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </CatalogMaterialQuery>
    </>
  );
};

/** Table to check multiple boilerplate items and edit the volume */
export default ItemBoilerplateSearchVolumeEdit;
