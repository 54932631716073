import React from 'react';
import { Field } from 'formik';
import { Color } from '../../../types/graphql';
import FormikTextField from '../../../components/Form/FormikTextField';
import { IDataTableColumn, IDataTableRow } from '../../../components/DataTable/types';
import ColorCoder from '../../../components/ColorCoder';
import { createSwissCurrencyFormatter } from '../../../utils/createCurrencyFormatter';
import { formatDate } from '../../../utils/format/date';
import { formatVolume } from '../../../utils/format/volume';
import { preventNonNumericInput } from '../../../utils/preventNonNumericInput';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

export const missionColumns: IDataTableColumn[] = [
  {
    id: 'id',
    label: 'Laufnummer',
    width: 100,
  },
  {
    id: 'name',
    label: 'Einsatz',
  },
  {
    id: 'status',
    label: 'Status',
    width: 70,
  },
  {
    id: 'netPlanSum',
    label: 'Plan Summe Netto',
    render: formatCurrency,
    width: 100,
  },
  {
    id: 'grossPlanSum',
    label: 'Plan Summe Brutto',
    render: formatCurrency,
    width: 100,
  },
  {
    id: 'netFinishedSum',
    label: 'Ausmaß Summe Netto',
    render: formatCurrency,
    width: 140,
  },
  {
    id: 'grossFinishedSum',
    label: 'Ausmaß Summe Brutto',
    render: formatCurrency,
    width: 140,
  },
  {
    id: 'executionDate',
    label: 'Ausführungsdatum',
    render: (executionDate) => executionDate && formatDate(executionDate),
    width: 100,
  },
  {
    id: 'colorCodings',
    label: '',
    width: 100,
    render: (colorCodings: Color[]) => colorCodings && <ColorCoder colorCodings={colorCodings} />,
  },
];
export const locationColumns: IDataTableColumn[] = [
  {
    id: 'name',
    label: 'Örtlichkeit',
  },
];
export const missionItemColumns: IDataTableColumn[] = [
  {
    id: 'productNumber',
    label: 'Art.Nr.',
    width: 110,
  },
  {
    id: 'descriptionOne',
    label: 'Beschreibung 1',
    width: 200,
  },
  {
    id: 'billOfQuantity',
    label: 'LV',
    hideOnDefault: true,
  },
  {
    id: 'descriptionTwo',
    label: 'Beschreibung 2',
    hideOnDefault: true,
  },
  {
    id: 'freeText',
    label: 'Freitext',
    hideOnDefault: true,
  },
  {
    id: 'unit',
    label: 'Einheit',
    width: 60,
  },
  {
    id: 'netPrice',
    label: 'Nettopreis',
    width: 100,
    render: formatCurrency,
  },
  {
    id: 'markingStyle',
    label: 'Positionsart',
    width: 110,
    hideOnDefault: true,
  },
  {
    id: 'openVolume',
    label: 'Noch offene Menge',
    hideOnDefault: true,
    render: formatVolume,
  },
  {
    id: 'volume',
    label: 'Angebot Menge',
    width: 80,
    render: formatVolume,
  },
  {
    id: 'netOfferSum',
    label: 'Angebot Summe Netto',
    render: formatCurrency,
    hideOnDefault: true,
  },
  {
    id: 'grossOfferSum',
    label: 'Angebot Summe Brutto',
    width: 120,
    render: formatCurrency,
    hideOnDefault: true,
  },
  {
    id: 'finishedVolume',
    label: 'Ausmaß Menge',
    width: 80,
    render: formatVolume,
  },
  {
    id: 'openSum',
    label: 'Ausmaß Summe',
    hideOnDefault: true,
    render: formatCurrency,
  },
  {
    id: 'invoicedVolume',
    label: 'Verrechnete Menge',
    width: 95,
    render: formatVolume,
  },
  {
    id: 'missionItemSum',
    label: 'Plan Summe',
    width: 110,
    render: formatCurrency,
    hideOnDefault: true,
  },
  {
    id: 'invoicedSum',
    label: 'Verrechnete Summe',
    width: 110,
    render: formatCurrency,
  },

  {
    id: 'openInvoicedVolume',
    label: 'Noch zu verrechnende Menge',
    width: 100,
    render: formatVolume,
  },
  {
    id: 'openInvoicedSum',
    label: 'Noch zu verrechnende Summe',
    width: 110,
    render: formatCurrency,
  },
  {
    id: 'amountVolume',
    label: 'Jetzt zu verrechnende Menge',
    width: 100,
    render: (cell: any, row: IDataTableRow) => (
      <Field
        name={`${row.id}.amountVolume`}
        component={FormikTextField}
        disabled={row.data.mission.isMissionBlocked}
        type="number"
        onKeyPress={preventNonNumericInput}
      />
    ),
  },
  {
    id: 'comment',
    label: 'Positions Kommentar',
    hideOnDefault: true,
  },
  {
    id: 'customerComment',
    label: 'Bemerkungen Kunde',
    hideOnDefault: true,
  },
  {
    id: 'additionalText',
    label: 'Zusatztext',
    width: 110,
  },
];
