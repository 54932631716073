import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { first, isEmpty, last } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useProjectNumber } from '../../hooks/useProjectNumber';
import { PdfPrintStoreIn, PdfPrintType } from '../../types/graphql';
import { convertToInputValue } from '../../utils/dateToInputValue';
import { downloadFile } from '../../utils/downloadFile';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';
import { GET_PROJECT_REPOSITORY_QUERY } from '../FileRepository/queries';
import FormikCheckboxWithLabel from '../Form/FormikCheckboxWithLabel';
import FormikTextField from '../Form/FormikTextField';
import AppErrorMessage from '../Page/AppErrorMessage';
import AppProgress from '../Page/AppProgress';
import { PDF_PRINT_EXECUTION_DATE_RANGE_QUERY, PDF_PRINT_QUERY } from './pdfPrint.queries';
import { PdfPrint as TPdfPrint, PdfPrintVariables } from './types/PdfPrint';
import {
  PdfPrintExecutionDateRange,
  PdfPrintExecutionDateRangeVariables,
} from './types/PdfPrintExecutionDateRange';

export enum PrintType {
  MISSION,
  RAPPORT,
  BILL_OF_QUANTITY,
}

export enum AvailableRapportParameters {
  DAY_SUM = 'daySum',
  LOCATIONS = 'locations',
  SUMMARY = 'summary',
  PRICE = 'price',
  MEASUREMENT_ACCEPTED = 'measurementAccepted',
  STORE_IN_PROJECT = 'storeInProject',
  CONFIRMATION = 'confirmation',
  SORT_BY_LOCATIONS = 'sortByLocations',
  DELIVERY_NOTE = 'deliveryNote',
}

interface IData {
  id: string;

  missions?: number[];

  daySum?: boolean;
  locations?: boolean;
  summary?: boolean;
  price?: boolean;
  measurementAccepted?: boolean;
  storeInProject?: boolean;
  confirmation?: boolean;
  sortByLocations?: boolean;
  deliveryNote?: boolean;
}

interface IProps {
  printType: PdfPrintType;
  pdfFilename?: string | ((rapportParametersToShow: AvailableRapportParameters[]) => string);
  data: IData;
  rapportParametersToShow?: AvailableRapportParameters[];
  disabled?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  formWrapper: {
    padding: theme.spacing(2),
  },
}));

const PdfPrint: React.FC<IProps> = ({
  printType,
  data,
  rapportParametersToShow,
  disabled,
  pdfFilename,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const params = useParams();
  const client = useApolloClient();
  const projectNumber = useProjectNumber();
  if (!projectNumber) {
    throw new Error('PdfPrint rendered in a route without a projectNumber');
  }

  const {
    data: executionRangeData,
    loading,
    error,
  } = useQuery<PdfPrintExecutionDateRange, PdfPrintExecutionDateRangeVariables>(
    PDF_PRINT_EXECUTION_DATE_RANGE_QUERY,
    { variables: { projectNumber: params.projectNumber } },
  );

  const {
    loading: printLoading,
    error: printError,
    refetch: executePrintQuery,
  } = useQuery<TPdfPrint, PdfPrintVariables>(PDF_PRINT_QUERY, {
    skip: true,
    fetchPolicy: 'network-only',
  });

  const executionRangeDataMapped = executionRangeData?.project ?? null;

  const missions = useMemo(() => {
    return executionRangeDataMapped?.missions.filter((mission) => mission.executionDate) ?? [];
  }, [executionRangeDataMapped]);

  const filteredMissions = useMemo(
    () =>
      data.missions
        ? missions.filter((mission) => data.missions!.indexOf(mission.id) > -1)
        : missions,
    [data.missions, missions],
  );

  const oldestMission = last<any>(filteredMissions);
  const newestMission = first<any>(filteredMissions);

  const minExecDate =
    oldestMission && oldestMission.executionDate
      ? new Date(oldestMission.executionDate)
      : new Date();

  const maxExecDate =
    newestMission && newestMission.executionDate
      ? new Date(newestMission.executionDate)
      : new Date();

  const handlePopoverOpen = useCallback(
    (event: any, setFieldValue: any) => {
      setAnchorEl(event.currentTarget);

      // recalculate dates on popover open in case positions have been added or removed from
      // the table that's being printed
      if (!isEmpty(data.missions) && !isEmpty(filteredMissions)) {
        setFieldValue(
          'measurementAcceptedMinDate',
          convertToInputValue(new Date(last<any>(filteredMissions).executionDate)),
        );
        setFieldValue(
          'measurementAcceptedMaxDate',
          convertToInputValue(new Date(first<any>(filteredMissions).executionDate)),
        );
      }
    },
    [setAnchorEl, data.missions, filteredMissions],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handlePdfDownloadRequest = useCallback(
    async ({
      measurementAcceptedMinDate,
      measurementAcceptedMaxDate,
      storeInProject,
      ...paramsToShow
    }: any) => {
      setButtonDisabled(true);

      const res = await executePrintQuery({
        include: paramsToShow,
        type: printType,
        where: {
          id: String(data.id),
          maxDate: measurementAcceptedMaxDate,
          minDate: measurementAcceptedMinDate,
        },
        storeIn: [...(storeInProject ? [PdfPrintStoreIn.PROJECT] : [])],
      });

      // refetch repository if new document gets added by print
      if (storeInProject) {
        client.query({
          query: GET_PROJECT_REPOSITORY_QUERY,
          fetchPolicy: 'network-only',
          variables: { projectNumber },
        });
      }

      const file = `uploads/tmp/${res.data.pdfPrint.fileName}?delete=true`;

      const fileName = pdfFilename
        ? typeof pdfFilename === 'string'
          ? pdfFilename
          : pdfFilename(
              Object.keys(paramsToShow).filter(
                (param) => !!paramsToShow[param],
              ) as AvailableRapportParameters[],
            )
        : '';

      switch (printType) {
        case PdfPrintType.BILL_OF_QUANTITY:
          await downloadFile(file, fileName);
          break;
        case PdfPrintType.MISSION:
          await downloadFile(file, fileName);
          break;
        case PdfPrintType.RAPPORT:
          await downloadFile(file, fileName);
          break;
      }

      setButtonDisabled(false);
    },
    [executePrintQuery, printType, data.id, pdfFilename, client, projectNumber],
  );

  if (loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  if (!executionRangeData) {
    return null;
  }

  const {
    daySum,
    locations,
    summary,
    price,
    measurementAccepted,
    storeInProject,
    confirmation,
    sortByLocations,
    deliveryNote,
  } = data;

  return (
    <>
      {buttonDisabled && <AppProgress />}
      {printError && <AppErrorMessage message={errorPrefixRemover(printError.message)} />}
      {rapportParametersToShow ? (
        <>
          <Formik
            initialValues={{
              daySum,
              locations,
              summary,
              price,
              measurementAccepted,
              measurementAcceptedMinDate: convertToInputValue(minExecDate),
              measurementAcceptedMaxDate: convertToInputValue(maxExecDate),
              storeInProject,
              confirmation,
              sortByLocations,
              deliveryNote,
            }}
            onSubmit={() => {
              return;
            }}
          >
            {({ values, setFieldValue }) => (
              <span onMouseEnter={(e) => handlePopoverOpen(e, setFieldValue)}>
                <IconButton
                  aria-label={'Drucken'}
                  onClick={() => handlePdfDownloadRequest(values)}
                  disabled={(disabled && printLoading) || buttonDisabled}
                >
                  <PrintIcon />
                </IconButton>
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
                  <ClickAwayListener onClickAway={handlePopoverClose}>
                    <Paper className={classes.formWrapper}>
                      <Form>
                        {rapportParametersToShow?.includes(AvailableRapportParameters.DAY_SUM) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="daySum"
                            Label={{ label: 'Tagessumme' }}
                            disabled={values.sortByLocations}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.LOCATIONS,
                        ) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="locations"
                            Label={{ label: 'Örtlichkeit' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(AvailableRapportParameters.SUMMARY) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="summary"
                            Label={{ label: 'Zusammenfassung' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(AvailableRapportParameters.PRICE) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="price"
                            Label={{ label: 'Preis' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.MEASUREMENT_ACCEPTED,
                        ) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="measurementAccepted"
                            Label={{ label: 'Ausmaß' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.CONFIRMATION,
                        ) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="confirmation"
                            Label={{ label: 'Auftragsbestätigung' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.SORT_BY_LOCATIONS,
                        ) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="sortByLocations"
                            Label={{ label: 'Sort. Örtlichkeit' }}
                            disabled={values.daySum}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.DELIVERY_NOTE,
                        ) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="deliveryNote"
                            Label={{ label: 'Lieferschein' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.STORE_IN_PROJECT,
                        ) && (
                          <Field
                            component={FormikCheckboxWithLabel}
                            name="storeInProject"
                            Label={{ label: 'In Projekt ablegen' }}
                          />
                        )}

                        {rapportParametersToShow?.includes(
                          AvailableRapportParameters.MEASUREMENT_ACCEPTED,
                        ) && (
                          <>
                            <Box display="flex">
                              <Field
                                component={FormikTextField}
                                name="measurementAcceptedMinDate"
                                type="date"
                                shrink
                                label="Ausmaß min Datum"
                              />
                              <Field
                                component={FormikTextField}
                                name="measurementAcceptedMaxDate"
                                type="date"
                                shrink
                                label="Ausmaß max Datum"
                              />
                            </Box>
                          </>
                        )}
                      </Form>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </span>
            )}
          </Formik>
        </>
      ) : (
        <Tooltip title="Drucken">
          <span>
            <IconButton
              aria-label={'Drucken'}
              onClick={() => handlePdfDownloadRequest({})}
              disabled={disabled || buttonDisabled}
            >
              <PrintIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default PdfPrint;
