import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Create from '@material-ui/icons/Create';
import { Link, RouteComponentProps } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AddButton from '../DataTable/DataTableToolbar/AddButton';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { remove } from 'lodash';
import AppProgress from '../Page/AppProgress';
import { formatVolume } from '../../utils/format/volume';
import { createSwissCurrencyFormatter } from '../../utils/createCurrencyFormatter';

const formatCurrency = createSwissCurrencyFormatter({ withCurrency: true });

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
  headerToolbar: {
    justifyContent: 'space-between',
  },
  secondaryToolbar: {
    justifyContent: 'flex-end',
  },
}));

export const scaleDiscountFields = gql`
  fragment scaleDiscountFields on ScaleDiscount {
    id
    pricePerUnit
    threshhold
  }
`;

export const SCALE_DISCOUNTS_QUERY = gql`
  query ScaleDiscounts($itemBoilerplateId: ID!) {
    scaleDiscounts(where: { itemBoilerplate: { id: $itemBoilerplateId } }) {
      ...scaleDiscountFields
    }
  }
  ${scaleDiscountFields}
`;
const DELETE_SCALE_DISCOUNT_MUTATION = gql`
  mutation DeleteScaleDiscount($id: ID!) {
    deleteScaleDiscount(id: $id) {
      id
    }
  }
`;

const ScaleDiscounts: React.FunctionComponent<RouteComponentProps> = ({ match, history }) => {
  const classes = useStyles();

  const itemBoilerplateId = (match.params as any).id;

  const handleClose = () => history.goBack();

  return (
    <Query<any, any> query={SCALE_DISCOUNTS_QUERY} variables={{ itemBoilerplateId }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <AppProgress />;
        }

        if (error) {
          console.error(error);
          return null;
        }

        return (
          data && (
            <Mutation<any, any>
              mutation={DELETE_SCALE_DISCOUNT_MUTATION}
              update={(cache, { data: { deleteScaleDiscount } }) => {
                const { scaleDiscounts } = cache.readQuery({
                  query: SCALE_DISCOUNTS_QUERY,
                  variables: { itemBoilerplateId },
                }) as any;
                remove(scaleDiscounts, (discount: any) => discount.id === deleteScaleDiscount.id);
                cache.writeQuery({
                  query: SCALE_DISCOUNTS_QUERY,
                  variables: { itemBoilerplateId },
                  data: { scaleDiscounts },
                });
              }}
            >
              {(deleteScaleDiscount) => (
                <Dialog open={true} onClose={handleClose}>
                  <Toolbar className={classes.headerToolbar}>
                    <DialogTitle>Mengenrabatte</DialogTitle>
                    <Tooltip title="Schliessen">
                      <IconButton onClick={handleClose} aria-label="Close">
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                  <DialogContent>
                    <Toolbar className={classes.secondaryToolbar}>
                      <AddButton
                        href={`/einzel-positions-vorlagen/${itemBoilerplateId}/staffelrabatte/erstellen`}
                        tooltip="Erstellen"
                      />
                    </Toolbar>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Menge</TableCell>
                          <TableCell>Preis / Einheit</TableCell>
                          <TableCell>Aktion</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.scaleDiscounts.map((scaleDiscount: any, idx: number) => (
                          <TableRow key={idx}>
                            <TableCell>{formatVolume(scaleDiscount.threshhold)}</TableCell>
                            <TableCell>{formatCurrency(scaleDiscount.pricePerUnit)}</TableCell>
                            <TableCell align="center">
                              <Tooltip title="Editieren">
                                <Link
                                  to={`/einzel-positions-vorlagen/${itemBoilerplateId}/staffelrabatte/${scaleDiscount.id}/editieren`}
                                >
                                  <IconButton>
                                    <Create />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                              <Tooltip title="Löschen">
                                <IconButton
                                  onClick={() =>
                                    deleteScaleDiscount({ variables: { id: scaleDiscount.id } })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </DialogContent>
                </Dialog>
              )}
            </Mutation>
          )
        );
      }}
    </Query>
  );
};

export default ScaleDiscounts;
