import { TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { DownloadCsvAction } from '../../components/Actions/Download/Csv';
import { DataTableBody, DataTableHotKeysWrapper } from '../../components/DataTable';
import { Filter, useFilterReducer } from '../../components/Filter';
import AppProgress from '../../components/Page/AppProgress';
import { Pagination } from '../../components/Pagination';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Search, useSearchState } from '../../components/Search/Search';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { formatDate } from '../../utils/format/date';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { isComputedField } from '../../utils/order/isComputedField';
import { mapMissionWhereClause, missionFilterConfigs } from '../Missions/filter.config';
import { missionColumns } from '../Missions/mission.columns';
import { MISSION_COMPUTED_FIELDS } from '../Missions/mission.consts';
import { mapToMissionTableData } from '../Missions/mission.mapper';
import { MISSION_QUERY, SELECTED_SUBSIDIARY_QUERY } from '../Missions/queries';
import { GetMissions, GetMissionsVariables } from '../Missions/types/GetMissions';

export const Measurements: React.FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => window.parent.postMessage('momoAusmasse', '*'), []);

  const [filterState, dispatchFilter] = useFilterReducer();
  const [missionOngoingId, setMissionOngoingId] = useState('');

  const searchState = useSearchState();

  const {
    loading: subsidiaryLoading,
    error: subsidiaryError,
    data: subsidiaryData,
  } = useQuery(SELECTED_SUBSIDIARY_QUERY);

  const missionWhere = useMemo(
    () => mapMissionWhereClause(filterState.addedFilters),
    [filterState.addedFilters],
  );

  const queryVariables = useMemo(
    (): GetMissionsVariables => ({
      where: {
        ...missionWhere,
        ...(missionOngoingId && { id: parseInt(missionOngoingId, 10) }),
        ...(subsidiaryData.selectedSubsidiary !== 'all' && {
          project: {
            subsidiary: { id: subsidiaryData.selectedSubsidiary },
          },
        }),
      },
    }),
    [missionOngoingId, subsidiaryData, missionWhere],
  );

  const { error, data, refetch, loading, variables } = useQuery<GetMissions, GetMissionsVariables>(
    MISSION_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        ...queryVariables,
        page: {
          amountPerPage: 25,
          pageNumber: 0,
        },
      },
    },
  );

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
        ...queryVariables,
      });
    },
    [refetch, queryVariables],
  );

  const count = useCount({
    entity: CountEntity.MISSION,
    variables: queryVariables,
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  if (subsidiaryLoading) {
    return null;
  }

  if (error || subsidiaryError) {
    console.log(error);
    return null;
  }

  if (!data || !subsidiaryData) {
    return null;
  }

  const flattenedItems = mapToMissionTableData(data);

  return (
    <DataTableHotKeysWrapper
      pagination={<Pagination paginationState={paginationState} />}
      search={
        <Search
          columns={[
            'project.projectNumber',
            'project.projectName',
            'name',
            'status.missionStatus',
            'surfaceType',
            'workTypes',
            'projectAddress.postCode',
            'projectAddress.city',
          ]}
          searchState={searchState}
          onSubmit={(search) => refetch({ search, ...queryVariables })}
          loading={loading}
        />
      }
      options={{
        filterText: searchState.searchTerm,
        tableName: 'ALL_MEASUREMENTS',
        onChangeSort: (fieldName, order) => {
          refetch({
            orderBy: {
              fieldName: createOrderByFieldName(fieldName, order),
              isComputed: isComputedField(fieldName, MISSION_COMPUTED_FIELDS),
            },
          });
        },
        levels: [
          {
            columns: missionColumns,
            onRowClick: (row) =>
              history.push(
                `/projekte/${row.data['project.projectNumber']}/ausmass/${row.data.id}/details/editieren`,
              ),
          },
        ],
        disableFocusOnActiveRowChange: true,
      }}
      innerTableRows={flattenedItems}
    >
      {(context) => {
        return (
          <>
            {loading && <AppProgress />}
            <Filter
              filters={missionFilterConfigs}
              selectedFilter={filterState.selectedFilter}
              addedFilters={filterState.addedFilters}
              dispatch={dispatchFilter}
            />
            <TextField
              value={missionOngoingId}
              placeholder="Laufnummer"
              onChange={(e) => setMissionOngoingId(e.target.value)}
            />
            <Formik
              initialValues={{}}
              onSubmit={() => {
                return;
              }}
            >
              {() => {
                return (
                  <Form>
                    <DataTableBody
                      context={context}
                      toolbarProps={{
                        actions: (
                          <>
                            <DownloadCsvAction
                              entityType="MISSION"
                              variables={{ where: variables.where, search: variables.search }}
                              csvName={`Ausmass_${formatDate()}`}
                            />
                          </>
                        ),
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        );
      }}
    </DataTableHotKeysWrapper>
  );
};
