import { Grid, MenuItem, RootRef, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField as Field, Form, Formik } from 'formik';
import { omit } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFocusField } from '../../hooks/useFocusField';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import Publisher from '../../pages/Projects/TabDetails/Publisher';
import SubsidiarySelect from '../../pages/Projects/TabDetails/SubsidiarySelect';
import { Role } from '../../types/graphql';
import ColorCoder, { Color } from '../ColorCoder';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikCheckBoxWithLabel from '../Form/FormikCheckboxWithLabel';
import FormikSelect from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';
import HistorizedField from '../Form/HistorizedField';
import UnsavedWarningPrompt from '../Form/UnsavedWarningPrompt';
import {
  AutocompleteCatalogField,
  ParentProjectField,
  PersonResponsibleField,
} from './ProjectDetailsForm.common';

export enum ProjectStatus {
  P0 = 'P0 - Neu',
  P1 = 'P1 - Angebot',
  P2 = 'P2 - Auftrag erhalten',
  P3 = 'P3 - In Arbeit',
  P4 = 'P4 - Abgeschlossen',
  P5 = 'P5 - Nicht erhalten',
  P6 = 'P6 - Archiviert',
}

const omitTypeName = (object: Record<string, unknown>) => omit(object, ['__typename', 'id']);

export const mapProjectDetailsFormValues = (formData: any) => ({
  ...(formData.catalog && { catalog: { id: formData.catalog } }),
  projectNumber: formData.projectNumber,
  projectName: formData.projectName,
  status: formData.status,
  isSpecialProject: formData.isSpecialProject,
  hasSeparateFileStorage: formData.hasSeparateFileStorage,
  contactPerson: formData.contactPerson,
  staffTariff: parseFloat(formData.staffTariff),
  commentToBranchLeader: formData.commentToBranchLeader,
  commentToBackoffice: formData.commentToBackoffice,
  commentToExecutives: formData.commentToExecutives,
  customerInput: formData.customerInput,
  personResponsible: omitTypeName(formData.personResponsible),
  subsidiary: omitTypeName(formData.subsidiary),
  documents: omitTypeName(formData.documents),
  ...(formData.parent && { parent: { projectNumber: formData.parent.projectNumber } }),
});

type MappedRow = ReturnType<typeof mapProjectDetailsFormValues>;

const useStyles = makeStyles((theme: Theme) => ({
  root: { flexGrow: 1 },
  rowMargin: {
    marginBottom: '2rem',
  },
  checkBox: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
}));

interface IProps {
  project: any;
  hasBoqs: boolean;
  onSubmit: (row: MappedRow) => Promise<any>;
}

export const projectNumberYupSchema = Yup.string()
  .matches(/^[A-Za-z0-9]*$/, 'Projektnummer darf keine Sonderzeichen oder Leerzeichen enthalten')
  .required('Verpflichtend');

const validationSchema = Yup.object().shape({
  projectNumber: projectNumberYupSchema,
  projectName: Yup.string().matches(/^[A-Za-z0-9-.,/üöäÜÖÄ ]*$/, 'Keine Sonderzeichen erlaubt'),
});

const ProjectDetailsForm: React.FunctionComponent<IProps> = ({ project, hasBoqs, onSubmit }) => {
  const firstField = useFocusField();
  const user = useLoggedInUser();

  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    await onSubmit(mapProjectDetailsFormValues(values));
    setSubmitting(false);

    if (values.projectNumber !== project.projectNumber) {
      history.replace(`/projekte/${values.projectNumber}/details/editieren`);
    }
  };

  const catalog = (project.catalog && project.catalog.id) || '';
  const catalogName = project.catalog?.useName ?? '';

  if (!user) {
    return null;
  }

  const isPlainUser = user.role === Role.USER;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...project,
        parent: project.parent || '',
        catalog,
        catalogVisible: catalogName,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty }) => {
        return (
          <Form className={classes.root}>
            <UnsavedWarningPrompt dirty={dirty && !isSubmitting} />
            <Grid container spacing={5} className={classes.rowMargin}>
              <Grid item md={6} lg={3}>
                {project.publisher && (
                  <Publisher name={project.publisher.name} createdAt={project.createdAt} />
                )}
              </Grid>
              <Grid item md={6} lg={3} className={classes.rowMargin}>
                <SubsidiarySelect />
              </Grid>
              <Grid item md={6} lg={3} className={classes.rowMargin}>
                <PersonResponsibleField />
              </Grid>
              <Grid item md={6} lg={3} className={classes.rowMargin} />
              {project.colorCodings && (
                <Grid item xs={12}>
                  <ColorCoder justify="flex-start" colorCodings={project.colorCodings as Color[]} />
                </Grid>
              )}
              <Grid item md={6} lg={3} className={classes.rowMargin}>
                <RootRef rootRef={firstField}>
                  <Field
                    name="projectNumber"
                    label="Projektnummer"
                    component={FormikTextField}
                    disabled={hasBoqs && isPlainUser}
                    required
                  />
                </RootRef>
                <Field name="projectName" label="Projektbezeichnung" component={FormikTextField} />
                <ParentProjectField />
              </Grid>

              <Grid item md={6} lg={3} className={classes.rowMargin}>
                {project.statusHistory && (
                  <HistorizedField
                    history={project.statusHistory.map((historyItem: any) => ({
                      date: historyItem.createdAt,
                      value:
                        ProjectStatus[historyItem.status as keyof typeof ProjectStatus].toString(),
                      user: historyItem.setBy!,
                    }))}
                    name="status"
                    label="Status"
                    component={FormikSelect}
                  >
                    {Object.entries(ProjectStatus).map(([key, val], index) => (
                      <MenuItem key={index} value={key}>
                        {val}
                      </MenuItem>
                    ))}
                  </HistorizedField>
                )}
                <Field
                  name="isSpecialProject"
                  component={FormikCheckBoxWithLabel}
                  Label={{ label: 'Spezialprojekt' }}
                  propClasses={{ root: classes.checkBox }}
                />
                <Field
                  name="hasSeparateFileStorage"
                  component={FormikCheckBoxWithLabel}
                  Label={{ label: 'Separate Dateiablage' }}
                  propClasses={{ root: classes.checkBox }}
                />
              </Grid>

              <Grid item md={6} lg={3} className={classes.rowMargin}>
                <AutocompleteCatalogField />
                <Field
                  type="number"
                  name="staffTariff"
                  label="Mitarbeiter-Tarif"
                  component={FormikTextField}
                />
              </Grid>

              <Grid item md={6} lg={3} className={classes.rowMargin}>
                <Field
                  name="commentToBranchLeader"
                  label="Kommentar an FL"
                  component={FormikTextField}
                  multiline
                />
                <Field
                  name="commentToBackoffice"
                  label="Kommentar an BO"
                  component={FormikTextField}
                  multiline
                />
                <Field
                  name="commentToExecutives"
                  label="Kommentar an GF"
                  component={FormikTextField}
                  multiline
                />
                <Field
                  name="customerInput"
                  label="Input von Kunde"
                  component={FormikTextField}
                  multiline
                />
              </Grid>
              <Grid item container justify="flex-end" md={12}>
                <CancelSaveButtons isDisabled={isSubmitting || !dirty} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectDetailsForm;
