import { ClickAwayListener, IconButton, RootRef, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { omit } from 'lodash';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Route, useHistory } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import AddButton from '../../components/DataTable/DataTableToolbar/AddButton';
import FormikTextField from '../../components/Form/FormikTextField';
import AppProgress from '../../components/Page/AppProgress';
import { Pagination } from '../../components/Pagination';
import { IPaginationState, usePaginationState } from '../../components/Pagination/Pagination.utils';
import { Search, useSearchState } from '../../components/Search/Search';
import { Role } from '../../enums';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { useCount } from '../../hooks/useCount';
import { CountEntity } from '../../hooks/useCount/useCount.queries';
import { useDynamicFocusField } from '../../hooks/useFocusField';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { hasAccessRights } from '../../utils';
import { formatDate } from '../../utils/format/date';
import { createOrderByFieldName } from '../../utils/order/createOrderByFieldName';
import { stopEventPropagation } from '../../utils/stopEventPropagation';
import { useEditCatalog } from './editCatalog';
import ImportDialog from './ImportDialog';
import { getCatalogs, getCatalogsVariables } from './types/getCatalogs';

export const GET_CATALOGS = gql`
  query getCatalogs($search: SearchInput, $page: PageInput, $orderBy: OrderByInput) {
    catalogs(search: $search, page: $page, orderBy: $orderBy) {
      id
      useName
      createdAt
    }
  }
`;

const DELETE_CATALOG = gql`
  mutation DeleteCatalog($id: String!) {
    deleteCatalog(id: $id) {
      id
    }
  }
`;

const toTableElement = (element: any) => ({
  id: element.id,
  data: element,
});

const useStyles = makeStyles({
  useNameField: {
    width: '50%',
  },
});

const Catalogs: React.FunctionComponent<any> = () => {
  useEffect(() => window.parent.postMessage('momoCatalogs', '*'), []);

  const [catalogEditId, setCatalogEditId] = useState('');
  const [catalogDeleteId, setCatalogDeleteId] = useState('');
  const classes = useStyles();
  const searchState = useSearchState();
  const focusField = useDynamicFocusField(true);
  const history = useHistory();
  const user = useLoggedInUser();
  const isAllowedToEdit = user?.role ? hasAccessRights(user.role, Role.SUPER_ADMIN) : false;

  const { data, loading, error, refetch, variables } = useQuery<getCatalogs, getCatalogsVariables>(
    GET_CATALOGS,
    {
      variables: {
        page: {
          pageNumber: 0,
          amountPerPage: 25,
        },
      },
    },
  );
  const [deleteCatalog] = useMutation(DELETE_CATALOG, {
    refetchQueries: [{ query: GET_CATALOGS, variables }],
  });
  const { executeEditCatalog } = useEditCatalog();

  const { confirmationDialog, requestConfirmation } = useConfirmationDialog({
    message: 'Möchtest du den Katalog wirklich löschen?',
    title: 'Katalog löschen',
    onConfirm: () => {
      deleteCatalog({ variables: { id: catalogDeleteId } });
      setCatalogDeleteId('');
    },
  });

  const onPaginationStateChange = useCallback(
    (state: IPaginationState) => {
      refetch({
        page: {
          amountPerPage: state.rowsPerPage,
          pageNumber: state.page,
        },
      });
    },
    [refetch],
  );

  const count = useCount({
    entity: CountEntity.CATALOG,
    variables: omit(variables, 'page'),
  });

  const paginationState = usePaginationState({
    totalNumberOfRows: count?.totalNumberOfRows ?? 0,
    scrollOnPaginationAction: true,
    onChangePage: onPaginationStateChange,
    onSetRowsPerPage: onPaginationStateChange,
  });

  const createRequestCatalogDelete = useCallback(
    (id: string) => async (e: MouseEvent) => {
      e.stopPropagation();
      setCatalogDeleteId(id);
      requestConfirmation();

      count?.refetch();
    },
    [count, requestConfirmation],
  );
  if (!data) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      {loading && <AppProgress />}
      <DataTable
        innerTableRows={data.catalogs?.map(toTableElement) ?? []}
        pagination={<Pagination paginationState={paginationState} />}
        search={
          <Search
            columns={['useName']}
            searchState={searchState}
            onSubmit={(search) => refetch({ search })}
            loading={loading}
          />
        }
        options={{
          filterText: searchState.searchTerm,
          tableName: 'CATALOGS',
          isReordering: loading,
          onChangeSort: (fieldName, order) => {
            return refetch({
              orderBy: { fieldName: createOrderByFieldName(fieldName, order), isComputed: false },
            });
          },
          levels: [
            {
              columns: [
                {
                  id: 'useName',
                  label: 'Verwendungsname',
                  render: (useName, row) =>
                    row.id !== catalogEditId ? (
                      useName
                    ) : (
                      <Formik
                        enableReinitialize
                        initialValues={{ useName }}
                        onSubmit={async (v) => {
                          setCatalogEditId('');
                          return executeEditCatalog({
                            variables: { where: { id: catalogEditId }, data: v },
                          });
                        }}
                      >
                        {({ isSubmitting, dirty, submitForm }) => (
                          <Form>
                            <ClickAwayListener
                              onClickAway={() => {
                                setCatalogEditId('');
                                if (dirty) {
                                  submitForm();
                                }
                              }}
                            >
                              <RootRef rootRef={focusField}>
                                <Field
                                  component={FormikTextField}
                                  name="useName"
                                  disabled={isSubmitting}
                                  classes={{ root: classes.useNameField }}
                                  fullWidth={false}
                                  onClick={stopEventPropagation}
                                  shrink
                                />
                              </RootRef>
                            </ClickAwayListener>
                          </Form>
                        )}
                      </Formik>
                    ),
                },
                {
                  id: 'createdAt',
                  label: 'Hochgeladen am',
                  render: formatDate,
                  preventSorting: true,
                },
              ],
              onRowClick: (row) => {
                // don't push to history when useName field is open
                if (document.querySelector('input[name=useName]')) {
                  return;
                }
                history.push({
                  pathname: '/einzel-positions-vorlagen',
                  state: { catalogId: row.id },
                });
              },
              actions: () => (
                <AddButton tooltip="Neu" href="/kataloge/erstellen" state={{ variables }} />
              ),
              rowActions: ({ row }) => (
                <>
                  <Tooltip title="Bearbeiten">
                    <span>
                      <IconButton
                        disabled={!isAllowedToEdit}
                        aria-label="Bearbeiten"
                        onClick={(e: MouseEvent) => {
                          stopEventPropagation(e);
                          setCatalogEditId(row.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Löschen">
                    <IconButton aria-label="Löschen" onClick={createRequestCatalogDelete(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
          ],
        }}
      />
      <Route path="/kataloge/erstellen" component={ImportDialog} />
      {confirmationDialog}
    </>
  );
};

export default Catalogs;
