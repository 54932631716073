import {
  Button,
  Grid,
  IconButton,
  Input,
  MenuItem,
  RootRef,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isNil, last, omit } from 'lodash';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFocusField } from '../../hooks/useFocusField';
import { Color, default as ColorCoder } from '../ColorCoder';
import CancelSaveButtons from '../Form/CancelSaveButtons';
import FormikCheckBoxWithLabel from '../Form/FormikCheckboxWithLabel';
import FormikSelect from '../Form/FormikSelect';
import FormikTextField from '../Form/FormikTextField';
import defaultNilInitialValues from '../../utils/form/initialValuesDefaults';
import Publisher from '../../pages/Projects/TabDetails/Publisher';
import { getChangedFormValues } from '../../utils/form/getChanged';
import BillOfQuantityStatusField from './BOQStatus/BillOfQuantityStatus';
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { Role } from '../../enums';
import { hasAccessRights } from '../../utils';
import { BillOfQuantityEntityType } from '../../types/graphql';
import { convertToInputValue } from '../../utils/dateToInputValue';
import AppErrorMessage from '../Page/AppErrorMessage';
import { errorPrefixRemover } from '../../utils/errorPrefixRemover';

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1 / 4),
  },
  colorCoderContainer: {
    padding: theme.spacing(1.5),
  },
  checkBox: {
    marginTop: theme.spacing(2),
  },
  copyOrderButton: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  initialValues?: any;
  onSubmit: (data: any, id?: string) => Promise<any>;
  onClose: () => void;
  disableDirtyCheck?: boolean;
  customSaveButtonLabel?: string;
  getAllValues?: boolean;
  hideHeader?: boolean;
  entityType: BillOfQuantityEntityType;
  isDisabled?: boolean;
  handleOrderToOfferCopy?: () => void;
}

enum BOQTaskTypes {
  INNEN = 'Innen',
  AUSSEN = 'Aussen',
  PARKPLATZ = 'Parkplatz',
  NEUMARKIERUNG = 'Neumarkierung',
  NACHMARKIERUNG = 'Nachmarkierung',
  GARAGE = 'Garage',
  HALLE = 'Halle',
  FGSO = 'FGSO',
  TAKTIL = 'Taktil',
  SPIELFELD = 'Spielfeld',
  DM = 'Demarkierung',
  SIGNALISATIONEN = 'Signalisationen',
}

enum BOQType {
  AB = 'AB - Autobahn',
  B = 'B - Baustelle',
  SP = 'SP - Strassenmarkierung (Private Auftraggeber)',
  PM = 'PM - Private Markierungen',
}

const mapInitialValues = (initialValues: any, isOffer: boolean) => ({
  ...defaultNilInitialValues(
    {
      ...initialValues,
      ...(initialValues && {
        statuses: omit(last(initialValues.statuses), ['__typename', 'setBy', 'createdAt']),
      }),
    },
    {
      name: '',
      taskTypes: [],
      type: '',
      hasScaleDiscount: true,
      hasScaleDiscountAccumulate: true,
      date: convertToInputValue(new Date()),
      statuses: {
        status: isOffer ? 'L1' : 'L4',
      },
    },
  ),
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Das Feld Bezeichnung ist ein Pflichtfeld'),
  type: Yup.string().required('Das Feld LV-Typ ist ein Pflichtfeld'),
  taskTypes: Yup.array().required('Das Feld Arbeitsgattung ist ein Pflichtfeld'),
  date: Yup.date().required('Das Feld Offert-Datum ist ein Pflichtfeld'),
});

interface IDetailsCloseProps {
  onClose: () => void;
}

export const DetailClose: React.FC<IDetailsCloseProps> = ({ onClose }) => {
  return (
    <Tooltip title="Schliessen">
      <IconButton onClick={onClose} aria-label="Close">
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};

const BillOfQuantityDetailsForm: React.FC<IProps> = ({
  initialValues,
  onSubmit,
  onClose,
  disableDirtyCheck,
  customSaveButtonLabel,
  getAllValues,
  hideHeader,
  entityType,
  isDisabled,
  handleOrderToOfferCopy,
}) => {
  const classes = useStyles();
  const firstField = useFocusField();

  const user = useLoggedInUser();

  const isOffer = useMemo(() => entityType === BillOfQuantityEntityType.OFFER, [entityType]);

  const showCopyToOfferButton = useMemo(() => {
    if (user?.role) {
      return (
        hasAccessRights(user?.role, Role.SUPER_ADMIN) && !isOffer && !isNil(handleOrderToOfferCopy)
      );
    }
    return false;
  }, [handleOrderToOfferCopy, isOffer, user]);

  const formInitialValues = mapInitialValues(initialValues, isOffer);

  const isCreate = useMemo(() => isEmpty(initialValues), [initialValues]);

  const [submitError, setSubmitError] = useState<string>('');

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setSubmitError('');
    const submittedValues = omit(
      getAllValues
        ? values
        : getChangedFormValues({
            initialValues: formInitialValues,
            values,
            includeKeys: ['statuses'],
            ignoreKeys: ['date'],
          }),
      ['statuses.id', 'isOfferBlocked', 'entityType'],
    );

    try {
      await onSubmit(submittedValues);
      if (isCreate) {
        onClose();
        return;
      }
      resetForm();
    } catch (e: any) {
      setSubmitError(e.message);
    }
  };

  const isFormDisabled = isDisabled;

  return (
    <>
      {submitError && <AppErrorMessage message={errorPrefixRemover(submitError)} />}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty, setSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              {!hideHeader && (
                <Grid container item className={classes.colorCoderContainer}>
                  {initialValues ? (
                    <Grid item xs={10}>
                      <Publisher name="" createdAt={initialValues?.createdAt} />
                      <ColorCoder
                        justify="flex-start"
                        colorCodings={(initialValues?.colorCodings as Color[]) ?? []}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={10}>
                      <Typography variant="h6">Leistungsverzeichnis erstellen</Typography>
                    </Grid>
                  )}
                  <Grid item xs={2}>
                    <Grid container justify="flex-end">
                      <DetailClose onClose={onClose} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={4}>
                <RootRef rootRef={firstField}>
                  <Field
                    disabled={isFormDisabled}
                    name={'name'}
                    label="Bezeichnung *"
                    component={FormikTextField}
                  />
                </RootRef>
                <Field
                  label="Arbeitsgattung *"
                  disabled={isFormDisabled}
                  component={FormikSelect}
                  name="taskTypes"
                  multiple
                  input={<Input id="taskTypes" />}
                >
                  {Object.entries(BOQTaskTypes).map(([type, value]) => (
                    <MenuItem value={type} key={type}>
                      {value}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={FormikSelect}
                  name="type"
                  disabled={isFormDisabled}
                  label="LV-Typ *"
                  input={<Input id={'type'} />}
                >
                  {Object.entries(BOQType).map(([type, value]) => (
                    <MenuItem key={type} value={type}>
                      {value}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  disabled={isFormDisabled}
                  component={BillOfQuantityStatusField}
                  name="statuses"
                  entityType={entityType}
                  history={initialValues && initialValues.statuses}
                  isCreate
                />
                <Field
                  component={FormikTextField}
                  disabled={isFormDisabled}
                  type="date"
                  name="date"
                  label="Offert-Datum *"
                  shrink
                />
                <Field
                  name="hasScaleDiscount"
                  Label={{ label: 'Mengenrabatt ja' }}
                  disabled={isFormDisabled}
                  component={FormikCheckBoxWithLabel}
                  propClasses={{ root: classes.checkBox }}
                />
                <Field
                  name="hasScaleDiscountAccumulate"
                  Label={{ label: 'Mengenrabatte über gleiche Positionen akummulieren' }}
                  component={FormikCheckBoxWithLabel}
                  propClasses={{ root: classes.checkBox }}
                  disabled={
                    user?.role && !hasAccessRights(user?.role, Role.ADMIN) && isFormDisabled
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="freeTextOne"
                  label="Zeile 1"
                  component={FormikTextField}
                  disabled={isFormDisabled}
                />
                <Field
                  name="freeTextTwo"
                  label="Zeile 2"
                  component={FormikTextField}
                  disabled={isFormDisabled}
                />
                <Field
                  name="freeTextThree"
                  label="Zeile 3"
                  component={FormikTextField}
                  disabled={isFormDisabled}
                />
                <Field
                  name="freeTextFour"
                  label="Zeile 4"
                  component={FormikTextField}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid
                item
                container
                justify={showCopyToOfferButton ? 'space-between' : 'flex-end'}
                md={12}
              >
                {showCopyToOfferButton && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.copyOrderButton}
                    disabled={isSubmitting || dirty}
                    onClick={() => {
                      setSubmitting(true);
                      handleOrderToOfferCopy!();
                    }}
                  >
                    Zu Angeboten kopieren
                  </Button>
                )}
                <CancelSaveButtons
                  customSaveButtonLabel={customSaveButtonLabel}
                  isDisabled={isSubmitting || (!dirty && !disableDirtyCheck)}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BillOfQuantityDetailsForm;
